import { styled, Box } from "@mui/material";

export const S = {
    InfoWindowContent: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        maxWidth: "320px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(5),
    })),

    AssetType: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: theme.spacing(15),
        paddingBottom: theme.spacing(15),
        fontWeight: "bold",
        fontSize: 24,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    })),

    AssetIdentity: styled(Box)(({ theme }) => ({
        display: "flex",
        justifyContent: "space-between",
    })),

    Heading: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: 3,
    })),

    AssetPropertyList: styled(Box)(({ theme }) => ({
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 2,
        marginTop: theme.spacing(5),
    })),
};
