import { LineChart, LineChartProps } from "@mui/x-charts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
    AssetRisk,
    CrremPathway,
} from "containers/StrategyPage/strategyPage.types";
import { ChartDisplayType } from "glue/Chart/chart.constants";

import {
    assetRiskListToChartSeries,
    crremPathwaysToChartSeries,
    getXAxisYears,
} from "./chart.utils";
import { StrategyLine } from "./StrategyLine";
import { StrategyMark } from "./StrategyMark";
import { StrategyTooltip } from "./StrategyTooltip";

type Props = {
    assets: AssetRisk[];
    pathways: CrremPathway[];
    loading: boolean;
    displayType: ChartDisplayType;
    onSelectedAsset: (asset: AssetRisk) => void;
};

export const Chart = (props: Props) => {
    const { assets, pathways, loading, displayType, onSelectedAsset } = props;
    const { t } = useTranslation();

    const [years, series] = useMemo(() => {
        const years = getXAxisYears();
        const assetSeries = assetRiskListToChartSeries(
            assets,
            years,
            displayType,
            t
        );
        const crremSeries = crremPathwaysToChartSeries(
            pathways,
            years,
            displayType
        );

        const series = [...assetSeries, ...crremSeries];
        return [years, series];
    }, [assets, pathways, displayType, t]);

    const onLineClick: LineChartProps["onLineClick"] = (_, params) => {
        const { seriesId } = params;

        const id = (seriesId as string).split("asset-")[1];
        const asset = assets.find((asset) => asset.id === id);

        if (!asset) {
            return;
        }

        onSelectedAsset(asset);
    };

    const xAxisLabel =
        displayType === ChartDisplayType.EMISSIONS ? "kg CO2e/m²" : "kWh/m²";

    return (
        <LineChart
            loading={loading}
            height={600}
            series={series}
            yAxis={[
                {
                    tickNumber: 5,
                    label: xAxisLabel,
                    labelStyle: { translate: -10 },
                },
            ]}
            xAxis={[
                {
                    data: years,
                    scaleType: "point",
                    valueFormatter: (date: number) => date.toString(),
                    tickInterval: (value) => value % 5 === 0,
                },
            ]}
            slots={{
                itemContent: StrategyTooltip,
                line: StrategyLine,
                mark: StrategyMark,
            }}
            slotProps={{
                mark: {
                    series,
                    currentYearIndex: years.findIndex(
                        (year) => year === new Date().getFullYear()
                    ),
                } as any,
                itemContent: { years } as any,
                legend: { hidden: true },
                line: { limit: new Date().getFullYear() } as any,
            }}
            tooltip={{
                trigger: "item",
            }}
            onLineClick={onLineClick}
            onMarkClick={onLineClick}
            axisHighlight={{ x: "none", y: "none" }}
            grid={{ vertical: true, horizontal: true }}
            margin={{ top: 15 }}
            sx={{
                "& .MuiLineElement-root": {
                    strokeWidth: 2,
                },
                "& .line-after path[class*='MuiLineElement-series-asset']": {
                    strokeDasharray: "10 5",
                    stroke: "gray",
                },
                "& path[class*='MuiLineElement-series-asset']": {
                    cursor: "pointer",
                },
                // Mark
                "& .custom-mark": { cursor: "pointer", strokeWidth: 2 },
                "& .custom-mark:hover": { strokeWidth: 4 },
            }}
        />
    );
};
