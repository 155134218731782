import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SearchAssetView } from "./../../../../types";
import { S } from "./search.styles";

type Props = {
    onSearchViewChange: (view: SearchAssetView) => void;
};

export const SearchView = (props: Props) => {
    const { onSearchViewChange } = props;

    const { t } = useTranslation();
    const [selectedView, setSelectedView] = useState<SearchAssetView>("map");

    const handleChange = (
        _event: React.MouseEvent<HTMLElement>,
        newView: SearchAssetView | null
    ) => {
        if (newView !== null) {
            setSelectedView(newView);
            onSearchViewChange(newView);
        }
    };

    return (
        <S.SearchViewGroup
            value={selectedView}
            exclusive
            onChange={handleChange}
            aria-label="View Toggle"
        >
            <S.SearchViewButton value="map">
                <S.SearchViewButtonInner>
                    <ExploreOutlinedIcon fontSize="small" />
                    {t("legacyNext.map", "map")}
                </S.SearchViewButtonInner>
            </S.SearchViewButton>
            <S.SearchViewButton value="list">
                <S.SearchViewButtonInner>
                    <SubjectOutlinedIcon fontSize="small" />
                    {t("legacyNext.list", "list")}
                </S.SearchViewButtonInner>
            </S.SearchViewButton>
            <S.SearchViewButton value="graph">
                <S.SearchViewButtonInner>
                    <InsightsOutlinedIcon fontSize="small" />
                    {t("legacyNext.graph", "graph")}
                </S.SearchViewButtonInner>
            </S.SearchViewButton>
        </S.SearchViewGroup>
    );
};
