import { Box, styled } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentDataType } from "graphql-types/graphql";

import { PortfolioPage } from "../PortfolioDashboard/types";
import TabsPanel from "../TabsPanel/TabsPanel";
import YearAndAssessmentTypeFilter from "../YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";

const SubHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    margin: theme.spacing(4, 0),

    "& > *:last-child": {
        marginLeft: "auto",
    },
}));

type Props = {
    tab: { active: PortfolioPage; onChange: (value: PortfolioPage) => void };
    assessmentType: {
        active: AssessmentDataType;
        onChange: (value: AssessmentDataType) => void;
    };
    hasCoverage?: boolean;
};

export const SubHeaderWithTabs = (props: Props) => {
    const { tab, assessmentType, hasCoverage } = props;
    const { t } = useTranslation();

    const tabsData = useMemo(() => {
        const tabs = [
            {
                title: t("common.timeframe.yearly", "yearly"),
                value: PortfolioPage.OVERVIEW,
            },
            {
                title: t("common.timeframe.monthly", "monthly"),
                value: PortfolioPage.YEARLY_BREAKDOWN,
            },
        ];

        if (hasCoverage) {
            tabs.push({
                title: t("portfolioDashboard.tabs.coverage", "Coverage"),
                value: PortfolioPage.COVERAGE,
            });
        }

        return tabs;
    }, [t, hasCoverage]);

    const onTabChange = useCallback(
        (_: React.ChangeEvent<object>, value: PortfolioPage) =>
            tab.onChange(value),
        [tab]
    );

    return (
        <SubHeader>
            <TabsPanel
                tabsData={tabsData}
                activeTab={tab.active}
                onChange={onTabChange}
            />

            <YearAndAssessmentTypeFilter
                activeType={assessmentType.active}
                onAssessmentTypeChange={assessmentType.onChange}
                isYearSelectorHidden={tab.active === PortfolioPage.OVERVIEW}
                isAssessmentTypeSelectorHidden={
                    tab.active === PortfolioPage.COVERAGE
                }
            />
        </SubHeader>
    );
};
