import { List, ListItem } from "@mui/material";
import { useState } from "react";

import { BenchmarkMarkers } from "components/types";

import { BenchmarkButton } from "../BenchmarkButton";
import { BenchmarkDownload } from "../BenchmarkDownload";
import { BenchmarkDrawer } from "../BenchmarkDrawer";
import { BenchmarkItemToggle } from "../BenchmarkItemToggle";

type Props = {
    title: string;
    benchmarkMarkers: BenchmarkMarkers;
    onToggleMarkerVisibility: (id: string) => void;
    allowDownload?: boolean;
};

export const BenchmarkPanel = (props: Props) => {
    const { title, benchmarkMarkers, onToggleMarkerVisibility, allowDownload } =
        props;

    const [toggleBenchmarkDrawer, setToggleBenchmarkDrawer] = useState(false);

    function onToggleBenchmarkDrawer() {
        setToggleBenchmarkDrawer(!toggleBenchmarkDrawer);
    }

    if (!benchmarkMarkers.length) return null;

    const items = benchmarkMarkers.map((benchmarkMarker) => (
        <ListItem key={benchmarkMarker.id}>
            <BenchmarkItemToggle
                benchmarkMarker={benchmarkMarker}
                onToggleMarkerVisibility={onToggleMarkerVisibility}
            />
        </ListItem>
    ));

    return (
        <>
            <BenchmarkButton
                benchmarkCount={benchmarkMarkers.length}
                onClick={onToggleBenchmarkDrawer}
            />
            <BenchmarkDrawer
                title={title}
                isOpen={toggleBenchmarkDrawer}
                onClose={onToggleBenchmarkDrawer}
            >
                <List>{items}</List>;{allowDownload && <BenchmarkDownload />}
            </BenchmarkDrawer>
        </>
    );
};
