import { TFunction } from "i18next";
import _ from "lodash";
import { match, P } from "ts-pattern";

import { ColorProps } from "components/AssessmentStats/types";
import {
    AssessmentCategory,
    AssessmentVertical,
    ConsumptionType,
} from "graphql-types/graphql";

export const MandatoryVerticals = [
    AssessmentVertical.ELECTRICITY,
    AssessmentVertical.HEATING,
    AssessmentVertical.WATER,
];

const VerticalPriority = [
    ...MandatoryVerticals,
    AssessmentVertical.COOLING,
    AssessmentVertical.WASTE,
];

export const assessmentVerticalToTitle = (
    assessmentType: AssessmentVertical | null | undefined,
    t: TFunction
) => {
    switch (assessmentType) {
        case AssessmentVertical.HEATING:
            return t("assessmentOverview.heat.title", "Heat", {
                ns: "translation",
            });
        case AssessmentVertical.COOLING:
            return t("assessmentOverview.cooling.title", "Cooling", {
                ns: "translation",
            });
        case AssessmentVertical.ELECTRICITY:
            return t("assessmentOverview.electricity.title", "Electricity", {
                ns: "translation",
            });
        case AssessmentVertical.MATERIALS:
            return t("assessmentOverview.materials.title", "Materials", {
                ns: "translation",
            });
        case AssessmentVertical.TRANSPORT:
            return t("assessmentOverview.transport.title", "Transport", {
                ns: "translation",
            });
        case AssessmentVertical.WASTE:
            return t("assessmentOverview.waste.title", "Waste", {
                ns: "translation",
            });
        case AssessmentVertical.WATER:
            return t("assessmentOverview.water.title", "Water", {
                ns: "translation",
            });
        case AssessmentVertical.ENERGY:
            return t("assessmentOverview.energy.title", "Energy Combined", {
                ns: "translation",
            });
        case AssessmentVertical.UN_CATEGORIZED:
            return t(
                "assessmentOverview.uncategorized.title",
                "Uncategorized",
                { ns: "translation" }
            );
        default:
            return "";
    }
};

export const assessmentVerticalToColor = (
    key: AssessmentVertical
): ColorProps => {
    switch (key) {
        case AssessmentVertical.TRANSPORT:
            return "transport";
        case AssessmentVertical.WASTE:
            return "waste";
        case AssessmentVertical.MATERIALS:
            return "materials";
        case AssessmentVertical.HEATING:
            return "heat";
        case AssessmentVertical.ELECTRICITY:
            return "electricity";
        case AssessmentVertical.WATER:
            return "water";
        case AssessmentVertical.COOLING:
            return "cooling";
        case AssessmentVertical.ENERGY:
            return "energy";
        case AssessmentVertical.UN_CATEGORIZED:
            return "uncategorized";
    }
};

const sortedVerticals = _.uniq([
    ...VerticalPriority,
    ...Object.values(AssessmentVertical),
]);

export const verticalSorter = <T extends { vertical: AssessmentVertical }>(
    a: T,
    b: T
) => {
    return (
        sortedVerticals.indexOf(a.vertical) -
        sortedVerticals.indexOf(b.vertical)
    );
};

export const getAssessmentCategoryTranslation = (
    category: AssessmentCategory,
    t: TFunction
) => {
    return match(category)
        .with(AssessmentCategory.CAPITAL_GOODS, () =>
            t("assessmentCategories.CAPITAL_GOODS", "Capital Goods")
        )
        .with(AssessmentCategory.COOLING_REMOTE, () =>
            t("assessmentCategories.COOLING_REMOTE", "Cooling Remote")
        )
        .with(AssessmentCategory.ELECTRICITY_GENERATED, () =>
            t(
                "assessmentCategories.ELECTRICITY_GENERATED",
                "Electricity Generated"
            )
        )
        .with(AssessmentCategory.ELECTRICITY_REMOTE, () =>
            t("assessmentCategories.ELECTRICITY_REMOTE", "Electricity Remote")
        )
        .with(AssessmentCategory.ENERGY_COMBINED, () =>
            t("assessmentCategories.ENERGY_COMBINED", "Energy Combined")
        )
        .with(AssessmentCategory.HEATING_GENERATED, () =>
            t("assessmentCategories.HEATING_GENERATED", "Heating Generated")
        )
        .with(AssessmentCategory.HEATING_REMOTE, () =>
            t("assessmentCategories.HEATING_REMOTE", "Heating Remote")
        )
        .with(AssessmentCategory.PURCHASED_GOODS_OTHER, () =>
            t(
                "assessmentCategories.PURCHASED_GOODS_OTHER",
                "Purchased Goods Other"
            )
        )
        .with(AssessmentCategory.TRANSPORT_BUSINESS_TRAVEL, () =>
            t(
                "assessmentCategories.TRANSPORT_BUSINESS_TRAVEL",
                "Transport Business Travel"
            )
        )
        .with(AssessmentCategory.TRANSPORT_EMPLOYEE_COMMUTING, () =>
            t(
                "assessmentCategories.TRANSPORT_EMPLOYEE_COMMUTING",
                "Transport Employee Commuting"
            )
        )
        .with(AssessmentCategory.TRANSPORT_INTERNAL, () =>
            t("assessmentCategories.TRANSPORT_INTERNAL", "Transport Internal")
        )
        .with(AssessmentCategory.WASTE, () =>
            t("assessmentCategories.WASTE", "Waste")
        )
        .with(AssessmentCategory.WATER, () =>
            t("assessmentCategories.WATER", "Water")
        )
        .otherwise(() => t("assessmentCategories.UNCATEGORIZED"));
};

export const mapConsumptionTypeToVertical = (
    consumptionType: ConsumptionType
) => {
    return match(consumptionType)
        .with(ConsumptionType.ENERGY, () => AssessmentVertical.ENERGY)
        .with(
            P.string.includes("ELECTRICITY"),
            () => AssessmentVertical.ELECTRICITY
        )
        .with(P.string.includes("HEATING"), () => AssessmentVertical.HEATING)
        .with(P.string.includes("WATER"), () => AssessmentVertical.WATER)
        .with(P.string.includes("WASTE"), () => AssessmentVertical.WASTE)
        .with(P.string.includes("COOLING"), () => AssessmentVertical.COOLING)
        .otherwise(() => AssessmentVertical.UN_CATEGORIZED);
};
