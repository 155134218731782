import { TFunction } from "i18next";
import _ from "lodash";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { ChartSeries } from "glue/Chart";
import { PROJECTED_SERIES_COLOR } from "glue/Chart/chart.constants";
import { AssessmentDataType } from "graphql-types/graphql";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

export const getTargetSeries = (
    t: TFunction,
    years: number[],
    dataType: AssessmentDataType,
    assets: any[]
): ChartSeries[] => {
    const showStranded = assets.length === 1;
    const valueFormatter = (value: number) =>
        `${formatNumberLocale(value, t)} t CO2e / year`;

    const data = years.map((year) => {
        const emissionKey =
            dataType === AssessmentDataType.ESTIMATE ? "epc" : "bestEffort";

        const emission = assets
            .flatMap((a) => a.emissionSummaries)
            .filter((a) => DateTime.fromISO(a.from).year === year);
        const projectedEmission = assets
            .flatMap((a) => a.projectedEmissionSummaries)
            .filter((a) => DateTime.fromISO(a.from).year === year);
        const targetEmission = assets
            .flatMap((a) => a.targetEmissions)
            .filter((a) => DateTime.fromISO(a?.from).year === year)
            .map((a) => a?.emissionGrams[emissionKey]);

        const total = _.sumBy(emission, "ownedEmission");
        const projected =
            year === DateTime.now().year
                ? Math.max(
                      _.sumBy(projectedEmission, "ownedEmission") - total,
                      0
                  )
                : _.sumBy(projectedEmission, "ownedEmission");

        const target = targetEmission.length
            ? _.sumBy(targetEmission, "ownedEmission")
            : null;

        const isStranded = showStranded && target && target < total + projected;

        return {
            total,
            target,
            projected,
            stranded: isStranded,
        };
    });

    const total = data.map((a) =>
        getConvertedGramsToMassUnit(a.total, UnitMass.TON)
    );
    const projected = data.map((a) =>
        getConvertedGramsToMassUnit(a.projected, UnitMass.TON)
    );
    const target = data.map((a) =>
        a.target ? getConvertedGramsToMassUnit(a.target, UnitMass.TON) : null
    );
    const stranded = data.map((a) => a.stranded);

    return [
        {
            id: "emission",
            label: t(
                "forecastDashboard.labels.totalEmissions",
                "Total Emissions"
            ),
            stack: "total",
            type: "bar",
            valueFormatter,
            data: total,
        },
        {
            id: "projected",
            type: "bar",
            label: t(
                "forecastDashboard.labels.projectedEmissions",
                "Projected Emissions"
            ),
            stack: "total",
            color: PROJECTED_SERIES_COLOR,
            data: projected,
            valueFormatter,
        },
        {
            id: "target",
            label: t(
                "forecastDashboard.labels.targetEmissions",
                "Target Emissions"
            ),
            type: "line",
            color: "green",
            valueFormatter,
            showMark: (params: any) => stranded[params.index],
            data: target,
        },
    ] as any[];
};
