import { TFunction } from "i18next";
import { DateTime } from "luxon";

import { ReportEntity } from "containers/ReportsListPage/ReportsSaved";
import {
    AssessmentDataType,
    ReportFramework,
    ReportLanguage,
} from "graphql-types/graphql";
import { formatDate } from "utils/date.utils";
import { downloadBuffer, FetchOptions } from "utils/file.utils";

import { DateContext, DateContextType } from "./reportDateContext";
import {
    AssetDisplayType,
    getTranslatedAssessmentType,
    getTranslatedFrameworkName,
    getTranslatedTabName,
    ReportTab,
} from "./reportPageHelpers";

type DownloadReport =
    | ReportEntity
    | (Pick<ReportEntity, "id" | "from" | "segmentName"> & {
          framework: "RealESG";
      });

const getReportDescription = (
    t: TFunction,
    filteredAssets?: number,
    unfilteredAssets?: number,
    reportDescription?: string
) =>
    t(
        "reportsList.reportDescription",
        "Based on {{filteredAssets}} out of {{unfilteredAssets}} assets - {{reportDescription}}",
        { filteredAssets, unfilteredAssets, reportDescription, ns: "report" }
    );

export function getReportGridTitle(reportTab: ReportTab, t: TFunction) {
    switch (reportTab) {
        case ReportTab.SUMMARY:
            return t("labels.emissionOverview", "Emissions — Key Figures", {
                ns: "report",
            });
        case ReportTab.INVENTORY:
            return t("labels.emissionSummary", "Emissions Summary", {
                ns: "report",
            });
        case ReportTab.EMISSIONFACTORS:
            return t("tabs.emissionFactors", "Emission Factors", {
                ns: "report",
            });
        case ReportTab.ENERGYCONVERSIONFACTORS:
            return t(
                "tabs.energyConversionFactors",
                "Energy Conversion Factors",
                {
                    ns: "report",
                }
            );
    }
}

const getReportDateForFileName = (reportDateContext: DateContext) => {
    const fromDate = DateTime.fromISO(reportDateContext.from);
    const toDate = DateTime.fromISO(reportDateContext.to);
    if (reportDateContext.dateContextType === DateContextType.YEAR) {
        return fromDate.year;
    }
    return `${fromDate.toLocaleString()} - ${toDate.toLocaleString()}`;
};

export function getFileToDownloadName(
    t: TFunction,
    dataType: AssessmentDataType,
    frameworkKey: ReportFramework | "AssetsList",
    reportTab: ReportTab | undefined,
    assetDisplay: AssetDisplayType,
    reportDescription: string,
    reportDateContext: DateContext
) {
    const frameworkName = getTranslatedFrameworkName(t, frameworkKey);
    const tabName =
        reportTab !== undefined
            ? `- ${getTranslatedTabName(t, reportTab)} `
            : "";
    const assessmentType = getTranslatedAssessmentType(t, dataType);

    const { filteredAssets, unfilteredAssets } = assetDisplay;

    const description = getReportDescription(
        t,
        filteredAssets,
        unfilteredAssets,
        reportDescription
    );
    const dateDescription = getReportDateForFileName(reportDateContext);

    return `${dateDescription} ${frameworkName} ${tabName}${assessmentType} - ${description}`;
}

export const formatDateContext = (dateContext: DateContext) => {
    const from = DateTime.fromISO(dateContext.from, { setZone: true });
    const to = DateTime.fromISO(dateContext.to, { setZone: true });

    if (!from.isValid || !to.isValid) {
        return "";
    }

    const isFromStartOfYear = from.startOf("day").equals(from.startOf("year"));
    const isToEndOfYear = to.endOf("day").equals(to.endOf("year"));
    const isSameYear = from.year === to.year;

    if (isFromStartOfYear && isToEndOfYear && isSameYear) {
        return from.year.toString();
    }

    return `${formatDate(from)} - ${formatDate(to)}`;
};

export function getReportFileName(
    t: TFunction,
    report: DownloadReport,
    extension = "xlsx"
) {
    if (report.framework === "RealESG") {
        return `${report.from} Real ESG ${report.segmentName}.${extension}`;
    }

    const frameworkName = getTranslatedFrameworkName(t, report.framework);
    const assessmentType = getTranslatedAssessmentType(t, report.source);

    const description = t(
        "reportsList.savedReportsTab.downloadFile.description",
        "Based on {{filteredAssets}} assets - {{reportDescription}}",
        {
            filteredAssets: report.numberOfAssets,
            reportDescription: report.segmentName,
            ns: "report",
        }
    );
    const dateDescription = formatDateContext({
        from: report.from,
        to: report.to,
    } as DateContext);

    return `${dateDescription} ${frameworkName} ${assessmentType} - ${description}.${extension}`;
}

export const getReportLanguageFromI18n = (i18nLanguage: string) => {
    switch (i18nLanguage) {
        case "en":
            return ReportLanguage.EN;
        case "da":
            return ReportLanguage.DA;
        default:
            return ReportLanguage.EN;
    }
};

export const downloadReport = async (
    report: DownloadReport,
    t: TFunction,
    options?: FetchOptions
) => {
    const endpoint = `report/download/${report.id}`;

    const fileName = getReportFileName(t, report);

    return downloadBuffer(endpoint, fileName, options);
};
