import { Navigate, Outlet } from "react-router-dom";

import { UserProvider } from "context";
import { DashboardLayout } from "legacyNext/layouts";
import auth from "utils/auth";

export const LegacyNextRoute = () => {
    if (auth.getAccessToken() === null) return <Navigate to="/auth/login" />;

    return (
        <UserProvider>
            <DashboardLayout>
                <Outlet />
            </DashboardLayout>
        </UserProvider>
    );
};
