import { Card } from "@mui/material";
import { useState } from "react";

import AssessmentOverviewStatsLayout from "components/AssessmentStats/AssessmentOverviewStatsLayout";
import { PortfolioPage } from "components/PortfolioDashboard/types";
import { SubHeaderWithTabs } from "components/SubHeaderWithTabs/SubHeaderWithTabs";

import { AssetOverviewFilters } from "./AssetOverviewFilters";
import { useAssessmentDataTypeContext } from "../AssessmentDataTypeContext";
import AssetBestEffortCoverage from "../AssetDataOverview/AssetBestEffortCoverage";

export const AssetOverview = ({ locationId }: { locationId: string }) => {
    const { assessmentType, setAssessmentType: setActiveTab } =
        useAssessmentDataTypeContext();

    const [activeSubTab, setActiveSubTab] = useState(
        PortfolioPage.YEARLY_BREAKDOWN
    );

    const AssetOverviewContent = () => {
        switch (activeSubTab) {
            case PortfolioPage.COVERAGE:
                return <AssetBestEffortCoverage locationId={locationId} />;
            case PortfolioPage.YEARLY_BREAKDOWN:
            case PortfolioPage.OVERVIEW:
                return (
                    <AssessmentOverviewStatsLayout
                        isYearly={
                            activeSubTab === PortfolioPage.YEARLY_BREAKDOWN
                        }
                        activeTab={assessmentType}
                        locationId={locationId}
                    />
                );
        }
    };

    return (
        <>
            <SubHeaderWithTabs
                tab={{ active: activeSubTab, onChange: setActiveSubTab }}
                assessmentType={{
                    active: assessmentType,
                    onChange: setActiveTab,
                }}
                hasCoverage
            />

            <Card
                sx={{ p: 4, display: "flex", flexDirection: "column", gap: 4 }}
            >
                {activeSubTab !== PortfolioPage.COVERAGE && (
                    <AssetOverviewFilters />
                )}
                <AssetOverviewContent />
            </Card>
        </>
    );
};
