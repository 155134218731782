import { gql } from "@apollo/client";

export const OUTLIER_ASSETS = gql`
    query OutlierAssets(
        $filter: LocationFilter!
        $sorting: LocationSort!
        $dataType: AssessmentDataType!
        $lower: DateTime!
        $upper: DateTime!
    ) {
        locations(paging: { first: 10 }, filter: $filter, sorting: [$sorting]) {
            edges {
                node {
                    id
                    externalId
                    shortAddress
                    displayName
                    name
                    emissionSummaries(
                        from: $lower
                        to: $upper
                        dataType: $dataType
                        aggregation: Month
                    ) {
                        id
                        ownedArea
                        ownedEnergy
                        ownedEmission
                    }
                }
            }
        }
    }
`;
