import { TFunction } from "i18next";
import _, { groupBy } from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import {
    AssetRisk,
    CrremPathway,
    IntensityStranded,
} from "containers/StrategyPage/strategyPage.types";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import { formatValue } from "glue/Chart/components/ChartTooltip";
import { CrremCategory, GetAssetRiskPreviewQuery } from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { StrategySeriesType } from "./chart.types";

export function getXAxisYears(): number[] {
    return _.range(2020, 2051);
}

export function assetRiskListToChartSeries(
    assets: AssetRisk[],
    years: number[],
    type: ChartDisplayType,
    t: TFunction
): StrategySeriesType[] {
    return assets.map((asset) => {
        const { id, constructionYear, externalId } = asset;

        const intensity = years.map((year) =>
            asset[type] && asset[type][year]
                ? asset[type][year].intensity ?? null
                : null
        );
        const stranded = years.map((year) =>
            asset[type] && asset[type][year]
                ? asset[type][year].stranded ?? null
                : null
        );

        return {
            id: `asset-${id}`,
            internalId: externalId ?? undefined,
            label: asset.crremPropertyType ?? undefined,
            constructionYear: constructionYear ?? undefined,
            displayName: asset.displayName,
            stranded,
            data: intensity,
            type: "line",
            curve: "linear",
            color: asset.color || "black",
            valueFormatter: (value: any) =>
                formatValue(value, type, t, true, UnitMass.KILOGRAM) || "",
        };
    });
}

export function crremPathwaysToChartSeries(
    crremPathways: CrremPathway[],
    years: number[],
    type: ChartDisplayType
): StrategySeriesType[] {
    const typeUnit = type === ChartDisplayType.EMISSIONS ? "kgCO2" : "kWh";
    const pathways = _.chain(crremPathways)
        .filter((pathway) => pathway.unit.includes(typeUnit))
        .groupBy((pathway) => pathway.crremCategory)
        .map((pathways): StrategySeriesType => {
            const data = years.map((year) => {
                const pathway = pathways.find(
                    (pathway) => pathway.year === year
                );

                return pathway?.value ?? null;
            });

            const category = pathways[0].crremCategory;

            return {
                id: "crrem-" + category,
                label: category,
                data,
                type: "line",
                curve: "natural",
                color: pathways[0].color,
                showMark: false,
            };
        })
        .value();

    return pathways;
}

function calculateTotal({
    years,
    grouped,
    projected,
    ownedArea,
    pathway,
    dataKey,
    conversionFn,
}: {
    years: number[];
    grouped: Record<number, any[]>;
    projected: Record<number, any[]>;
    ownedArea?: number | null;
    pathway: CrremPathway[];
    dataKey: "ownedEmission" | "ownedEnergy";
    conversionFn?: (value: number) => number;
}) {
    return years.reduce((acc, year) => {
        const items = [...(grouped[year] ?? []), ...(projected[year] ?? [])];
        const data: { stranded: boolean | null; intensity: number | null } = {
            stranded: null,
            intensity: null,
        };

        const valuePerArea = items.reduce((sum, item) => {
            if (!ownedArea || !item[dataKey]) return sum;
            return sum + item[dataKey] / ownedArea;
        }, 0);

        const convertedValue = valuePerArea
            ? conversionFn
                ? conversionFn(valuePerArea)
                : valuePerArea
            : null;

        const pathwayValue = pathway.find((p) => p.year === year)?.value;

        if (convertedValue && pathwayValue) {
            if (convertedValue > pathwayValue) {
                data.stranded = true;
            } else {
                data.stranded = false;
            }
        }

        data.intensity = convertedValue;

        return data.intensity ? { ...acc, [year]: data } : acc;
    }, {} as IntensityStranded);
}

export const getAssetRisk = (query: GetAssetRiskPreviewQuery): AssetRisk[] => {
    const { getAssetGroupPreview: assets, getCrremPathways: pathways } = query;

    return assets.map((asset) => {
        const { externalId, constructionDate, ownedArea, crremPropertyType } =
            asset;
        const years = getXAxisYears();

        const grouped = groupBy(asset.emissionSummaries, (item) =>
            new Date(item.from).getFullYear()
        );
        const projected = groupBy(asset.projectedEmissionSummaries, (item) =>
            new Date(item.from).getFullYear()
        );

        const { emissionPathway, energyPathway } = pathways
            .filter(
                ({ crremCategory }) =>
                    crremCategory === asset.crremPropertyType ||
                    asset.crremPropertyType === CrremCategory.MIXED_USE
            )
            .reduce(
                (acc, pathway) => {
                    if (pathway.unit.includes("kgCO2")) {
                        acc.emissionPathway.push(pathway);
                    } else if (pathway.unit.includes("kWh")) {
                        acc.energyPathway.push(pathway);
                    }
                    return acc;
                },
                { emissionPathway: [], energyPathway: [] } as {
                    emissionPathway: CrremPathway[];
                    energyPathway: CrremPathway[];
                }
            );

        const emissions = calculateTotal({
            years,
            grouped,
            projected,
            ownedArea,
            pathway: emissionPathway,
            dataKey: "ownedEmission",
            conversionFn: (value) =>
                getConvertedGramsToMassUnit(value, UnitMass.KILOGRAM),
        });

        const energy = calculateTotal({
            years,
            grouped,
            projected,
            ownedArea,
            pathway: energyPathway,
            dataKey: "ownedEnergy",
        });

        const constructionYear = constructionDate
            ? new Date(constructionDate).getFullYear()
            : undefined;

        const color = emissionPathway.find(
            ({ crremCategory }) => crremCategory === crremPropertyType
        )?.color;

        return {
            id: asset.id,
            externalId,
            constructionYear,
            color,
            crremPropertyType,
            emissions,
            energy,
            displayName: asset.displayName || undefined,
        };
    });
};
