import { Theme } from "@mui/material";
import { BarSeriesType } from "@mui/x-charts";
import { TFunction } from "i18next";
import _ from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import {
    ChartDisplayType,
    PROJECTED_SERIES_COLOR,
} from "glue/Chart/chart.constants";
import { formatValue } from "glue/Chart/components/ChartTooltip";
import { getCurrentYear } from "utils/date.utils";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getYearLabel } from "../charts.helper";
import { getVerticalSeries } from "../helpers/monthlyChart.helpers";
import { PortfolioChartData } from "../types/chart.types";

export const getTotalChartSeries = (
    summaries: SummaryYearOverview[],
    palette: Theme["palette"],
    type: ChartDisplayType,
    t: TFunction
): PortfolioChartData => {
    const data = summaries.map(({ year, emission, projected }) => {
        const isCurrentYear = getCurrentYear() === year;
        const calculatedProjectedEmission = projected && projected - emission;

        const projectedSeriesData =
            isCurrentYear &&
            calculatedProjectedEmission &&
            calculatedProjectedEmission > 0
                ? calculatedProjectedEmission
                : null;

        return {
            xAxisData: year,
            projectedSeriesData:
                type === ChartDisplayType.EMISSIONS && projectedSeriesData
                    ? getConvertedGramsToMassUnit(
                          projectedSeriesData,
                          UnitMass.TON
                      )
                    : projectedSeriesData,
        };
    });

    const [xAxisData, projectedSeriesData] = _.chain(data)
        .map(Object.values)
        .unzip()
        .value();

    const verticalSeries = getVerticalSeries(
        summaries,
        palette,
        type,
        t,
        false,
        UnitMass.TON
    );

    const series = [
        ...verticalSeries,
        {
            id: "projected",
            type: "bar" as const,
            stack: "total",
            color: PROJECTED_SERIES_COLOR,
            data: projectedSeriesData,
            label: t("portfolioDashboard.boxes.projected", "Projected"),
            valueFormatter: (value: any) =>
                formatValue(value, type, t, false, UnitMass.TON) || "",
        },
    ] as BarSeriesType[];

    return {
        series,
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
    };
};
