import { useTheme } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentChartTooltip } from "components/AssessmentStats/Chart/MUIAssessmentChartTooltip";
import { useDownloadContext } from "components/PortfolioDashboard/helpers/useDownloadContext";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { BenchmarkMarkers } from "components/types";
import { Chart } from "glue/Chart";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import { AssessmentVertical } from "graphql-types/graphql";

import {
    getTotalIntensitySeries,
    getYearlyPortfolioIntensityChartCSVData,
    getYearlyPortfolioIntensityChartData,
} from "./yearlyIntensityChart.helpers";
import { benchmarksToSeries, legacyBenchmarkToSeries } from "../utils";

type Props = {
    yearSummaries: SummaryYearOverview[];
    benchmarkMarkers: BenchmarkMarkers;
    type: ChartDisplayType;
};

export const YearlyIntensityChart = (props: Props) => {
    const { yearSummaries, benchmarkMarkers, type } = props;

    const { t } = useTranslation();
    const { palette } = useTheme();

    const { setDownloadData } = useDownloadContext();

    const { series, xAxis, years } = useMemo(() => {
        const { years } = getYearlyPortfolioIntensityChartData(
            t,
            yearSummaries
        );

        const summaries = yearSummaries.map((summary) => {
            const breakdown = Object.keys(summary.breakdown).reduce(
                (acc, key) => {
                    const vertical = key as AssessmentVertical;
                    const value = summary.breakdown[vertical];

                    return { ...acc, [vertical]: value / summary.totalArea };
                },
                {} as Record<AssessmentVertical, number>
            );

            return {
                ...summary,
                breakdown,
            };
        });

        return {
            years,
            ...getTotalIntensitySeries(summaries, palette, type, t),
        };
    }, [t, yearSummaries, type, palette]);

    const benchmarkSeries = useMemo(() => {
        const benchmarkSeries = benchmarksToSeries(
            benchmarkMarkers,
            years.length,
            type,
            false,
            t
        );
        const legacyBenchmarkSeries = legacyBenchmarkToSeries(
            years,
            benchmarkMarkers,
            type,
            false,
            t
        );

        return [...benchmarkSeries, ...legacyBenchmarkSeries];
    }, [benchmarkMarkers, type, years, t]);

    useEffect(() => {
        const csvData = getYearlyPortfolioIntensityChartCSVData(
            years,
            series,
            benchmarkSeries,
            t
        );

        setDownloadData({
            data: csvData,
            fileName: "yearly-intensity-chart.csv",
        });
    }, [setDownloadData, series, years, benchmarkSeries, t]);

    return (
        <Chart
            series={[...series, ...benchmarkSeries]}
            xAxis={xAxis}
            yAxis={[
                {
                    label:
                        type === ChartDisplayType.EMISSIONS
                            ? "kg CO2e / m²"
                            : "kWh / m²",
                },
            ]}
            legend={{
                slotProps: { seriesToDisplay: series as any },
                hidden: false,
                position: { vertical: "bottom" },
            }}
            customTooltip={AssessmentChartTooltip(type, t)}
        />
    );
};
