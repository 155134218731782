import { Box, Divider } from "@mui/material";
import { ReactNode } from "react";

import { S } from "./chartTooltip.styles";
import Section, { SectionProps } from "./ChartTooltipSection";

type Props = {
    sections: SectionProps[];
    children?: ReactNode;
};

export const ChartTooltip = (props: Props) => {
    const { sections, children } = props;

    return (
        <S.Container>
            {sections.map((section, index) => {
                if (Object.entries(section).length === 0) return null;
                return (
                    <Box key={index}>
                        {index > 0 && <Divider sx={{ my: 4 }} />}
                        <Section key={index} {...section} />
                    </Box>
                );
            })}
            {children}
        </S.Container>
    );
};
