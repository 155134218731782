import { gql } from "@apollo/client";

const ASSET_GROUP_FRAGMENT = gql`
    fragment AssetGroup on AssetGroup {
        id
        name
        assetsAggregate {
            count {
                id
            }
        }
    }
`;

export const GET_ASSET_GROUPS = gql`
    query GetAssetGroups {
        assetGroups(paging: { first: 50 }) {
            edges {
                node {
                    ...AssetGroup
                }
            }
        }
    }
    ${ASSET_GROUP_FRAGMENT}
`;

export const DELETE_ASSET_GROUP = gql`
    mutation DeleteAssetGroup($id: ID!) {
        deleteOneAssetGroup(input: { id: $id }) {
            id
        }
    }
`;

export const CREATE_ASSET_GROUP = gql`
    mutation CreateAssetGroup($input: CreateOneAssetGroupInput!) {
        createOneAssetGroup(input: $input) {
            ...AssetGroup
        }
    }

    ${ASSET_GROUP_FRAGMENT}
`;

export const UPDATE_ASSET_GROUP = gql`
    mutation UpdateAssetGroup($input: UpdateOneAssetGroupInput!) {
        updateOneAssetGroup(input: $input) {
            id
        }
    }
`;

export const GET_ASSET_GROUPS_PREVIEW = gql`
    query GetAssetGroupsPreview($input: [AssetGroupFilterInput!]!) {
        getAssetGroupPreview(assetGroupFilters: $input) {
            id
            displayName
            externalId
        }
    }
`;

export const GET_ASSET_GROUP = gql`
    query GetAssetGroup($id: ID!) {
        assetGroup(id: $id) {
            id
            name
            filters {
                category
                values
            }
            assets {
                id
                displayName
                externalId
            }
        }
    }
`;
