import { styled, Box, ToggleButtonGroup, ToggleButton } from "@mui/material";

export const S = {
    Search: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: theme.spacing(5),
    })),

    Inner: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "row",
        gap: theme.spacing(5),
        alignItems: "center",
    })),

    SearchField: styled(Box)(({ theme }) => ({
        flexGrow: 1,
        mr: theme.spacing(2),
    })),

    SearchViewGroup: styled(ToggleButtonGroup)(({ theme }) => ({
        gap: theme.spacing(5),
    })),

    SearchViewButton: styled(ToggleButton)(({ theme }) => ({
        border: `1px solid ${theme.palette.grey[500]} !important`,
        borderRadius: theme.spacing(4),

        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),

        "&.MuiToggleButtonGroup-firstButton": {
            borderTopRightRadius: theme.spacing(4),
            borderBottomRightRadius: theme.spacing(4),
        },

        "&.MuiToggleButtonGroup-middleButton": {
            borderRadius: theme.spacing(4),
        },

        "&.MuiToggleButtonGroup-lastButton": {
            borderRadius: theme.spacing(4),
        },
    })),

    SearchViewButtonInner: styled(Box)(({ theme }) => ({
        display: "flex",
        gap: theme.spacing(2),
        alignContent: "center",
    })),
};
