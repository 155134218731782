import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentChartTooltip } from "components/AssessmentStats/Chart/MUIAssessmentChartTooltip";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { Chart } from "glue/Chart";
import { ChartDisplayType } from "glue/Chart/chart.constants";

import { getYearlyBuildingAverageSeries } from "./yearlyBuildingAverageChart.helpers";

type Props = {
    yearSummaries: SummaryYearOverview[];
    type: ChartDisplayType;
};

export const YearlyBuildingAverageChart = (props: Props) => {
    const { yearSummaries, type } = props;
    const { t } = useTranslation();
    const { palette } = useTheme();

    const { series, xAxis } = useMemo(() => {
        return getYearlyBuildingAverageSeries(yearSummaries, palette, type, t);
    }, [t, yearSummaries, type, palette]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            legend={{
                hidden: false,
                position: { vertical: "bottom" },
            }}
            yAxis={[
                {
                    label:
                        type === ChartDisplayType.EMISSIONS
                            ? "kg CO2e / m²"
                            : "kWh / m²",
                },
            ]}
            customTooltip={AssessmentChartTooltip(type, t)}
        />
    );
};
