import { useQuery } from "@apollo/client";
import { Box, Card } from "@mui/material";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
    AssessmentDataType,
    GetDataQualityQuery,
    GetDataQualityQueryVariables,
} from "graphql-types/graphql";

import { DATA_QUALITY_QUERY } from "./Queries/dataQualityQuery";
import { Label } from "../../styling/typography";
import { formatDataQualityToDataQualityItems } from "../DataQuality/DataQuality.helpers";
import DataQualityDistributionBar from "../DataQuality/DataQualityDistributionBar";
import { DataQualitySummary } from "../DataQuality/DataQualitySummary";
import InfoPopover from "../Popovers/InfoPopover";
import { useSelectedYearContext } from "../YearSelector/YearContext";

export const DataQualityTooltipInfo = () => (
    <Trans i18nKey="dataQuality.tooltipInfo" style={{ width: 20 }}>
        Our data quality breakdown will define the quality of consumption
        sources used on each asset and aggregate it on a portfolio level. The
        quality parameters are based on the following.
        <br />
        <br />
        <b>High</b> - All automatic meter readings.
        <br />
        <b>Medium</b> - EPC data and manual meter reading with documentation.
        <br />
        <b>Low</b> - EPC proxy data and manual meter reading without
        documentation.
    </Trans>
);

type Props = {
    activeTab: AssessmentDataType;
};

function PortfolioDataQuality(props: Props) {
    const { activeTab } = props;

    const { t } = useTranslation();

    const { activeYear } = useSelectedYearContext();

    const { data, loading } = useQuery<
        GetDataQualityQuery,
        GetDataQualityQueryVariables
    >(DATA_QUALITY_QUERY, {
        variables: {
            from: `${activeYear}-01-01`,
            to: `${activeYear}-12-31`,
            assessmentDataType: activeTab,
        },
    });

    const { dataQuality, items } = useMemo(() => {
        const dataQuality = data?.me.organization?.dataQuality[0];

        if (!dataQuality) return { items: [], dataQuality };

        return {
            items: formatDataQualityToDataQualityItems(dataQuality, t),
            dataQuality,
        };
    }, [data, t]);

    return (
        <Card sx={{ p: 4, gap: 2, display: "flex", flexDirection: "column" }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Label>
                    {t(
                        "portfolioDashboard.boxes.yourDataQuality",
                        "Your Data Quality"
                    )}
                </Label>
                <InfoPopover element={<DataQualityTooltipInfo />} />
            </Box>

            {loading && t("labels.loading", "Loading")}

            {dataQuality && <DataQualityDistributionBar {...dataQuality} />}
            <DataQualitySummary dataQuality={items} hasFullTitle={true} />
        </Card>
    );
}

export default PortfolioDataQuality;
