import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material";
import { AxisValueFormatterContext } from "@mui/x-charts/internals";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DATA_QUALITY_QUERY } from "components/PortfolioDashboard/Queries/dataQualityQuery";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { useReportingYearContext } from "context";
import { Chart } from "glue/Chart";
import {
    AssessmentAggregation,
    AssessmentDataType,
    GetDataQualityQuery,
    GetDataQualityQueryVariables,
} from "graphql-types/graphql";

import { DataQualityTooltip } from "./components/DataQualityTooltip";
import { getDataQualityChartData } from "./dataQualityChart.helpers";

type Props = {
    activeTab: AssessmentDataType;
    byMonth?: boolean;
};

export const DataQualityChart = (props: Props) => {
    const { activeTab, byMonth } = props;

    const { t } = useTranslation();
    const theme = useTheme();

    const { activeYear } = useSelectedYearContext();
    const reportingYear = useReportingYearContext();
    const currentYear = new Date().getFullYear();

    const { data, previousData } = useQuery<
        GetDataQualityQuery,
        GetDataQualityQueryVariables
    >(DATA_QUALITY_QUERY, {
        variables: {
            from: `${byMonth ? activeYear : reportingYear}-01-01`,
            to: `${byMonth ? activeYear : currentYear}-12-31`,
            assessmentDataType: activeTab,
            aggregation: byMonth ? AssessmentAggregation.MONTH : undefined,
        },
    });

    const { xAxis, series } = useMemo(() => {
        const dataLoader = data ?? previousData;
        const dataQuality = dataLoader?.me.organization?.dataQuality ?? [];

        const xAxisData = dataQuality.map(({ from }) =>
            byMonth
                ? DateTime.fromISO(from).monthLong
                : DateTime.fromISO(from).year.toString()
        );

        const series = getDataQualityChartData(dataQuality, theme, t);

        return {
            xAxis: {
                data: xAxisData,
                scaleType: "band" as const,
                valueFormatter: (
                    value: string,
                    context: AxisValueFormatterContext
                ) =>
                    byMonth && context.location === "tick"
                        ? value.substring(0, 3)
                        : value,
            },
            series,
        };
    }, [data, previousData, byMonth, theme, t]);

    return (
        <Chart
            series={series}
            xAxis={[xAxis]}
            yAxis={[{ max: 100, label: "%" }]}
            legend={{ hidden: false, position: { vertical: "bottom" } }}
            customTooltip={DataQualityTooltip}
        />
    );
};
