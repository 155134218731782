import { gql } from "@apollo/client";

export const PORTFOLIO_OVERVIEW_EMISSION_SUMMARY_QUERY = gql`
    query GetPortfolioOverviewEmissionSummaries(
        $from: DateTime
        $assetGroupId: ID
        $dataType: AssessmentDataType!
    ) {
        orgEmissionSummaries(
            assetGroupId: $assetGroupId
            aggregation: Year
            dataType: $dataType
        ) {
            ...DashboardSummary
            ...Breakdown
        }
        orgProjectedEmissionSummaries(
            assetGroupId: $assetGroupId
            aggregation: Year
            dataType: $dataType
            from: $from
            to: $from
        ) {
            ...DashboardProjectedSummary
        }
    }
    fragment DashboardSummary on EmissionSummaryView {
        from
        to
        assetCount
        ownedEmission
        ownedEnergy
        avgEmissionIntensity
        ownedArea
        emissionBreakdown
        energyBreakdown
    }
    fragment DashboardProjectedSummary on ProjectedEmissionSummaryView {
        from
        to
        ownedArea
        assetCount
        ownedEmission
        ownedEnergy
        avgEnergyIntensity
        avgEmissionIntensity
    }

    fragment Breakdown on EmissionSummaryView {
        energyBreakdown
        emissionBreakdown
        energyForAreaBreakdown
        emissionForAreaBreakdown
        avgEnergyIntensityBreakdown
        avgEmissionIntensityBreakdown
    }
`;
