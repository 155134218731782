import { gql } from "@apollo/client";

import { DataQualityFragment } from "components/PortfolioDashboard/Queries/dataQualityQuery";

const AssetFragment = gql`
    fragment Asset on Location {
        id
        externalId
        displayName
        fallbackOwnedArea(
            from: $fromLower
            to: $fromUpper
            dataType: $dataType
            aggregation: Month
        )
        emissionSummaries(
            from: $fromLower
            to: $fromUpper
            dataType: $dataType
            aggregation: Month
        ) {
            id
            vertical
            sourceTypes
            ownedArea
            ownedEmission
        }
        dataQuality(
            from: $fromLower
            to: $fromUpper
            filter: { dataType: $dataType }
        ) @skip(if: $skipDataQuality) {
            ...DataQuality
        }
        benchmarkPerformances(
            filter: {
                dataType: { eq: $dataType }
                benchmarkId: { eq: $benchmarkId }
                from: { between: { lower: $fromLower, upper: $fromUpper } }
            }
        ) @skip(if: $skipPerformance) {
            id
            performance
        }
        epcs(year: $fromYear) @skip(if: $skipClassifications) {
            classification
        }
    }
    ${DataQualityFragment}
`;

export const GET_ASSETS = gql`
    query GetAssets(
        $paging: CursorPaging!
        $filter: LocationFilter!
        $sorting: LocationSort!
        $dataType: AssessmentDataType!
        $fromLower: DateTime!
        $fromUpper: DateTime!
        $benchmarkId: ID
        $fromYear: Float
        $skipPerformance: Boolean!
        $skipDataQuality: Boolean!
        $skipClassifications: Boolean!
    ) {
        locations(paging: $paging, filter: $filter, sorting: [$sorting]) {
            totalCount
            edges {
                node {
                    ...Asset
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
    ${AssetFragment}
`;
