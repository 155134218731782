import { Typography } from "@mui/material";
import { InfoWindow, Marker } from "@vis.gl/react-google-maps";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { InsightsProperty } from "graphql-types/graphql";

import { S } from "./asset-marker.styles";

type Props = {
    asset: InsightsProperty;
};

export const AssetMarker = (props: Props) => {
    const { asset } = props;
    const [selectedMarker, setSelectedMarker] =
        useState<InsightsProperty | null>(null);

    const { t } = useTranslation();

    const location = {
        lat: asset.latitude,
        lng: asset.longitude,
    };

    return (
        <>
            <Marker
                key={asset.id}
                position={location}
                onClick={() => setSelectedMarker(asset)}
            />

            {selectedMarker && (
                <InfoWindow
                    position={location}
                    onCloseClick={() => setSelectedMarker(null)}
                >
                    <S.AssetType>
                        {asset.objectType[0].toUpperCase()}
                    </S.AssetType>

                    <S.InfoWindowContent>
                        <S.Heading>
                            <Typography fontWeight="bold">
                                {asset.fullAddress}
                            </Typography>
                            <S.AssetIdentity>
                                <Typography>{`BFE ${asset.assetExternalId}`}</Typography>
                            </S.AssetIdentity>
                        </S.Heading>
                        <S.AssetPropertyList>
                            <Typography>
                                {t("legacyNext.buildingType", "building type")}
                            </Typography>
                            <Typography textAlign="right">
                                {asset.objectType}
                            </Typography>
                            <Typography>
                                {t("legacyNext.area", "area")}
                            </Typography>
                            <Typography textAlign="right">
                                {t("legacyNext.sqm", "sqm", {
                                    value: asset.area,
                                })}
                            </Typography>
                        </S.AssetPropertyList>
                    </S.InfoWindowContent>
                </InfoWindow>
            )}
        </>
    );
};
