import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

import { BenchmarkMarkers } from "components/types";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    AssessmentDataType,
    GetLegacyBenchmarksQuery,
    GetLegacyBenchmarksQueryVariables,
    LegacyBenchmarkValueType,
} from "graphql-types/graphql";
import { useBenchmarkMarker, useLegacyBenchmarkMarker } from "hooks";
import { LEGACY_BENCHMARKS_QUERY } from "queries/legacyBenchmarksQuery";
import { BenchmarksContext } from "utils/benchmarksContext";

import { getYearRange } from "../Charts/utils";

export const useBenchmarks = (
    assessmentType: AssessmentDataType,
    type: ChartDisplayType
) => {
    const [activeMarkers, setActiveMarkers] = useState<BenchmarkMarkers>([]);

    const { convertToBenchmarkMarker } = useBenchmarkMarker();
    const { convertToLegacyBenchmarkMarker } = useLegacyBenchmarkMarker();
    const { benchmarks } = useContext(BenchmarksContext);

    const { data } = useQuery<
        GetLegacyBenchmarksQuery,
        GetLegacyBenchmarksQueryVariables
    >(LEGACY_BENCHMARKS_QUERY, {
        variables: {
            valueType:
                type === ChartDisplayType.EMISSIONS
                    ? LegacyBenchmarkValueType.EMISSION
                    : LegacyBenchmarkValueType.CONSUMPTION,
        },
    });

    useEffect(
        () => {
            const benchmarkMarkers = convertToBenchmarkMarker(
                assessmentType,
                benchmarks
            );

            const legacyBenchmarks = convertToLegacyBenchmarkMarker(
                data?.getLegacyBenchmarks ?? []
            );

            const markersLegacyBenchmark = legacyBenchmarks.map(
                (marker: any) => ({
                    ...marker,
                    numberOfBuildings:
                        marker.numberOfBuildings / getYearRange(marker).length,
                })
            );

            if (type === ChartDisplayType.ENERGY) {
                setActiveMarkers(markersLegacyBenchmark);
                return;
            }

            setActiveMarkers([...benchmarkMarkers, ...markersLegacyBenchmark]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, benchmarks]
    );

    const onToggleMarkerVisibility = (id: string) => {
        setActiveMarkers((prevMarkers) =>
            prevMarkers.map((marker) => {
                if (marker.id === id) {
                    return { ...marker, isVisible: !marker.isVisible };
                }

                return marker;
            })
        );
    };

    return {
        activeMarkers,
        onToggleMarkerVisibility,
    };
};
