import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentChartTooltip } from "components/AssessmentStats/Chart/MUIAssessmentChartTooltip";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import { OrgProjectedEmissionSummaryFragment } from "graphql-types/graphql";

import {
    getChartData,
    getMonthlyProjectedChartSeries,
} from "./MonthlyTotalChart.helpers";
import {
    getMonthlyChartAxis,
    getVerticalSeries,
} from "../helpers/monthlyChart.helpers";
import { Summary } from "../types/monthlyChart.types";

type Props = {
    summaries: Summary[];
    projected: OrgProjectedEmissionSummaryFragment[];
    type: ChartDisplayType;
};

export const MonthlyTotalChart = (props: Props) => {
    const { summaries, projected, type } = props;

    const { t } = useTranslation();
    const { palette } = useTheme();
    const { activeYear } = useSelectedYearContext();

    const { series, xAxis, yAxis } = useMemo(() => {
        const summariesValues = getChartData(summaries, type);
        const totalKey =
            type === ChartDisplayType.EMISSIONS
                ? "ownedEmission"
                : "ownedEnergy";

        const projectedSeries = getMonthlyProjectedChartSeries({
            summaries: summariesValues,
            projected,
            type,
            totalKey,
            t,
        });

        const series = getVerticalSeries(summariesValues, palette, type, t);
        if (new Date().getFullYear() === activeYear) {
            series.push(projectedSeries);
        }

        return {
            series,
            ...getMonthlyChartAxis(
                projectedSeries.data?.length || summariesValues.length,
                false,
                type
            ),
        };
    }, [summaries, projected, type, palette, t, activeYear]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            legend={{
                hidden: false,
                position: { vertical: "bottom" },
            }}
            customTooltip={AssessmentChartTooltip(type, t)}
        />
    );
};
