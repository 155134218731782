import { Box, Theme, styled } from "@mui/material";
import { Link } from "react-router-dom";

type MenuItemProps = { theme: Theme } & {
    isSelected: boolean;
};

export const S = {
    Menu: styled(Box)(() => ({
        display: "flex",
        flexDirection: "column",
    })),

    MenuItem: styled(Link, {
        shouldForwardProp: (prop) => prop !== "isSelected",
    })((props: MenuItemProps) => {
        const { theme, isSelected } = props;

        return {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),

            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textDecoration: "none",
            color: "inherit",
            borderRight: isSelected ? `${theme.spacing(0.8)} solid` : "none",
            borderRightColor: isSelected
                ? theme.palette.purple.main
                : "transparent",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
        };
    }),

    MenuBody: styled(Box)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        flexGrow: theme.spacing(1),
    })),
};
