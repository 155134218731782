import { gql } from "@apollo/client";

export const FORECAST_DASHBOARD_QUERY = gql`
    query GetForecastAssessments(
        $assetGroupId: ID
        $from: DateTime!
        $to: DateTime!
        $projectedFrom: DateTime!
        $projectedTo: DateTime!
        $dataType: AssessmentDataType!
    ) {
        me {
            id
            organization {
                id
                targetSetting {
                    id
                    type
                    targetYear
                    reduction
                    pathwayName
                }
            }
        }
        getAssets(assetGroupId: $assetGroupId) {
            id
            externalId
            displayName
            emissionSummaries(
                from: $from
                to: $to
                aggregation: Year
                dataType: $dataType
            ) {
                from
                ownedEmission
                ownedEnergy
            }
            projectedEmissionSummaries(
                from: $projectedFrom
                to: $projectedTo
                aggregation: Year
                dataType: $dataType
            ) {
                from
                ownedEmission
                ownedEnergy
            }
            targetEmissions {
                from
                to
                emissionGrams {
                    epc {
                        isStranded
                        ownedEmission
                    }
                    bestEffort {
                        isStranded
                        ownedEmission
                    }
                }
            }
        }

        pathways {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
