import { gql } from "@apollo/client";

const GET_PROPERTY_ALLOCATIONS_FRAGMENT = gql`
    fragment PropertyAllocationFragment on Property {
        allocations {
            id
            assetIntegration {
                id
                dataQuality
                consumptionTypes
            }
        }
    }
`;

export const GET_ASSET_PROPERTY_TREE_FRAGMENT = gql`
    fragment AssetPropertyTreeFragment on Property {
        id
        name
        longAddress
        objectType
        ownershipType
        ...PropertyAllocationFragment
    }
    ${GET_PROPERTY_ALLOCATIONS_FRAGMENT}
`;

export const GET_ASSET_PROPERTY_TREE = gql`
    query GetAssetPropertyTree($assetId: ID!) {
        getAssetPropertyTree(assetId: $assetId) {
            ...AssetPropertyTreeFragment
            children {
                ...AssetPropertyTreeFragment
                children {
                    ...AssetPropertyTreeFragment
                    children {
                        ...AssetPropertyTreeFragment
                        children {
                            ...AssetPropertyTreeFragment
                            children {
                                ...AssetPropertyTreeFragment
                                children {
                                    ...AssetPropertyTreeFragment
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${GET_ASSET_PROPERTY_TREE_FRAGMENT}
`;

export const ADD_ASSET_PROPERTY = gql`
    mutation AddAssetProperty($input: AddAssetPropertyInput!) {
        addAssetProperty(input: $input) {
            id
            name
        }
    }
`;

export const UPDATE_ASSET_PROPERTY = gql`
    mutation UpdateAssetProperty($input: UpdateOnePropertyInput!) {
        updateOneProperty(input: $input) {
            id
            name
        }
    }
`;

export const DELETE_ASSET_PROPERTY = gql`
    mutation DeleteAssetProperty($input: DeleteAssetPropertyInput!) {
        deleteAssetProperty(input: $input)
    }
`;

export const GET_PROPERTY = gql`
    query GetProperty($id: ID!) {
        property(id: $id) {
            id
            identifier
            name
            generalPropertyType
            crremPropertyCategory
            nationalPropertyType
            nationalPropertyTypeValue
            objectType
            ownershipType
            purchaseDate
            saleDate
            updatedAt
            createdAt
            constructionDate
            buildingArea
            address
            parent {
                name
                objectType
            }
        }
    }
`;
