import { Container, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading/Loading";
import { Table, TableHeader, TablePagination } from "components/Table";
import {
    AssessmentDataType,
    ReportFramework,
    ReportInventoryQuery,
} from "graphql-types/graphql";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { REPORT_INVENTORY } from "./reportQueries";
import {
    getColumnSorter,
    getColumnWidth,
    getHeaderInfo,
} from "./reportTables.helpers";
import { useReportQuery } from "./useReportQuery";

const getCellRender = (key: string) => {
    switch (key) {
        case "epcProxy":
            return (params: any) => {
                if (params.value !== DASH_SEPARATOR) {
                    return <Typography color="red">{params.value}</Typography>;
                }
            };
    }
};
export interface InventoryHeader {
    key: string;
    header: string;
}
export const getInventoryColumnDefinition = (
    headers: readonly InventoryHeader[],
    t: TFunction
) => {
    return headers.map(({ key, header }): GridColDef => {
        const sortComparator = getColumnSorter(key);

        return {
            field: key,
            headerName: header,
            width: getColumnWidth(key),
            renderHeader: (params) =>
                TableHeader({ params, infoText: getHeaderInfo(key, t) }),
            renderCell: getCellRender(key),
            ...(sortComparator ? { sortComparator } : {}),
        };
    });
};

export const ReportInventory = ({
    dataType,
    reportType,
}: {
    dataType: AssessmentDataType;
    reportType: ReportFramework;
}) => {
    const { t } = useTranslation();

    const { data, loading } = useReportQuery<ReportInventoryQuery>(
        REPORT_INVENTORY,
        dataType,
        reportType
    );

    const columns = useMemo(() => {
        return data?.getReportInventoryHeaders
            ? getInventoryColumnDefinition(data.getReportInventoryHeaders, t)
            : [];
    }, [data, t]);

    const rows = useMemo(() => data?.getReportInventory || [], [data]);

    if (loading || !data) {
        return (
            <Container sx={{ textAlign: "center", padding: 2 }}>
                {loading ? (
                    <Loading
                        description={t(
                            "labels.loading",
                            "We are processing your report",
                            { ns: "report" }
                        )}
                    />
                ) : (
                    t(
                        "assessment.common.noData",
                        "No available data for this period."
                    )
                )}
            </Container>
        );
    }

    return (
        <Table
            isLoading={loading}
            rows={rows}
            columns={columns}
            sortable={true}
            slotProps={{
                pagination: {
                    ActionsComponent: TablePagination,
                },
            }}
        />
    );
};
