import {
    AnimatedLineProps,
    useDrawingArea,
    useXScale,
    useChartId,
    AnimatedLine,
} from "@mui/x-charts";
import React, { useState } from "react";

import { CrremCategory } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";

type Props = AnimatedLineProps & {
    limit?: number;
};

/**
 * Code from original MUI X Charts library.
 * https://mui.com/x/react-charts/line-demo/#line-with-forecast
 */
export const StrategyLine = (props: Props) => {
    const {
        limit,
        onPointerEnter,
        onPointerDown,
        onPointerLeave,
        onClick,
        cursor,
        ...other
    } = props;

    const clickEvents = {
        onPointerEnter,
        onPointerDown,
        onPointerLeave,
        onClick,
        cursor,
    };
    const hoverActions = {
        onMouseEnter: () => setLineHover(true),
        onMouseLeave: () => setLineHover(false),
    };

    const { translations } = useAssetFilterTranslations();
    const { top, bottom, height, left, width } = useDrawingArea();
    const scale = useXScale();
    const chartId = useChartId();
    const [lineHover, setLineHover] = useState(false);

    const hoverStyle = { strokeWidth: lineHover ? 3 : undefined };

    const isCrrem = props.ownerState.id.toLocaleString().includes("crrem");

    if (limit === undefined || isCrrem) {
        const parseDAttribute = (d: string) => {
            const match = d.match(/M(\d+),(\d+)/);
            if (match) {
                const x = parseFloat(match[1]);
                const y = parseFloat(match[2]);
                return { x, y };
            }
            return { x: 0, y: 0 };
        };
        const firstDataPoint = parseDAttribute(props.d);
        // Gets the CRREM_PROPERTY_TYPE from the id crrem_CRREM_PROPERTY_TYPE
        const label = props.ownerState.id.toString().split("crrem-")[1];

        return (
            <g>
                <AnimatedLine {...other} {...hoverActions} style={hoverStyle} />
                <AnimatedLine
                    {...other}
                    {...hoverActions}
                    style={{
                        opacity: 0,
                        strokeWidth: 14,
                        zIndex: 999999,
                    }}
                />

                {isCrrem && lineHover && (
                    <text
                        x={firstDataPoint.x + 40}
                        y={firstDataPoint.y + 20}
                        fontSize="14"
                        textAnchor="start"
                    >
                        CRREM {translations[label as CrremCategory]} Pathway
                    </text>
                )}
            </g>
        );
    }

    const limitPosition = scale(limit); // Convert value to x coordinate.

    // log all the x positions
    if (limitPosition === undefined) {
        return <AnimatedLine {...other} />;
    }

    const clipIdRight = `${chartId}-${props.ownerState.id}-line-limit-${limit}-2`;
    const clipIdleft = `${chartId}-${props.ownerState.id}-line-limit-${limit}-1`;

    return (
        <React.Fragment>
            {/* Clip to show the line before the limit */}
            <clipPath id={clipIdleft}>
                <rect
                    x={left}
                    y={0}
                    width={limitPosition - left}
                    height={top + height + bottom}
                />
            </clipPath>
            {/* Clip to show the line after the limit */}
            <clipPath id={clipIdRight}>
                <rect
                    x={limitPosition}
                    y={0}
                    width={left + width - limitPosition}
                    height={top + height + bottom}
                />
            </clipPath>
            <g clipPath={`url(#${clipIdleft})`} className="line-before">
                <AnimatedLine
                    {...other}
                    {...clickEvents}
                    {...hoverActions}
                    style={{
                        opacity: 0,
                        strokeWidth: 14,
                        zIndex: 999999,
                    }}
                />
                <AnimatedLine
                    {...other}
                    {...hoverActions}
                    {...clickEvents}
                    style={hoverStyle}
                />
            </g>
            <g clipPath={`url(#${clipIdRight})`} className="line-after">
                <AnimatedLine
                    {...other}
                    {...clickEvents}
                    {...hoverActions}
                    style={{
                        strokeDasharray: "0",
                        opacity: 0,
                        strokeWidth: 14,
                        zIndex: 999999,
                    }}
                />
                <AnimatedLine
                    {...other}
                    {...clickEvents}
                    {...hoverActions}
                    style={hoverStyle}
                />
            </g>
        </React.Fragment>
    );
};
