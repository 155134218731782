import { sumBy } from "lodash";
import { match } from "ts-pattern";

import { UnitMass } from "components/AssessmentStats/types";
import { ChartType } from "components/PortfolioDashboard/types";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    AssessmentDataType,
    EmissionSummary,
    LocationSortFields,
    SortDirection,
} from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

export const geQueryVariables = (
    type: ChartDisplayType,
    dataType: AssessmentDataType,
    activeYear: number,
    chartType: ChartType
) => {
    const between = {
        lower: activeYear.toString(),
        upper: `${activeYear}-12-31:23:59:59`,
    };

    const sortingField = match(chartType)
        .with(ChartType.MONTH_BY_MONTH, () =>
            type === ChartDisplayType.EMISSIONS
                ? LocationSortFields.EMISSIONSORT
                : LocationSortFields.ENERGYSORT
        )
        .with(ChartType.PORTFOLIO_INTENSITY, () =>
            type === ChartDisplayType.EMISSIONS
                ? LocationSortFields.EMISSIONINTENSITYSORT
                : LocationSortFields.ENERGYINTENSITYSORT
        )
        .otherwise(() => LocationSortFields.EMISSIONSORT);

    return {
        filter: { dataType: { eq: dataType }, from: { between } },
        sorting: { field: sortingField, direction: SortDirection.DESC },
        dataType,
        ...between,
    };
};

export const getSumValue = (
    type: ChartDisplayType,
    chartType: ChartType,
    summaries: Pick<
        EmissionSummary,
        "ownedArea" | "ownedEmission" | "ownedEnergy"
    >[]
) => {
    const key =
        type === ChartDisplayType.EMISSIONS ? "ownedEmission" : "ownedEnergy";

    const sum = sumBy(summaries, (summary) => summary[key] || 0);
    const area = summaries[0]?.ownedArea || 0;

    if (chartType === ChartType.PORTFOLIO_INTENSITY && area === 0) {
        return null;
    }

    const total =
        chartType === ChartType.PORTFOLIO_INTENSITY ? sum / area : sum;

    return type === ChartDisplayType.EMISSIONS
        ? getConvertedGramsToMassUnit(total, UnitMass.KILOGRAM)
        : total;
};
