import { useLazyQuery } from "@apollo/client";
import { useState } from "react";

import {
    GetLegacyNextAssetsQuery,
    GetLegacyNextAssetsQueryVariables,
    InsightsProperty,
} from "graphql-types/graphql";
import { SearchAssetView, SearchTerms } from "legacyNext/types";

import { AssetRisk, Header, LegacyMap, Search, SearchList } from "./components";
import { S } from "./home.styles";
import { SEARCH_QUERY } from "./queries";

export const Home = () => {
    const [assetList, setAssetList] = useState<InsightsProperty[]>([]);
    const [selectedView, setSelectedView] = useState<SearchAssetView>("map");

    const [fetchData, { loading }] = useLazyQuery<
        GetLegacyNextAssetsQuery,
        GetLegacyNextAssetsQueryVariables
    >(SEARCH_QUERY);

    const onSearchTermChange = async (searchTerms: SearchTerms) => {
        const { data } = await fetchData({
            variables: {
                searchFilters: searchTerms,
            },
        });

        setAssetList((data?.getLegacyNextAssets as InsightsProperty[]) || []);
    };

    function onSearchViewChange(view: SearchAssetView) {
        setSelectedView(view);
    }

    return (
        <S.Page>
            <S.Content>
                <Header />
                <S.Search>
                    <Search
                        loading={loading}
                        onSearchViewChange={onSearchViewChange}
                        onSearchTermChange={onSearchTermChange}
                    />
                </S.Search>
                <S.SearchView>
                    <S.View visible={selectedView === "map"}>
                        <S.Map>
                            <LegacyMap assetList={assetList} />
                        </S.Map>
                    </S.View>
                    <S.View visible={selectedView === "list"}>
                        <SearchList assetList={assetList} />
                    </S.View>
                    <S.View visible={selectedView === "graph"}>
                        <AssetRisk />
                    </S.View>
                </S.SearchView>
            </S.Content>
        </S.Page>
    );
};
