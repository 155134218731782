import { gql } from "@apollo/client";

export const EMISSION_SUMMARIES_QUERY = gql`
    query GetOrgEmissionSummariesForYearlyBreakdown(
        $aggregation: AssessmentAggregation!
        $from: DateTime!
        $to: DateTime!
        $dataType: AssessmentDataType!
        $assetGroupId: ID
    ) {
        getAssets(from: $from, to: $to, assetGroupId: $assetGroupId) {
            id
            hasData(
                from: $from
                to: $to
                aggregation: $aggregation
                dataType: $dataType
            )
            ownedArea
        }
        orgEmissionSummaries(
            aggregation: $aggregation
            from: $from
            to: $to
            dataType: $dataType
            assetGroupId: $assetGroupId
        ) {
            ...OrgEmissionSummary
            ...Breakdown
        }
        orgProjectedEmissionSummaries(
            aggregation: $aggregation
            from: $from
            to: $to
            dataType: $dataType
            assetGroupId: $assetGroupId
        ) {
            ...OrgProjectedEmissionSummary
        }
    }
    fragment OrgEmissionSummary on EmissionSummaryView {
        from
        assetCount
        avgEnergyIntensity
        avgEmissionIntensity
        ownedEnergyForArea
        ownedEnergy
        ownedEmissionForArea
        ownedEmission
        ownedArea
    }

    fragment OrgProjectedEmissionSummary on ProjectedEmissionSummaryView {
        from
        ownedEnergy
        ownedEmission
        ownedEnergyForArea
        ownedEmissionForArea
        avgEnergyIntensity
        avgEmissionIntensity
        energyBreakdown
        emissionBreakdown
        ownedArea
    }

    fragment Breakdown on EmissionSummaryView {
        energyBreakdown
        emissionBreakdown
        energyForAreaBreakdown
        emissionForAreaBreakdown
        avgEnergyIntensityBreakdown
        avgEmissionIntensityBreakdown
    }
`;
