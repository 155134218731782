import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";

import { useOrganizationContext } from "context";
import {
    GetForecastAssessmentsQuery,
    GetForecastAssessmentsQueryVariables,
    AssessmentDataType,
} from "graphql-types/graphql";

import { FORECAST_DASHBOARD_QUERY } from "./forecastQueries";

export const useForecastQuery = (dataType: AssessmentDataType) => {
    const organizationContext = useOrganizationContext();
    const baselineYear = organizationContext?.baselineYear || null;

    const { data, loading } = useQuery<
        GetForecastAssessmentsQuery,
        GetForecastAssessmentsQueryVariables
    >(FORECAST_DASHBOARD_QUERY, {
        variables: {
            from: DateTime.fromObject({ year: baselineYear || 2018 }),
            to: DateTime.now().endOf("year").toISO(),
            projectedFrom: DateTime.now().startOf("year").toISO(),
            projectedTo: DateTime.fromObject({ year: 2050 })
                .endOf("year")
                .toISO(),
            dataType,
        },
    });

    const organization = data?.me.organization;

    if (!organization) {
        return { data: null, loading };
    }

    const pathways = data?.pathways.edges.map((e) => e.node);

    return {
        data: {
            assets: data.getAssets,
            baselineYear: baselineYear || null,
            targetSettings: organization.targetSetting || null,
            pathways: pathways || [],
        },
        loading,
    };
};
