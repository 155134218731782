import { styled, Box, Theme } from "@mui/material";

type ViewProps = { theme: Theme } & {
    visible: boolean;
};

export const S = {
    Page: styled(Box)(({ theme }) => ({
        display: "grid",
        gridTemplateRows: "30% auto",
        padding: theme.spacing(3, 10),
        gap: theme.spacing(8),
        height: "100%",
    })),

    Content: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        minHeight: `calc(100vh - 100px)`,
        gap: theme.spacing(5),
    })),

    Search: styled(Box)(({ theme }) => ({
        padding: theme.spacing(8, 8),
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(4),
    })),

    SearchView: styled(Box)(({ theme }) => ({
        width: "100%",
        height: "100%",
        padding: theme.spacing(8, 8),
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.spacing(4),
    })),

    View: styled(Box, {
        shouldForwardProp: (prop) => prop !== "visible",
    })((props: ViewProps) => {
        const { visible } = props;
        return {
            width: "100%",
            height: "100%",
            display: visible ? "block" : "none",
        };
    }),

    Map: styled(Box)(({ theme }) => ({
        width: "100%",
        height: "100%",
    })),
};
