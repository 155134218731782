import { useQuery } from "@apollo/client";
import {
    GridRowId,
    GridPaginationModel,
    GridPaginationMeta,
    GridSortModel,
} from "@mui/x-data-grid";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isBoolean } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { match } from "ts-pattern";

import {
    AllocationObjectType,
    ConsumptionType,
    GetAllAssetIntegrationsQuery,
    GetAllAssetIntegrationsQueryVariables,
    GetAssetAutomaticSourcesCountQuery,
    GetAssetAutomaticSourcesCountQueryVariables,
    GetAssetAutomaticSourcesOptionsQuery,
    GetAssetAutomaticSourcesOptionsQueryVariables,
    IntegrationType,
    LocationIntegrationFilter,
    LocationIntegrationSortFields,
    SortDirection,
} from "graphql-types/graphql";

import {
    GET_ASSET_AUTOMATIC_SOURCES,
    GET_ASSET_AUTOMATIC_SOURCES_COUNT,
    GET_ASSET_AUTOMATIC_SOURCES_OPTIONS,
} from "./automaticSourcesTable.query";

export const PAGE_SIZE = 50;

const DEFAULT_SORT_MODEL = {
    field: LocationIntegrationSortFields.TYPE,
    direction: SortDirection.DESC,
};

export type QueryFilter = {
    isActive: boolean | null | undefined;
    integrationTypes: IntegrationType[];
    consumptionTypes: ConsumptionType[];
    allocationTypes: AllocationObjectType[];
};

const formatQueryFilter = (filter: QueryFilter): LocationIntegrationFilter => {
    return {
        isActive: {
            is: filter.isActive,
        },
        consumptionTypes: {
            in: filter.consumptionTypes,
        },
        type: {
            in: filter.integrationTypes.length
                ? filter.integrationTypes
                : undefined,
        },
        allocationTypes: {
            in: filter.allocationTypes,
        },
    };
};

const formatQuerySort = (sortModel: GridSortModel) => {
    const first = sortModel[0];

    if (!first) {
        return DEFAULT_SORT_MODEL;
    }

    const field = match(first.field)
        .with("source", () => LocationIntegrationSortFields.TYPE)
        .with("identifier", () => LocationIntegrationSortFields.ORIGINID)
        .with("from", () => LocationIntegrationSortFields.FROM)
        .with("to", () => LocationIntegrationSortFields.TO)
        .with(
            "allocationNote",
            () => LocationIntegrationSortFields.ALLOCATIONNOTE
        )
        .otherwise(() => LocationIntegrationSortFields.TYPE);

    return {
        field,
        direction: first.sort?.toUpperCase() as SortDirection,
    };
};

/**
 * Based on the pagination guide on the official documentation:
 * https://mui.com/x/react-data-grid/pagination/#cursor-based-pagination
 * @param assetId
 */
export const useAssetIntegrationsQuery = (assetId: string) => {
    const { useConsumptionAllocation } = useFlags();

    // Filtering state
    const [filters, setFilters] = useState<QueryFilter>({
        allocationTypes: [],
        consumptionTypes: [],
        isActive: null,
        integrationTypes: [],
    });

    const handleFilterChange = (key: keyof QueryFilter, value: any) => {
        setFilters((prevFilter) => ({ ...prevFilter, [key]: value }));
    };

    // Pagination model
    const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>({});
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: PAGE_SIZE,
    });

    // Sorting state
    const [queryOptions, setQueryOptions] = useState(DEFAULT_SORT_MODEL);

    // Query
    const { data, previousData, loading } = useQuery<
        GetAllAssetIntegrationsQuery,
        GetAllAssetIntegrationsQueryVariables
    >(GET_ASSET_AUTOMATIC_SOURCES, {
        variables: {
            filter: { assetId: { eq: assetId }, ...formatQueryFilter(filters) },
            paging: {
                first: PAGE_SIZE,
                after: mapPageToNextCursor.current[paginationModel.page - 1],
            },
            sorting: queryOptions,
            skipAllocation: !useConsumptionAllocation,
        },
    });

    // Query total count
    const { data: totalCountData } = useQuery<
        GetAssetAutomaticSourcesCountQuery,
        GetAssetAutomaticSourcesCountQueryVariables
    >(GET_ASSET_AUTOMATIC_SOURCES_COUNT, {
        variables: { assetId },
    });
    const totalCount = totalCountData?.locationIntegrations.totalCount ?? 0;

    const { hasNextPage, endCursor } =
        data?.locationIntegrations.pageInfo || {};

    // Filter Options
    const { data: filterOptionsData } = useQuery<
        GetAssetAutomaticSourcesOptionsQuery,
        GetAssetAutomaticSourcesOptionsQueryVariables
    >(GET_ASSET_AUTOMATIC_SOURCES_OPTIONS, {
        variables: { assetId },
    });

    const filterOptions = {
        consumptionTypes: filterOptionsData?.location.consumptionTypes ?? [],
        integrationTypes: filterOptionsData?.location.integrationTypes ?? [],
    };

    // Pagination model change
    const handlePaginationModelChange = (
        newPaginationModel: GridPaginationModel
    ) => {
        // We have the cursor, we can allow the page transition.
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setPaginationModel(newPaginationModel);
        }
    };

    // Row count
    const [rowCount, setRowCount] = useState(0);
    useEffect(() => {
        const currentRowCount = data?.locationIntegrations.totalCount;

        setRowCount((prevRowCountState) =>
            currentRowCount !== undefined ? currentRowCount : prevRowCountState
        );
    }, [data?.locationIntegrations.totalCount]);

    // Pagination meta
    const paginationMetaRef = useRef<GridPaginationMeta>();
    const paginationMeta = useMemo(() => {
        if (
            isBoolean(hasNextPage) &&
            paginationMetaRef.current?.hasNextPage !== hasNextPage
        ) {
            paginationMetaRef.current = { hasNextPage };
        }
        return paginationMetaRef.current;
    }, [hasNextPage]);

    // Save the cursor for the next page
    useEffect(() => {
        if (!loading && endCursor) {
            mapPageToNextCursor.current[paginationModel.page] = endCursor;
        }
    }, [paginationModel.page, loading, endCursor]);

    const sources =
        data?.locationIntegrations.edges.map((edge) => edge.node) ??
        previousData?.locationIntegrations.edges.map((edge) => edge.node) ??
        [];

    // Sorting
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptions(formatQuerySort(sortModel));
    }, []);

    return {
        sources,
        loading,
        totalCount,
        filterProps: {
            queryFilter: filters,
            filterOptions,
            onFilterChange: handleFilterChange,
        },
        tablePaginationProps: {
            rowCount,
            pageSizeOptions: [PAGE_SIZE],
            paginationModel,
            paginationMeta,
            onPaginationModelChange: handlePaginationModelChange,
            onSortModelChange: handleSortModelChange,
        },
    };
};
