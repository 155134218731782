import { GridSortModel } from "@mui/x-data-grid";
import { match } from "ts-pattern";

import { DropDownPerformanceType } from "components/AssetList/components/Filters/RadioDropdown";
import {
    AssessmentDataType,
    LocationFilter,
    LocationSortFields,
    SortDirection,
} from "graphql-types/graphql";

export type QueryFilter = {
    dataType: AssessmentDataType;
    inputString: string | null;
    performance: DropDownPerformanceType;
};

const getCurrentYearAssetsFilter = (activeYear: number): LocationFilter => {
    const salesOr = {
        or: [
            { saleDate: { is: null } },
            { saleDate: { gte: activeYear.toString() } },
        ],
    };

    const purchaseOr = {
        or: [
            { purchaseDate: { is: null } },
            { purchaseDate: { lte: (activeYear + 1).toString() } },
        ],
    };

    return {
        and: [salesOr, purchaseOr],
    };
};

const getInputStringFilter = (inputString: string | null): LocationFilter => {
    if (!inputString) return {};

    return {
        or: [
            { externalId: { iLike: `${inputString}%` } },
            { displayName: { iLike: `%${inputString}%` } },
        ],
    };
};

const getPerformanceFilter = (
    performance: DropDownPerformanceType
): LocationFilter => {
    return match(performance)
        .with(DropDownPerformanceType.ABOVE_BENCHMARK, () => ({
            performance: { gt: 1 },
        }))
        .with(DropDownPerformanceType.BELOW_BENCHMARK, () => ({
            performance: { lt: 1 },
        }))
        .otherwise(() => ({}));
};

export const formatQueryFilter = (
    filter: QueryFilter,
    activeYear: number,
    benchmarkId?: string
): LocationFilter => {
    /** Needed for relation sorting/filtering **/
    const defaultFilters = {
        dataType: { eq: filter.dataType },
        benchmarkId: { eq: benchmarkId },
        from: {
            between: {
                lower: `${activeYear}-01-01T00:00:00Z`,
                upper: `${activeYear}-12-31T23:59:59.999Z`,
            },
        },
    };

    return {
        ...getCurrentYearAssetsFilter(activeYear),
        ...getInputStringFilter(filter.inputString),
        ...getPerformanceFilter(filter.performance),
        ...defaultFilters,
    };
};

export const formatQuerySort = (sortModel: GridSortModel) => {
    const first = sortModel[0];

    if (!first) {
        return {
            field: LocationSortFields.EXTERNALID,
            direction: SortDirection.ASC,
        };
    }

    const field = match(first.field)
        .with("externalId", () => LocationSortFields.EXTERNALID)
        .with("displayName", () => LocationSortFields.DISPLAYNAME)
        .with("totalEmission", () => LocationSortFields.EMISSIONSORT)
        .with("performance", () => LocationSortFields.PERFORMANCE)
        .with("emissionPerArea", () => LocationSortFields.EMISSIONINTENSITYSORT)
        .otherwise(() => LocationSortFields.EXTERNALID);

    return {
        field,
        direction: first.sort?.toUpperCase() as SortDirection,
    };
};
