import { useTranslation } from "react-i18next";

import { UnitMass } from "components/AssessmentStats/types";
import { AssessmentDataType } from "graphql-types/graphql";
import { Label } from "styling/typography";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

import PortfolioDataQuality from "./PortfolioDataQuality";
import { S } from "./sidebar.styles";

type Keys = "assetCount" | "totalArea" | "ownedEmission" | "ownedEnergy";

type Props = {
    dataType: AssessmentDataType;
    data: Record<Keys, number>;
};

export const Sidebar = (props: Props) => {
    const { dataType, data } = props;

    const { t } = useTranslation();

    const items = [
        {
            title: t("portfolioDashboard.boxes.assets", "Active Assets"),
            value: data.assetCount,
        },
        {
            title: t("portfolioDashboard.boxes.area", "Total Area"),
            value: `${formatNumberLocale(data.totalArea, t, { max: 0 })} m²`,
        },
        {
            title: t("portfolioDashboard.boxes.emissions", "Total Emissions"),
            value: `${formatNumberLocale(
                getConvertedGramsToMassUnit(data.ownedEmission, UnitMass.TON),
                t,
                { max: 0 }
            )} t CO2e`,
        },
        {
            title: t(
                "portfolioDashboard.boxes.energy",
                "Total Energy Consumption"
            ),
            value: `${formatNumberLocale(data.ownedEnergy, t, { max: 0 })} kWh`,
        },
    ];

    return (
        <S.Container>
            {items.map((item) => (
                <S.Card key={item.title}>
                    <Label>{item.title}</Label>
                    <S.Value>{item.value}</S.Value>
                </S.Card>
            ))}

            <PortfolioDataQuality activeTab={dataType} />
        </S.Container>
    );
};
