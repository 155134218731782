import { Theme } from "@mui/material";
import { BarSeriesType } from "@mui/x-charts";
import { SeriesValueFormatter } from "@mui/x-charts/internals";
import { TFunction } from "i18next";
import _ from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import {
    ChartDisplayType,
    PROJECTED_SERIES_COLOR,
} from "glue/Chart/chart.constants";
import {
    AbsoluteLineSeriesType,
    xAxisBandConfig,
} from "glue/Chart/chart.types";
import { formatValue } from "glue/Chart/components/ChartTooltip";
import { getCurrentYear } from "utils/date.utils";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

import { YearlyIntensitySeriesType } from "./yearlyIntensityChart.types";
import { getFormattedValueToMassUnit, getYearLabel } from "../charts.helper";
import { getVerticalSeries } from "../helpers/monthlyChart.helpers";
import { PortfolioChartData } from "../types/chart.types";

export function getYearlyPortfolioIntensityChartData(
    t: TFunction,
    yearSummaries: SummaryYearOverview[]
): {
    series: YearlyIntensitySeriesType[];
    xAxis: xAxisBandConfig[];
    years: number[];
} {
    const data = yearSummaries.map(
        ({ year, assetCount, totalArea, emission, projectedEmission }) => {
            const isCurrentYear = getCurrentYear() === year;

            const intensity = totalArea ? emission / totalArea : null;
            const projectedIntensity = isCurrentYear
                ? projectedEmission / totalArea
                : null;

            const calculatedProjectedIntensity =
                projectedIntensity &&
                intensity &&
                projectedIntensity - intensity;

            return {
                xAxisData: year,
                assetCount: assetCount,
                totalArea: totalArea,
                seriesData: intensity
                    ? getConvertedGramsToMassUnit(intensity, UnitMass.KILOGRAM)
                    : null,
                projectedSeriesData: calculatedProjectedIntensity
                    ? getConvertedGramsToMassUnit(
                          calculatedProjectedIntensity,
                          UnitMass.TON
                      )
                    : null,
            };
        }
    );

    const [xAxisData, assetCount, totalArea, seriesData, projectedSeriesData] =
        _.chain(data).map(Object.values).unzip().value();

    const valueFormatter: SeriesValueFormatter<number | null> = (value) =>
        getFormattedValueToMassUnit(value, UnitMass.KILOGRAM, t) || "";

    return {
        series: [
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.intensity", "Intensity"),
                stack: "total",
                id: "emission",
                data: seriesData,
                valueFormatter,
                assetCount,
                totalArea,
            },
            {
                type: "bar",
                label: t("portfolioDashboard.boxes.projected", "Projected"),
                stack: "total",
                id: "projected",
                color: PROJECTED_SERIES_COLOR,
                data: projectedSeriesData,
                valueFormatter,
            },
        ],
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
        years: xAxisData,
    };
}

export const getTotalIntensitySeries = (
    summaries: SummaryYearOverview[],
    palette: Theme["palette"],
    type: ChartDisplayType,
    t: TFunction
): PortfolioChartData => {
    const data = summaries.map(({ year, totalArea, emission, projected }) => {
        const isCurrentYear = getCurrentYear() === year;

        const intensity = totalArea ? emission / totalArea : null;
        const projectedIntensity =
            isCurrentYear && projected && intensity
                ? projected / totalArea - intensity
                : null;

        return {
            xAxisData: year,
            projectedSeriesData:
                projectedIntensity && projectedIntensity > 0
                    ? type === ChartDisplayType.EMISSIONS
                        ? getConvertedGramsToMassUnit(
                              projectedIntensity,
                              UnitMass.KILOGRAM
                          )
                        : projectedIntensity
                    : null,
        };
    });

    const [xAxisData, projectedSeriesData] = _.chain(data)
        .map(Object.values)
        .unzip()
        .value();

    const verticalSeries = getVerticalSeries(summaries, palette, type, t, true);

    const series = [
        ...verticalSeries,
        {
            id: "projected",
            type: "bar" as const,
            stack: "total",
            data: projectedSeriesData,
            color: PROJECTED_SERIES_COLOR,
            label: t("portfolioDashboard.boxes.projected", "Projected"),
            valueFormatter: (value: any) =>
                formatValue(value, type, t, true, UnitMass.KILOGRAM) || "",
        },
    ] as BarSeriesType[];

    return {
        series,
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
        years: xAxisData,
    };
};

const formatSeries = (
    label: any,
    data: (number | null)[] | undefined,
    t: TFunction
) => [
    label?.toString() ?? "",
    ...(data?.map((value) =>
        value !== null ? `${formatNumberLocale(value, t, { max: 3 })}` : ""
    ) ?? []),
    t(
        "portfolioDashboard.locationTable.subheader.emissionPerSize",
        "kg/CO2e/m²"
    ),
];

export function getYearlyPortfolioIntensityChartCSVData(
    years: number[],
    series: YearlyIntensitySeriesType[],
    benchmarkSeries: AbsoluteLineSeriesType[],
    t: TFunction
): string[][] {
    const headers = ["", ...years.map(String), t("common.labels.unit", "Unit")];

    const rows = [
        ...series.map(({ label, data }) => formatSeries(label, data, t)),
        formatSeries(
            t("portfolioDashboard.boxes.assets", "Assets"),
            series[0]?.assetCount,
            t
        ),
        formatSeries(
            t("portfolioDashboard.boxes.area", "Area"),
            series[0]?.totalArea,
            t
        ),
        ...benchmarkSeries.map(({ label, data }) =>
            formatSeries(label, data, t)
        ),
    ];

    return [headers, ...rows];
}
