import {
    Checkbox,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";

import { AssetGroupFilterCategory, CrremCategory } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";
import { FilterItemOption, FilterValue } from "utils/asset-group-filters";

import { S } from "../../page.styles";
type Props = {
    selectedFilters: FilterItemOption;
    onChange: (filter: FilterItemOption) => void;
};

export function FilterOptionCrremPropertyType(props: Props) {
    const { selectedFilters, onChange } = props;

    const { translations } = useAssetFilterTranslations();

    const [value, setValue] = useState<string[]>([]);

    useEffect(() => {
        const selectedFilterValues = selectedFilters.values as FilterValue[];

        const selectedValues = selectedFilterValues.map(
            (valueItem) => translations[valueItem.key as CrremCategory]
        );

        setValue(selectedValues);
    }, [selectedFilters, setValue, translations]);

    const propertyTypeList = Object.keys(CrremCategory).map(
        (propertyTypeKey) => ({
            key: propertyTypeKey,
            value: translations[propertyTypeKey as CrremCategory],
        })
    );

    function onSelectedValue(event: SelectChangeEvent<typeof value>) {
        const selectedValues = event.target.value as string[];

        setValue(selectedValues);

        const filterValues = selectedValues.map((valueItem) => {
            const propertyItem = propertyTypeList.find(
                (option) => option.value === valueItem
            ) as FilterValue;

            return {
                ...propertyItem,
            };
        });

        onChange({
            category: AssetGroupFilterCategory.CRREMPROPERTYTYPES,
            values: filterValues,
        });
    }

    return (
        <S.FilterWrapper>
            <InputLabel id="asset-filter-crrem-label">
                {translations[AssetGroupFilterCategory.CRREMPROPERTYTYPES]}
            </InputLabel>
            <Select
                labelId="asset-filter-crrem-label"
                id="asset-filter-crrem"
                multiple
                value={value}
                onChange={onSelectedValue}
                input={
                    <OutlinedInput
                        label={
                            translations[
                                AssetGroupFilterCategory.CRREMPROPERTYTYPES
                            ]
                        }
                    />
                }
                renderValue={(selected) => selected.join(", ")}
            >
                {propertyTypeList.map((propertyType) => (
                    <MenuItem key={propertyType.key} value={propertyType.value}>
                        <Checkbox
                            checked={value.includes(propertyType.value)}
                        />
                        <ListItemText primary={propertyType.value} />
                    </MenuItem>
                ))}
            </Select>
        </S.FilterWrapper>
    );
}
