import {
    Settings as SettingsIcon,
    ExitToApp,
    ListAltOutlined,
} from "@mui/icons-material";
import {
    Box,
    Divider,
    ListItemIcon,
    MenuItem,
    styled,
    Typography,
} from "@mui/material";
import { Settings } from "luxon";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useUserContext } from "context";

import NavMenu from "../../components/NavMenu/NavMenu";
import auth from "../../utils/auth";

const HeaderBarContainer = styled(Box)(({ theme }) => ({
    height: theme.spacing(20),
    display: "flex",
    width: "fit-content",
    float: "right",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
}));

const LanguageBox = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr min-content min-content min-content",
    alignItems: "center",
    padding: theme.spacing(3, 7),
}));

const NavText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[400],
    padding: theme.spacing(3, 7),
}));

const HeaderBar = () => {
    const user = useUserContext();
    const { t, i18n } = useTranslation();

    const { name, organization } = user || {};

    useEffect(() => {
        const luxonLocale = i18n.language === "en" ? "en-GB" : i18n.language;
        Settings.defaultLocale = luxonLocale;
    }, [i18n.language]);

    const changeLanguage = useCallback(
        (language: string) => {
            i18n.changeLanguage(language);
            const luxonLocale = language === "en" ? "en-GB" : language;
            Settings.defaultLocale = luxonLocale;
        },
        [i18n]
    );

    const menuItems = [
        {
            text: t("navbar.settings", " Account Settings"),
            icon: <SettingsIcon fontSize="small" color="primary" />,
            link: "/settings",
        },
        {
            text: t("navbar.changeLog", "Change Log"),
            icon: <ListAltOutlined fontSize="small" color="primary" />,
            link: "/change-log",
        },
        {
            text: t("navbar.logOut", "Log Out"),
            icon: <ExitToApp fontSize="small" color="primary" />,
            link: "/auth/login",
            onClick: () => {
                auth.logOut();
            },
        },
    ];

    return (
        <HeaderBarContainer>
            <NavMenu>
                <NavText>{t("navbar.account", "Account")}</NavText>
                {organization && (
                    <NavText fontWeight="bold">{organization.name}</NavText>
                )}
                {name && <NavText>{name}</NavText>}
                <Divider sx={{ margin: "0.5rem 0" }} />
                <NavText>{t("navbar.profile", "Profile")}</NavText>

                {menuItems.map((item) => (
                    <MenuItem
                        sx={(theme) => ({
                            padding: theme.spacing(3, 7),
                        })}
                        component={Link}
                        key={item.text}
                        to={item.link}
                        onClick={item.onClick}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <Typography>{item.text}</Typography>
                    </MenuItem>
                ))}
                <Divider />
                <LanguageBox>
                    <Typography color="grey.400">
                        {t("navbar.language", "Language")}:
                    </Typography>
                    <MenuItem
                        selected={i18n.language === "da"}
                        onClick={() => changeLanguage("da")}
                    >
                        Dansk
                    </MenuItem>
                    <MenuItem
                        selected={i18n.language === "en"}
                        onClick={() => changeLanguage("en")}
                    >
                        English
                    </MenuItem>
                </LanguageBox>
            </NavMenu>
        </HeaderBarContainer>
    );
};

export default HeaderBar;
