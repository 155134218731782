import _, { sumBy } from "lodash";
import { DateTime } from "luxon";
import { match } from "ts-pattern";

import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    Benchmark,
    AssessmentDataType,
    OrgEmissionSummaryFragment,
    DashboardProjectedSummaryFragment,
    DashboardSummaryFragment,
    BreakdownFragment,
    TargetEmissionDetails,
    Location,
} from "graphql-types/graphql";
import { getCurrentYear } from "utils/date.utils";

import { ChartType, SummaryYearOverview } from "../types";

export function getTotalArea(
    locations: Pick<Location, "ownedArea">[] | undefined
) {
    return locations
        ? _.chain(locations)
              .map((l) => l.ownedArea)
              .sum()
              .value()
        : 0;
}

export const getAssessmentTypeEmission = (
    type: AssessmentDataType,
    e: Omit<TargetEmissionDetails, "meter">
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return e.epc.ownedEmission;
        default:
            return e.bestEffort.ownedEmission;
    }
};

export const getSummaryYearOverviewForEmissionSummaries = (
    summaries: (DashboardSummaryFragment & BreakdownFragment)[],
    projectedSummaries: DashboardProjectedSummaryFragment[],
    chartType: ChartType,
    type: ChartDisplayType
) => {
    const summaryYearOverviews = _.chain(summaries)
        .map((summary): SummaryYearOverview => {
            const ownedKey =
                type === ChartDisplayType.EMISSIONS
                    ? "ownedEmission"
                    : "ownedEnergy";

            const year = DateTime.fromISO(summary.from, { zone: "local" }).year;
            const isCurrentYear = getCurrentYear() === year;
            const emission = summary[ownedKey] || 0;

            const totalArea = summary.ownedArea || 0;
            const assetCount = summary.assetCount || 0;
            const projectedEmission = isCurrentYear
                ? projectedSummaries[0]?.[ownedKey] || 0
                : 0;

            const projectedBuildingAverageEmissionPerArea = isCurrentYear
                ? projectedSummaries[0]?.avgEmissionIntensity || 0
                : undefined;

            const projectedKey: keyof DashboardProjectedSummaryFragment = match(
                chartType
            )
                .with(
                    ChartType.TOTAL_EMISSION,
                    ChartType.PORTFOLIO_INTENSITY,
                    () =>
                        type === ChartDisplayType.EMISSIONS
                            ? "ownedEmission"
                            : "ownedEnergy"
                )
                .with(ChartType.WEIGHTED_AVERAGE, () =>
                    type === ChartDisplayType.EMISSIONS
                        ? "avgEmissionIntensity"
                        : "avgEnergyIntensity"
                )
                .otherwise(() => "ownedEmission");

            const projectedSummary = isCurrentYear
                ? projectedSummaries[0]
                : null;

            const breakdownKey: keyof BreakdownFragment = match(chartType)
                .with(ChartType.TOTAL_EMISSION, () =>
                    type === ChartDisplayType.EMISSIONS
                        ? "emissionBreakdown"
                        : "energyBreakdown"
                )
                .with(ChartType.PORTFOLIO_INTENSITY, () =>
                    type === ChartDisplayType.EMISSIONS
                        ? "emissionForAreaBreakdown"
                        : "energyForAreaBreakdown"
                )
                .with(ChartType.WEIGHTED_AVERAGE, () =>
                    type === ChartDisplayType.EMISSIONS
                        ? "avgEmissionIntensityBreakdown"
                        : "avgEnergyIntensityBreakdown"
                )
                .otherwise(() => "emissionBreakdown");

            return {
                breakdown: summary[breakdownKey] || {},
                buildingAverageEmissionPerArea:
                    summary.avgEmissionIntensity || 0,
                projectedBuildingAverageEmissionPerArea,
                projectedEmission,
                projected: projectedSummary?.[projectedKey] || null,
                assetCount,
                totalArea,
                emission,
                year,
            };
        })
        .value();

    return _.sortBy(summaryYearOverviews, ["year"]);
};

export const getBenchmark = (
    type: AssessmentDataType,
    benchmark: Benchmark
) => {
    switch (type) {
        case AssessmentDataType.ESTIMATE:
            return benchmark.emissionPerArea.epc.benchmark || null;
        case AssessmentDataType.ACTUAL:
            return benchmark.emissionPerArea.meter.benchmark || null;
        default:
            return benchmark.emissionPerArea.bestEffort.benchmark || null;
    }
};

export const getSumOrganizationEmissionSummariesKey = (
    organizationEmissionSummaries: Pick<
        OrgEmissionSummaryFragment,
        "ownedEmission" | "ownedEnergy"
    >[],
    key: "ownedEmission" | "ownedEnergy"
) => {
    return sumBy(organizationEmissionSummaries, (es) => es[key] || 0);
};
