import { Avatar, Box, Card, Typography, styled } from "@mui/material";

export const S = {
    Container: styled(Box)(({ theme }) => ({
        position: "relative",
        padding: theme.spacing(2),
        minHeight: 400,
        height: "100%",
        "@media print": {
            margin: "0 1rem",
            padding: "0 1rem",
        },
    })),

    Card: styled(Card)(({ theme }) => ({
        width: "100%",
        padding: theme.spacing(4),
    })),

    PrintContainer: styled(Box)(() => ({
        display: "none",
        gap: "1rem",

        "@media print": {
            display: "flex",
        },

        "&.footer": {
            display: "none",
            position: "fixed",
            alignContent: "center",
            alignItems: "center",

            left: 30,
            bottom: 30,

            "@media print": {
                display: "flex",
            },
        },
    })),

    Footer: styled(Typography)(({ theme }) => ({
        fontSize: "0.75rem",
        color: theme.palette.primary.main,
    })),

    Logo: styled(Avatar)(() => ({
        top: 30,
        right: 30,
        width: 50,
        height: 50,
        position: "fixed",
    })),

    FooterLogo: styled("img")(() => ({
        width: "20%",
        height: "30%",
        marginBottom: 0,
        display: "none",
    })),

    PrintHeader: styled(Box)(() => ({
        marginTop: 100,
    })),

    Title: styled(Typography)(() => ({
        fontSize: "1.5rem",
        fontWeight: 600,
        marginBottom: 0,
    })),

    Subtitle: styled(Typography)(() => ({
        fontSize: "1.25rem",
        marginBottom: 0,
    })),
};
