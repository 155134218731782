import { InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
    ChartDisplayType,
    chartTypeTranslations,
} from "glue/Chart/chart.constants";

import { S } from "../../page.styles";

type Props = {
    value: ChartDisplayType;
    onSelectedValue: (event: SelectChangeEvent<ChartDisplayType>) => void;
    disabled?: boolean;
};

export const DisplayTypeSelector = (props: Props) => {
    const { value, onSelectedValue, disabled } = props;
    const { t } = useTranslation();

    return (
        <S.FilterWrapper>
            <InputLabel id="display-type-filter-label">
                {t("strategy.displayType", "Display Type")}
            </InputLabel>
            <Select
                labelId="display-type-filter-label"
                id="display-type-filter"
                label={t("strategy.displayType", "Display Type")}
                value={value}
                disabled={disabled}
                onChange={onSelectedValue}
            >
                {Object.entries(ChartDisplayType).map(([key, type]) => (
                    <MenuItem key={key} value={type}>
                        {chartTypeTranslations(type, t)}
                    </MenuItem>
                ))}
            </Select>
        </S.FilterWrapper>
    );
};
