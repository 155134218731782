import { TFunction } from "i18next";

export enum ChartDisplayType {
    EMISSIONS = "emissions",
    ENERGY = "energy",
}

export const PROJECTED_SERIES_COLOR = "#00000020";

export const chartTypeTranslations = (type: ChartDisplayType, t: TFunction) => {
    switch (type) {
        case ChartDisplayType.EMISSIONS:
            return t("pageheader.emissions", "Emissions");
        case ChartDisplayType.ENERGY:
            return t("pageheader.energy", "Energy");
    }
};
