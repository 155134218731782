import { GridRowModel } from "@mui/x-data-grid";
import _ from "lodash";
import { DateTime } from "luxon";
import { match, P } from "ts-pattern";

import {
    AssessmentCategory,
    AssessmentDataType,
    ConsumptionType,
    CreateAssessmentInput,
    CreateLocationMetadataInput,
    EmissionUnit,
    GetLocationsQuery,
} from "graphql-types/graphql";
import { isValidNumber } from "utils/maths";
import { isValidUrl } from "utils/report.helpers";

import { ParsedCsvRow } from "./dataUploadTypes";

export type Asset = NonNullable<
    GetLocationsQuery["me"]["organization"]
>["locations"]["edges"][0]["node"];

export const parseDate = (date?: string) => {
    if (!date) {
        return undefined;
    }

    const dateWithoutTime = date.split("T")[0];

    const isValidDate =
        DateTime.fromFormat(dateWithoutTime, "yyyy-MM-dd").isValid ||
        DateTime.fromFormat(dateWithoutTime, "yyyy/MM/dd").isValid ||
        DateTime.fromFormat(dateWithoutTime, "yyyy.MM.dd").isValid;

    if (!isValidDate) {
        return undefined;
    }

    let dateFormat = "";
    if (date.includes("-")) {
        dateFormat = "yyyy-MM-dd";
    } else if (date.includes("/")) {
        dateFormat = "yyyy/MM/dd";
    } else if (date.includes(".")) {
        dateFormat = "yyyy.MM.dd";
    }

    return DateTime.fromFormat(dateWithoutTime, dateFormat).toFormat(
        "yyyy-MM-dd"
    );
};

const parseConsumption = (consumption?: string) => {
    if (!consumption) {
        return null;
    }

    if (consumption.length) {
        return parseFloat(consumption);
    }
    return null;
};

const isEmpty = (string?: string) => {
    return string?.length === 0;
};

const getLocationId = (locations: Asset[], assetID?: string) => {
    if (!assetID) {
        return null;
    }

    const location = locations?.find(
        (location) =>
            assetID === location.externalId ||
            assetID === location.id ||
            assetID === location.name ||
            assetID === location.shortAddress
    );

    if (!location) return null;

    return { id: location.id };
};

export const getEmissionUnit = (unit: string | undefined) => {
    const lowercaseUnit = unit?.toLowerCase();

    switch (lowercaseUnit) {
        case "m3":
            return EmissionUnit.M_3;
        case "kwh":
            return EmissionUnit.KWH;
        case "gj":
            return EmissionUnit.GJ;
        case "kg":
            return EmissionUnit.KG;
        case "liter":
            return EmissionUnit.LITER;
    }
};

export const getAssessmentCategory = (consumptionType?: ConsumptionType) => {
    return match(consumptionType)
        .with(
            ConsumptionType.ENERGY_ELECTRICITY,
            () => AssessmentCategory.ELECTRICITY_REMOTE
        )
        .with(
            ConsumptionType.ENERGY_HEATING_REMOTE,
            () => AssessmentCategory.HEATING_REMOTE
        )
        .with(ConsumptionType.ENERGY, () => AssessmentCategory.ENERGY_COMBINED)
        .with(
            P.string.includes("ELECTRICITY"),
            () => AssessmentCategory.ELECTRICITY_GENERATED
        )
        .with(
            P.string.includes("HEATING"),
            () => AssessmentCategory.HEATING_GENERATED
        )
        .with(P.string.includes("WATER"), () => AssessmentCategory.WATER)
        .with(P.string.includes("WASTE"), () => AssessmentCategory.WASTE)
        .with(
            P.string.includes("COOLING"),
            () => AssessmentCategory.COOLING_REMOTE
        )
        .otherwise(() => AssessmentCategory.UNCATEGORIZED);
};

export const getConsumptionType = (type?: string): ConsumptionType => {
    switch (type) {
        case "elektricitet":
        case "electricity":
            return ConsumptionType.ENERGY_ELECTRICITY;
        case "fjernvarme":
        case "district heating":
            return ConsumptionType.ENERGY_HEATING_REMOTE;
        case "vand":
        case "water":
            return ConsumptionType.WATER;
        case "gas":
            return ConsumptionType.ENERGY_HEATING_FUELS_GAS;
        case "wood chips":
        case "træflis":
            return ConsumptionType.ENERGY_HEATING_BIO_BIOMASS_WOOD_CHIPS;
        case "naturgas":
        case "natural gas":
            return ConsumptionType.ENERGY_HEATING_FUELS_GAS_NATURALGAS;
        case "flydende naturgas":
        case "liquified natural gas":
            return ConsumptionType.ENERGY_HEATING_FUELS_GAS_LIQUIFIEDNATURALGAS;
        case "køling":
        case "district chilled water":
            return ConsumptionType.ENERGY_COOLING_REMOTE;
        case "olie":
        case "oil":
        case "lpg":
            return ConsumptionType.ENERGY_HEATING_FUELS_LIQUID_OIL;
        default:
            return ConsumptionType.UNCATEGORIZED;
    }
};
const parseAssessmentData = (
    parsedCsvRows: ParsedCsvRow,
    assessmentDataType: AssessmentDataType
) => {
    if (assessmentDataType === AssessmentDataType.ACTUAL) {
        return parsedCsvRows.MeterID
            ? { hasError: false, data: { meterId: parsedCsvRows.MeterID } }
            : null;
    } else if (assessmentDataType === AssessmentDataType.ESTIMATE) {
        return {
            hasError:
                !parsedCsvRows.EPCIdentifier ||
                !parsedCsvRows.EPCClassification,
            data:
                parsedCsvRows.EPCIdentifier && parsedCsvRows.EPCClassification
                    ? {
                          epcIdentifier: parsedCsvRows.EPCIdentifier,
                          classification: parsedCsvRows.EPCClassification,
                          pdfLink: parsedCsvRows.ConsumptionSource || "",
                          buildingNumbers: parsedCsvRows.BuildingNumbers
                              ? _.chain(parsedCsvRows.BuildingNumbers)
                                    .split(",")
                                    .map((building) => {
                                        const parsed = parseInt(building, 10);
                                        return _.isFinite(parsed)
                                            ? parsed
                                            : null;
                                    })
                                    .compact()
                                    .value()
                              : [],
                          nationalIdentifiers: parsedCsvRows.NationalIdentifiers
                              ? _.chain(parsedCsvRows.NationalIdentifiers)
                                    .split(",")
                                    .compact()
                                    .value()
                              : [],
                      }
                    : null,
        };
    }
};

export const parseResults = (
    locations: Asset[],
    parsedCsvRows: ParsedCsvRow[],
    assessmentDataType: AssessmentDataType
) => {
    const assessments: Array<CreateAssessmentInput> = [];
    const locationMetaData: Array<CreateLocationMetadataInput> = [];

    const rows: GridRowModel[] = parsedCsvRows.map((result, index) => {
        const location = getLocationId(locations, result.AssetID);
        const from = parseDate(result.StartDate);
        const to = parseDate(result.EndDate);
        const consumption = parseConsumption(result.Consumption);
        const consumptionType = getConsumptionType(
            result.ConsumptionType?.toLowerCase()
        );
        const category = getAssessmentCategory(consumptionType);
        const locationId = location?.id;
        const unit = getEmissionUnit(result.Unit);
        const isEpc = assessmentDataType === AssessmentDataType.ESTIMATE;
        const ownedBuildingConsumptionPercentage =
            isEpc && result.BuildingOwnership
                ? parseFloat(result.BuildingOwnership)
                : null;
        const dataInfo = parseAssessmentData(result, assessmentDataType);

        const isFromBeforeOrEqualTo =
            from && to && new Date(from) <= new Date(to);

        const buildingArea = result.Area
            ? parseFloat(result.Area.toString())
            : undefined;
        const address = JSON.stringify({
            zip: result.Zip,
            city: result.City,
            street: result.Address,
        });

        const buildingUse = result.BuildingType;
        const constructionDate = parseDate(result.YearOfConstruction);

        if (locationId && from && typeof consumption === "number" && category) {
            //mapping assessment for upload
            assessments.push({
                locationId,
                from,
                to,
                consumption,
                consumptionType,
                category,
                unit,
                ownedBuildingConsumptionPercentage,
                data: dataInfo?.data ? JSON.stringify(dataInfo.data) : null,
                totalBuildingSqm: buildingArea,
            });
            locationMetaData.push({
                locationId,
                externalId: result.NationalIdentifiers,
                nationalIdentifier: result.NationalIdentifiers,
                buildingArea,
                address,
                buildingUse,
                constructionDate,
            });
        }

        const errors = {
            assetID: isEmpty(result.AssetID) || !locationId,
            consumptionType: isEmpty(result.ConsumptionType),
            start: isEmpty(result.StartDate) || !from || !isFromBeforeOrEqualTo,
            end: isEmpty(result.EndDate) || !to || !isFromBeforeOrEqualTo,
            consumption: !isValidNumber(result.Consumption),
            unit: isEmpty(result.Unit) || !unit,
            data: isEpc
                ? {
                      source:
                          !result.ConsumptionSource ||
                          !isValidUrl(result.ConsumptionSource),
                      identifier:
                          !result.EPCIdentifier ||
                          isEmpty(result.EPCIdentifier),
                      classification:
                          !result.EPCClassification ||
                          isEmpty(result.EPCClassification),
                  }
                : {
                      meterId: isEmpty(result.MeterID),
                  },
            buildingOwnership:
                isEpc && !isValidNumber(result.BuildingOwnership),
            area: isEpc && !result.Area,
        };

        const hasErrors = Object.values(errors)
            .filter((error) => typeof error === "boolean")
            .some((error) => error);
        const assetDataHasErrors = Object.values(errors.data).some(
            (error) => error
        );

        return {
            ...result,
            ...locationMetaData,
            id: index,
            errors,
            hasErrors: hasErrors || assetDataHasErrors,
        };
    });

    return { rows, assessments, locationMetaData };
};
