import { Circle } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { LineIcon } from "../LineIcon";

export const ChartItemLegend = () => {
    const { t } = useTranslation();
    const itemGap = 2;
    return (
        <Stack
            direction="row"
            gap={3}
            justifyContent="flex-end"
            sx={{ color: "gray", paddingX: 13 }}
        >
            <Stack direction="row" gap={itemGap} alignItems="center">
                <LineIcon dashed />
                {t("common.labels.projectedIntensity", "Projected Intensity")}
            </Stack>
            <Stack direction="row" gap={itemGap} alignItems="center">
                <LineIcon />
                {t("common.labels.intensity", "Intensity")}
            </Stack>
            <Stack direction="row" gap={itemGap} alignItems="center">
                <Circle
                    sx={{
                        fontSize: 10,
                        strokeWidth: 5,
                        stroke: "red",
                        fill: "none",
                    }}
                />
                {t("assetDetailsComponent.stradedAsset", "Stranded Asset")}
            </Stack>
        </Stack>
    );
};
