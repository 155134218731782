import {
    styled,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Card,
} from "@mui/material";
import React from "react";

type Props = {
    headerRow: string[];
    rows: React.ReactNode[][];
};

const Cell = styled(TableCell)(({ theme }) => ({
    textAlign: "right",
    border: "none",
    padding: theme.spacing(1),

    "&:first-of-type": {
        paddingLeft: "0",
        textAlign: "left",
    },

    "&:last-of-type": {
        paddingRight: "0",
    },
}));

const TableBrick = (props: Props) => {
    const { headerRow, rows } = props;
    return (
        <Card sx={{ p: 4 }}>
            <Table
                component={(props: any) => (
                    <Card {...props} style={{ overflowX: "auto" }} />
                )}
            >
                <TableHead>
                    <TableRow>
                        {headerRow.map((header, i) => (
                            <Cell
                                component="th"
                                sx={{ color: "grey.500" }}
                                key={i}
                            >
                                {header}
                            </Cell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <TableRow key={i}>
                            {row.map((cell, j) => (
                                <Cell key={`${i}-${j}`}>{cell}</Cell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default TableBrick;
