import { isEmpty } from "lodash";

import apolloClient from "./apolloClient";

const ACCESS_TOKEN_KEY = "accessToken";
const USER_INFO_KEY = "userInfo";
const REFRESH_TOKEN_KEY = "refreshToken";

type UserInfo = {
    id: string;
    name: string;
    email: string;
    organizationName: string;
    organizationId: string;
};

const auth = {
    logOut() {
        apolloClient.clearStore();
        if (localStorage) {
            const myLanguageSetting = localStorage.getItem("i18nextLng");
            localStorage.clear();
            if (myLanguageSetting) {
                localStorage.setItem("i18nextLng", myLanguageSetting);
            }
        }

        if (sessionStorage) {
            sessionStorage.clear();
        }
    },

    get(key: string) {
        const value =
            localStorage?.getItem(key) ?? sessionStorage?.getItem(key);
        return value ? JSON.parse(value) : null;
    },

    set(value: string, key: string) {
        if (isEmpty(value)) return null;
        const stringValue = JSON.stringify(value);
        if (localStorage) localStorage.setItem(key, stringValue);
        if (sessionStorage) sessionStorage.setItem(key, stringValue);
    },

    getAccessToken() {
        return auth.get(ACCESS_TOKEN_KEY);
    },

    getRefreshToken() {
        return auth.get(REFRESH_TOKEN_KEY);
    },

    getUserInfo(): UserInfo | null {
        return auth.get(USER_INFO_KEY);
    },

    getEndpointUrl() {
        return process.env.REACT_APP_API_URL;
    },

    setUserInfo(user: string) {
        return auth.set(user, USER_INFO_KEY);
    },

    setAccessToken(token: string) {
        return auth.set(token, ACCESS_TOKEN_KEY);
    },

    setRefreshToken(token: string) {
        return auth.set(token, REFRESH_TOKEN_KEY);
    },
};

export default auth;
