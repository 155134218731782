import { gql } from "@apollo/client";

export const SEARCH_QUERY = gql`
    query GetLegacyNextAssets($searchFilters: SearchFiltersInput!) {
        getLegacyNextAssets(searchFilters: $searchFilters) {
            id
            objectType
            longitude
            latitude
            area
            assetExternalId
            fullAddress
        }
    }
`;
