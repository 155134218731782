import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import RadioDropdown, {
    DropDownPerformanceType,
} from "components/AssetList/components/Filters/RadioDropdown";
import SearchFilter from "components/AssetList/components/Filters/SearchFilter";
import YearAndAssessmentTypeFilter from "components/YearAndAssessmentTypeFilter/YearAndAssessmentTypeFilter";
import { AssessmentDataType } from "graphql-types/graphql";
import { useBrowserStorage } from "hooks";

import { QueryFilter } from "../../helpers";
import { S } from "../components.styles";

type Props = {
    queryFilter: QueryFilter;
    onFilterChange: (key: keyof QueryFilter, value: any) => void;
};

export const Filters = (props: Props) => {
    const { queryFilter, onFilterChange } = props;

    const [inputString, setInputString] = useState(queryFilter.inputString);

    const [, setSearchParams] = useSearchParams();
    const [, setStorageFilter] = useBrowserStorage("assetListFilter", {});

    const onFilterChangeHandler = <K extends keyof QueryFilter>(
        key: K,
        value: QueryFilter[K]
    ) => {
        if (key === "inputString") setInputString(value);

        setSearchParams((prevState) =>
            !value || value === ""
                ? (prevState.delete(key), prevState)
                : (prevState.set(key, JSON.stringify(value)), prevState)
        );

        setStorageFilter((prev) => ({ ...prev, [key]: value }));
        onFilterChange(key, value);
    };

    return (
        <S.Container>
            <SearchFilter
                inputValue={inputString || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onFilterChangeHandler("inputString", e.target.value)
                }
            />

            <RadioDropdown
                selectedValue={queryFilter.performance}
                onChange={(value: DropDownPerformanceType) =>
                    onFilterChangeHandler("performance", value)
                }
            />

            <YearAndAssessmentTypeFilter
                activeType={queryFilter.dataType}
                onAssessmentTypeChange={(value: AssessmentDataType) =>
                    onFilterChangeHandler("dataType", value)
                }
            />
        </S.Container>
    );
};
