import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
    Button,
    CircularProgress,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SearchAssetView, SearchTerms } from "legacyNext/types";

import { EPCFilter } from "./EPCFilter";
import { S } from "./search.styles";
import { SearchView } from "./SearchView";

type Props = {
    loading: boolean;
    onSearchTermChange: (searchTerms: SearchTerms) => void;
    onSearchViewChange: (view: SearchAssetView) => void;
};

export const Search = (props: Props) => {
    const { loading = false, onSearchViewChange, onSearchTermChange } = props;

    const [searchBFE, setSearchBFE] = useState("");

    const { t } = useTranslation();

    function onSearch() {
        onSearchTermChange({
            bfeList: searchBFE.split(","),
            epcClassificationList: [],
        });
    }

    return (
        <S.Search>
            <Typography variant="h4">
                {t("legacyNext.searchTitle", "Find properties or portfolios")}
            </Typography>
            <S.Inner>
                <S.SearchField>
                    <TextField
                        value={searchBFE}
                        onChange={(e) => setSearchBFE(e.target.value)}
                        variant="outlined"
                        placeholder={t(
                            "legacyNext.searchPlaceholder",
                            "search for bfe"
                        )}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </S.SearchField>
                <EPCFilter />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSearch}
                    disabled={searchBFE === ""}
                >
                    {loading ? (
                        <CircularProgress size={24} color="secondary" />
                    ) : (
                        t("legacyNext.searchButton", "Search")
                    )}
                </Button>
            </S.Inner>
            <SearchView onSearchViewChange={onSearchViewChange} />
        </S.Search>
    );
};
