import { Box, styled } from "@mui/material";
import { useMemo } from "react";

import { useAssessmentDataTypeContext } from "containers/AssetPage/AssessmentDataTypeContext";

import { useSelectedYearContext } from "../../YearSelector/YearContext";
import { useGetMonthlyEmissionSummaries } from "../Charts/charts.helper";
import { MonthlyDashboardCharts } from "../Charts/MonthlyDashboardCharts";
import {
    getSumOrganizationEmissionSummariesKey,
    getTotalArea,
} from "../helpers/utilities";
import { Sidebar } from "../Sidebar";

const MonthlyDashboardContainer = styled(Box)(() => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 75%",
    gap: 12,
    alignItems: "start",
}));

export const MonthlyDashboard = () => {
    const { assessmentType } = useAssessmentDataTypeContext();
    const { activeYear } = useSelectedYearContext();

    const {
        assets,
        orgEmissionSummaries,
        orgProjectedEmissionSummaries,
        loading,
    } = useGetMonthlyEmissionSummaries(activeYear, assessmentType);

    const sidebarData = useMemo(() => {
        const assetsWithDataInCurrentYear = assets.filter(
            (asset) => asset.hasData
        );

        return {
            assetCount: assetsWithDataInCurrentYear.length,
            totalArea: getTotalArea(assetsWithDataInCurrentYear),
            ownedEmission: getSumOrganizationEmissionSummariesKey(
                orgEmissionSummaries,
                "ownedEmission"
            ),
            ownedEnergy: getSumOrganizationEmissionSummariesKey(
                orgEmissionSummaries,
                "ownedEnergy"
            ),
        };
    }, [assets, orgEmissionSummaries]);

    return (
        <MonthlyDashboardContainer>
            <Sidebar dataType={assessmentType} data={sidebarData} />

            <MonthlyDashboardCharts
                activeTab={assessmentType}
                summaries={orgEmissionSummaries}
                projected={orgProjectedEmissionSummaries}
                isLoading={loading}
            />
        </MonthlyDashboardContainer>
    );
};
