import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Box } from "@mui/material";
import {
    GridColDef,
    GridColumnHeaderParams,
    GridCellParams,
    GridRenderCellParams,
} from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { match, P } from "ts-pattern";

import EpcClassificationsCell from "components/Cells/EpcClassificationsCell";
import { VerticalCoverageCell } from "components/Cells/VerticalCoverageCell";
import { mapDataQualitySources } from "components/DataQuality/DataQuality.helpers";
import DataQualityDistributionBar from "components/DataQuality/DataQualityDistributionBar";
import DataQualityTooltip from "components/DataQuality/DataQualityTooltip";
import { TableHeader } from "components/Table";
import { DASH_SEPARATOR } from "utils";
import { assessmentVerticalToTitle } from "utils/assessment";

import {
    formatDataQualityColumn,
    formatPercentage,
} from "./asset-list.formatter";
import { BenchmarkPerformanceHeader } from "../components";

const renderHeader = (
    params: GridColumnHeaderParams,
    subHeader?: string,
    infoText?: string
) =>
    TableHeader({
        params,
        subHeaderText: subHeader,
        infoText,
    });

const dataQualityCol = (t: TFunction): GridColDef => {
    const headerName = t("common.labels.dataQuality", "Data Quality");
    const header = (params: GridColumnHeaderParams) =>
        renderHeader(
            params,
            "",
            t(
                "portfolioDashboard.locationTable.headerInfoBox.dataQuality",
                "The quality distribution of consumption data per asset."
            )
        );

    const renderCell = (params: GridRenderCellParams) => {
        if (params.value) {
            return (
                <DataQualityTooltip dataQuality={params.value}>
                    <DataQualityDistributionBar {...params.value} />
                </DataQualityTooltip>
            );
        }

        return t(
            "portfolioDashboard.locationTable.dataQuality.noData",
            "No data available"
        );
    };

    return {
        field: "dataQuality",
        headerName,
        renderHeader: header,
        renderCell,
        valueFormatter: (value) => formatDataQualityColumn(value, t),
        flex: 1.5,
    };
};

const verticalCoverageCol = (t: TFunction): GridColDef => {
    const headerName = t(
        "portfolioDashboard.locationTable.verticalCoverage",
        "Verticals"
    );

    const header = (params: GridColumnHeaderParams) =>
        renderHeader(
            params,
            t(
                "portfolioDashboard.locationTable.subheader.verticalCoverage",
                "Electricity, Heating, Water"
            ),
            t(
                "portfolioDashboard.locationTable.headerInfoBox.verticalCoverage",
                "This column gives you a glanceable overview if consumption data is present for electricity, heating and water."
            )
        );

    const valueFormatter = (value: any) => {
        if (!value) return DASH_SEPARATOR;

        if (value instanceof Array) {
            return value
                .map(
                    (v) =>
                        `${assessmentVerticalToTitle(
                            v.vertical,
                            t
                        )}: ${v.sources
                            .map((s: any) => mapDataQualitySources(s, t))
                            .join(", ")}`
                )
                .join(" / ");
        }

        return "";
    };

    return {
        field: "verticals",
        headerName,
        renderHeader: header,
        renderCell: (params: GridCellParams) =>
            VerticalCoverageCell({ verticals: params.value as any }),
        valueFormatter: valueFormatter,
        flex: 1,
    };
};

const performanceCol = (t: TFunction): GridColDef => {
    const renderCell = (params: GridCellParams) => {
        const { value } = params;
        const num = Number(value);

        return match(value)
            .with(0, (value) => `${value}%`)
            .with(P.number, (n) => (
                <Box display="flex" alignItems="center">
                    {formatPercentage(num * 100) > 0 ? (
                        <ArrowUpward color="error" fontSize="small" />
                    ) : (
                        <ArrowDownward color="success" fontSize="small" />
                    )}
                    {Math.abs(formatPercentage(n * 100))}%
                </Box>
            ))
            .otherwise(() => DASH_SEPARATOR);
    };

    return {
        field: "performance",
        headerName: t(
            "portfolioDashboard.locationTable.performance",
            "Performance"
        ),
        valueFormatter: formatPercentage,
        renderHeader: BenchmarkPerformanceHeader,
        renderCell,
        sortable: true,
        flex: 1,
    };
};

export const getColumns = (t: TFunction): GridColDef[] => [
    {
        flex: 2,
        field: "displayName",
        headerName: t("common.name", "Name"),
        renderHeader: (params: any) =>
            renderHeader(
                params,
                t(
                    "portfolioDashboard.locationTable.subheader.names",
                    "Address or Name of building"
                )
            ),
        sortable: true,
    },
    {
        field: "externalId",
        headerName: t("portfolioDashboard.locationTable.Id", "ID"),
        renderHeader: renderHeader,
        sortable: true,
        flex: 0.8,
    },
    {
        field: "ownedArea",
        headerName: t("portfolioDashboard.locationTable.area", "Area"),
        renderHeader: (params) =>
            renderHeader(params, t("common.units.area.m2", "m²")),
        valueFormatter: (value: any) =>
            value
                ? value.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                  })
                : DASH_SEPARATOR,
        flex: 0.9,
    },
    {
        field: "totalEmission",
        headerName: t(
            "portfolioDashboard.locationTable.totalEmission",
            "Total Emission(s)"
        ),
        renderHeader: (params) =>
            renderHeader(
                params,
                t(
                    "portfolioDashboard.locationTable.subheader.emission",
                    "kg/CO2e"
                )
            ),
        sortable: true,
        flex: 1,
    },
    dataQualityCol(t),
    {
        field: "emissionPerArea",
        headerName: `${t(
            "portfolioDashboard.locationTable.emissionPerSqm",
            "Emission/m²"
        )}`,
        sortable: true,
        renderHeader: (params) =>
            renderHeader(
                params,
                t(
                    "portfolioDashboard.locationTable.subheader.emissionPerSize",
                    "kg/CO2e/m²"
                )
            ),
        flex: 1,
    },
    performanceCol(t),
    verticalCoverageCol(t),
    {
        field: "classification",
        flex: 1.5,
        sortable: false,
        headerName: t(
            "portfolioDashboard.locationTable.classifications",
            "Classifications"
        ),
        valueFormatter: (value: any) =>
            value instanceof Array ? value.join(", ") : "",
        renderCell: (params) =>
            EpcClassificationsCell(params.value as string[]),
    },
];
