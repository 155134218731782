import { cloneElement, isValidElement, PropsWithChildren, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import Logo from "assets/legacyLogoPaleBlueIconWithName.png";
import { ChartType } from "components/PortfolioDashboard/types";
import { ChartDisplayType } from "glue/Chart/chart.constants";

import { S } from "./chart-container.style";
import { ChartPrintHeader } from "./ChartPrintHeader";
import { ChartHeader } from "../ChartHeader";

type Props = {
    chart: ChartType;
    type: ChartDisplayType;
};

export const ChartContainer = (props: PropsWithChildren<Props>) => {
    const { children, chart, type } = props;

    const contentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        contentRef,
    });

    const listChildren = Array.isArray(children) ? children : [children];
    const header = listChildren.find(
        (child) => isValidElement(child) && child.type === ChartHeader
    );
    const content = listChildren.filter(
        (child) => isValidElement(child) && child.type !== ChartHeader
    );

    const url = "www.legacyCO2.com";

    return (
        <S.Card>
            {cloneElement(header as React.ReactElement, {
                onPrint: handlePrint,
            })}
            <S.Container ref={contentRef}>
                <ChartPrintHeader chart={chart} type={type} />

                {content}

                <S.PrintContainer className="footer">
                    <S.FooterLogo src={Logo} alt="Logo" />
                    <S.Footer>
                        {url.toUpperCase()}
                        <br />
                        {`Generated on ${new Date().toLocaleDateString(
                            "en-GB",
                            {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                            }
                        )}`}
                    </S.Footer>
                </S.PrintContainer>
            </S.Container>
        </S.Card>
    );
};
