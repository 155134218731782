import { TFunction } from "i18next";

import { ChartType } from "../types";

export const getPortfolioOptions = (
    isYearlyBreakdown: boolean,
    t: TFunction
): {
    value: ChartType;
    title: string;
    unit?: string;
}[] => [
    {
        value: ChartType.PORTFOLIO_INTENSITY,
        title: t(
            "portfolioDashboard.portfolioSelector.portIntensity",
            "potfolio intensity"
        ),
    },
    {
        value: ChartType.WEIGHTED_AVERAGE,
        title: t(
            "portfolioDashboard.portfolioSelector.avgEmission",
            "building average"
        ),
    },
    {
        value: isYearlyBreakdown
            ? ChartType.MONTH_BY_MONTH
            : ChartType.TOTAL_EMISSION,
        title: t(
            "portfolioDashboard.portfolioSelector.totalFootprint",
            "total footprint"
        ),
    },
    ...(isYearlyBreakdown
        ? [
              {
                  value: ChartType.PORTFOLIO_RISK_PROFILE,
                  title: t(
                      "portfolioDashboard.portfolioSelector.portfolioRiskProfile",
                      "portfolio risk profile"
                  ),
              },
          ]
        : []),
    {
        value: isYearlyBreakdown
            ? ChartType.DATA_QUALITY_MONTH_BY_MONTH
            : ChartType.DATA_QUALITY,
        title: t(
            "portfolioDashboard.portfolioSelector.dataQuality",
            "data quality"
        ),
    },
];
