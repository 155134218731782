import { PaletteColor, Theme } from "@mui/material";
import { BarSeriesType } from "@mui/x-charts";
import { TFunction } from "i18next";
import { chain, round, uniq } from "lodash";

import {
    getDistributionPalette,
    mapDataQualitySources,
    sumDataQualities,
} from "components/DataQuality/DataQuality.helpers";
import { DataQuality, DataQualityDistribution } from "graphql-types/graphql";
import { percentageCalc } from "utils/maths";

import {
    DataQualityGradient,
    DataQualitySeriesData,
    QualityLevel,
} from "./dataQualityChart.types";

const QUALITY_ORDER: QualityLevel[] = ["high", "medium", "low"];
const getGradientKey = (priority: number, quality: QualityLevel) =>
    `${priority}_${quality}`;

const getDataQualityGradient = (
    distribution: DataQualityDistribution[],
    palette: PaletteColor,
    total: number,
    quality: QualityLevel
): DataQualityGradient =>
    [...distribution]
        .sort((a, b) => a.priorityDistribution - b.priorityDistribution)
        .reduce((acc, { priorityDistribution, sources, assessmentDays }) => {
            acc[getGradientKey(priorityDistribution, quality)] = {
                sources: sources.map((source) =>
                    quality === "high" &&
                    priorityDistribution === 66 &&
                    source === "ENTOLABS"
                        ? "ENTOLABS_E"
                        : source
                ),
                percentage: round(percentageCalc(assessmentDays, total), 2),
                color: getDistributionPalette(priorityDistribution, palette),
                quality,
            };
            return acc;
        }, {} as DataQualityGradient);

const getQualityGradientsPriority = (
    dataQuality: DataQuality,
    theme: Theme
) => {
    const { highQuality, mediumQuality, lowQuality } = dataQuality;

    const { sumHighQuality, sumMediumQuality, sumLowQuality } =
        sumDataQualities(dataQuality);

    const total = sumHighQuality + sumMediumQuality + sumLowQuality;

    return {
        ...getDataQualityGradient(
            lowQuality,
            theme.palette.error,
            total,
            "low"
        ),
        ...getDataQualityGradient(
            mediumQuality,
            theme.palette.warning,
            total,
            "medium"
        ),
        ...getDataQualityGradient(
            highQuality,
            theme.palette.success,
            total,
            "high"
        ),
    };
};

const withTag = (arr: DataQuality["highQuality"], quality: QualityLevel) =>
    arr.map(({ priorityDistribution }) =>
        getGradientKey(priorityDistribution, quality)
    );

const extractPriorityKeys = (qualities: DataQuality[]): string[] =>
    uniq(
        qualities.flatMap(({ highQuality, mediumQuality, lowQuality }) => [
            ...withTag(highQuality, "high"),
            ...withTag(mediumQuality, "medium"),
            ...withTag(lowQuality, "low"),
        ])
    ).sort((a, b) => {
        const [aDis, aLevel] = a.split("_");
        const [bDis, bLevel] = b.split("_");

        if (aLevel === bLevel) return +aDis - +bDis;
        return (
            QUALITY_ORDER.indexOf(aLevel as QualityLevel) -
            QUALITY_ORDER.indexOf(bLevel as QualityLevel)
        );
    });

export const getDataQualityChartData = (
    qualities: DataQuality[],
    theme: Theme,
    t: TFunction
): BarSeriesType[] => {
    const keys = extractPriorityKeys(qualities);
    const series: DataQualitySeriesData = Object.fromEntries(
        keys.map((key) => [
            key,
            { sources: [], data: [], color: "", quality: "" },
        ])
    );

    chain(qualities)
        .sortBy("from")
        .forEach((dq) => {
            const gradients = getQualityGradientsPriority(dq, theme);

            keys.forEach((key) => {
                const entry = series[key];
                const grad = gradients[key];

                if (grad) {
                    entry.sources.push(...grad.sources);
                    entry.color = grad.color;
                    entry.quality = grad.quality;
                    entry.data.push(grad.percentage);
                } else {
                    entry.data.push(null);
                }
            });
        })
        .value();

    return Object.values(series).map(({ data, sources, color, quality }) => ({
        type: "bar",
        data,
        label: uniq(sources)
            .map((s) => mapDataQualitySources(s, t))
            .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()))
            .join(" / "),
        stack: "dataQuality",
        stackOrder: "reverse",
        color,
        quality,
        valueFormatter: (val: number | null) => (val == null ? "" : `${val}%`),
    }));
};
