import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { InsightsProperty } from "graphql-types/graphql";

import { ExportCSVButton } from "../ExportCSVButton";

type Props = {
    assetList: InsightsProperty[];
};

export const SearchList = (props: Props) => {
    const { assetList } = props;

    const { t } = useTranslation();
    const hasAssets = assetList.length > 0;

    return (
        <Box display="flex" flexDirection="column" gap={5}>
            <Box display={"flex"} justifyContent={"flex-end"}>
                <ExportCSVButton assetList={assetList} />
            </Box>
            <TableContainer component={Paper}>
                {hasAssets ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>
                                    {t("legacyNext.address", "address")}
                                </TableCell>
                                <TableCell>
                                    {t(
                                        "legacyNext.buildingType",
                                        "buildingType"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {t("legacyNext.sqm", "sqm", {
                                        value: t("legacyNext.area", "area"),
                                    })}
                                </TableCell>
                                <TableCell>BFE</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetList.map((asset) => (
                                <TableRow key={asset.id}>
                                    <TableCell>{asset.id}</TableCell>
                                    <TableCell>{asset.fullAddress}</TableCell>
                                    <TableCell>{asset.objectType}</TableCell>
                                    <TableCell>{asset.area}</TableCell>
                                    <TableCell>
                                        {asset.assetExternalId}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Typography
                        variant="h6"
                        align="center"
                        style={{ padding: "20px" }}
                    >
                        {t("legacyNext.noAssets", "no assets")}
                    </Typography>
                )}
            </TableContainer>
        </Box>
    );
};
