import { DateTime } from "luxon";

import { ChartDisplayType } from "glue/Chart/chart.constants";

import { EmissionByMonth, Summary } from "../types/monthlyChart.types";

export const getAvgChartData = (
    summaries: Summary[],
    type: ChartDisplayType
): EmissionByMonth[] => {
    return summaries.map((summary) => {
        const breakdownKey =
            type === ChartDisplayType.EMISSIONS
                ? "avgEmissionIntensityBreakdown"
                : "avgEnergyIntensityBreakdown";
        const totalKey =
            type === ChartDisplayType.EMISSIONS
                ? "avgEmissionIntensity"
                : "avgEnergyIntensity";

        return {
            month: DateTime.fromISO(summary.from).month,
            breakdown: summary[breakdownKey],
            value: summary[totalKey] || 0,
        };
    });
};
