import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import Loading from "components/Loading/Loading";
import { useAssessmentDataTypeContext } from "containers/AssetPage/AssessmentDataTypeContext";
import { ChartDisplayType } from "glue/Chart/chart.constants";

import { ChartContainer } from "../Charts/ChartContainer";
import { ChartHeader } from "../Charts/ChartHeader";
import { DataQualityChart } from "../Charts/DataQualityChart";
import { YearlyBuildingAverageChart } from "../Charts/YearlyBuildingAverageChart";
import { YearlyIntensityChart } from "../Charts/YearlyIntensityChart";
import { YearlyTotalChart } from "../Charts/YearlyTotalChart";
import { useBenchmarks } from "../helpers/useBenchmarks";
import { DownloadProvider } from "../helpers/useDownloadContext";
import { useYearSummaries } from "../helpers/useYearSummaries";
import { ChartType } from "../types";

export const YearlyDashboard = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const { assessmentType } = useAssessmentDataTypeContext();
    const activeChartParams = searchParams.get("element");

    const [type, setType] = useState<ChartDisplayType>(
        ChartDisplayType.EMISSIONS
    );

    const [activeChart, setActiveChart] = useState(
        activeChartParams ? parseInt(activeChartParams, 10) : 0
    );

    const { activeMarkers, onToggleMarkerVisibility } = useBenchmarks(
        assessmentType,
        type
    );

    const { yearSummaries, loading } = useYearSummaries(
        assessmentType,
        activeChart,
        type
    );

    if (loading) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <DownloadProvider>
            <ChartContainer chart={activeChart} type={type}>
                <ChartHeader
                    isYearlyBreakdown={false}
                    benchmarkState={[activeMarkers, onToggleMarkerVisibility]}
                    chartState={[activeChart, setActiveChart]}
                    typeState={[type, setType]}
                />

                {activeChart === ChartType.PORTFOLIO_INTENSITY && (
                    <YearlyIntensityChart
                        yearSummaries={yearSummaries}
                        benchmarkMarkers={activeMarkers}
                        type={type}
                    />
                )}
                {activeChart === ChartType.TOTAL_EMISSION && (
                    <YearlyTotalChart summaries={yearSummaries} type={type} />
                )}
                {activeChart === ChartType.WEIGHTED_AVERAGE && (
                    <YearlyBuildingAverageChart
                        yearSummaries={yearSummaries}
                        type={type}
                    />
                )}
                {activeChart === ChartType.DATA_QUALITY && (
                    <DataQualityChart activeTab={assessmentType} />
                )}
            </ChartContainer>
        </DownloadProvider>
    );
};
