import { Box, Paper } from "@mui/material";
import { range, compact, max, min } from "lodash";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Chart } from "glue/Chart";
import { AssessmentDataType } from "graphql-types/graphql";

import { ForecastAssetSearch } from "./ForecastAssetSearch";
import { ForecastChartTooltip } from "./ForecastChartTooltip";
import ForecastDashboardHeader from "./ForecastDashboardHeader";
import { ChartDisclaimer } from "./ForecastDisclaimer";
import { getTargetSeries } from "./helpers/chartHelpers";
import TargetEmissionsLabels from "./TargetEmissionLabels";
import { useForecastQuery } from "./useForecastQuery";
import Loading from "../Loading/Loading";
import { TargetSettingProvider } from "../TargetSettings/useTargetSettings";

const ForecastDashboard = () => {
    const { t } = useTranslation(["translation", "report"]);
    const [activeTab, setActiveTab] = useState(AssessmentDataType.COMBINED);
    const [filteringId, setFilteringId] = useState("");

    const { data, loading } = useForecastQuery(activeTab);

    const { series, xAxis } = useMemo(() => {
        if (!data) {
            return { series: [], xAxis: {} };
        }

        const { baselineYear, targetSettings } = data;

        const assets = filteringId
            ? [data.assets.find((asset) => asset.id === filteringId)]
            : data.assets;

        const initialYear = filteringId
            ? max([
                  min(
                      assets.flatMap((a) =>
                          a?.emissionSummaries.flatMap(
                              (e) => DateTime.fromISO(e.from).year
                          )
                      )
                  ),
                  baselineYear,
              ])
            : baselineYear;

        const years = range(
            initialYear ?? 2018,
            (targetSettings?.targetYear ?? 2050) + 1
        );

        const series = getTargetSeries(t, years, activeTab, compact(assets));

        const xAxis: any = {
            tickLabelInterval: (t: number) => t % 5 === 0,
            scaleType: "band",
            data: years,
        };

        return { series, xAxis };
    }, [data, activeTab, filteringId, t]);

    if (loading || !data) {
        return <Loading description={t("loading.title", "Loading")} />;
    }

    return (
        <TargetSettingProvider
            value={{
                pathways: data.pathways,
                targetSettings: data.targetSettings,
            }}
        >
            <Box display="grid" gap={4}>
                <ForecastDashboardHeader
                    activeTab={activeTab}
                    baselineYear={data.baselineYear}
                    handleChange={setActiveTab}
                />

                {data.baselineYear && (
                    <TargetEmissionsLabels baselineYear={data.baselineYear} />
                )}

                <Paper style={{ padding: 32 }}>
                    <ForecastAssetSearch
                        assets={data.assets}
                        onAssetSelected={(l) => setFilteringId(l?.id || "")}
                    />
                    <Chart
                        series={series}
                        xAxis={[xAxis]}
                        customTooltip={ForecastChartTooltip}
                        legend={{
                            hidden: false,
                            position: { horizontal: "right" },
                        }}
                    />
                </Paper>

                <ChartDisclaimer />
            </Box>
        </TargetSettingProvider>
    );
};

export default ForecastDashboard;
