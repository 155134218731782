import { Download as DownloadIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { InsightsProperty } from "graphql-types/graphql";

type Props = {
    assetList: InsightsProperty[];
};

export const ExportCSVButton = (props: Props) => {
    const { assetList } = props;

    const { t } = useTranslation();

    const hasAssets = assetList.length > 0;

    function exportToCSV() {
        const headers = [
            "ID",
            t("legacyNext.address", "address"),
            t("legacyNext.buildingType", "buildingType"),
            t("legacyNext.sqm", "sqm", {
                value: t("legacyNext.area", "area"),
            }),
            "BFE",
        ];

        const rows = assetList.map((asset) => [
            asset.id,
            asset.fullAddress,
            asset.objectType,
            asset.area,
            asset.assetExternalId,
        ]);

        // Function to escape and quote values containing commas or quotes
        const escapeCsvValue = (value: unknown) => {
            if (typeof value === "string") {
                // Enclose values with commas or quotes in double quotes
                if (value.includes(",") || value.includes('"')) {
                    return `"${value.replace(/"/g, '""')}"`; // Escape internal double quotes
                }
            }
            return value;
        };

        const csvContent = [
            headers.map(escapeCsvValue), // Escape headers if necessary
            ...rows.map((row) => row.map(escapeCsvValue)), // Escape row values if necessary
        ]
            .map((e) => e.join(","))
            .join("\n");

        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "assets.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <Button
            disabled={!hasAssets}
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={exportToCSV}
        >
            {t("legacyNext.downloadCSV", "download csv")}
        </Button>
    );
};
