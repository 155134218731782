import { TFunction } from "i18next";
import _ from "lodash";

import { ChartDisplayType } from "glue/Chart/chart.constants";
import { formatNumberLocale } from "utils/report.helpers";
import { DASH_SEPARATOR } from "utils/strings.helpers";

import { UnitMass } from "../../AssessmentStats/types";
import { ChartType } from "../types";

export const BENCHMARK_LABEL = "Benchmark";

export const emissionUnit = UnitMass.TON;
const emissionIsTon = emissionUnit.valueOf() === UnitMass.TON.valueOf();
export const emissionLabel = emissionIsTon ? "t" : "kg";

export const roundTo = function (number: number, interval: number) {
    return _.round(number / interval) * interval;
};

export function formatSummedEmission(
    sumEmission: number | undefined,
    t: TFunction
) {
    if (!sumEmission) return DASH_SEPARATOR;

    const formattedSumEmission = `${formatNumberLocale(sumEmission, t)} ${t(
        "portfolioDashboard.portfolioSelector.kgsAvg",
        "kg CO2e/m²"
    )}`;

    return formattedSumEmission;
}

export const getChartTypeUnit = (
    chartType: ChartType,
    type: ChartDisplayType
) => {
    if (
        chartType === ChartType.TOTAL_EMISSION &&
        type === ChartDisplayType.EMISSIONS
    ) {
        return "tons CO2e";
    }

    const unit = type === ChartDisplayType.EMISSIONS ? "kg CO2e" : "kWh";

    if (
        chartType === ChartType.TOTAL_EMISSION ||
        chartType === ChartType.MONTH_BY_MONTH
    ) {
        return unit;
    }

    return unit + " / m²";
};
