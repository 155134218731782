export const LineIcon = ({
    dashed = false,
    color = "gray",
}: {
    dashed?: boolean;
    color?: string;
}) => {
    return (
        <svg
            width="18"
            height="2"
            viewBox="0 0 18 2"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="1"
                y1="1"
                x2="18"
                y2="1"
                stroke={color}
                fill={color}
                strokeWidth="2"
                strokeDasharray={dashed ? "4,2" : "0"}
            />
        </svg>
    );
};
