import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DataSourceStatus } from "components/Cells/DataStatusCell";
import { LocationOverviewRow } from "containers/AssetPage/AssetDetails/AssetDetails.helpers";

import { getScoreDistribution } from "./data-overview.helper";
import NumberTextBrick from "../../components/Brick/NumberTextBrick";
import TableBrick from "../../components/Brick/TableBrick";
import {
    formatNumberLocale,
    formatPercentageLocale,
} from "../../utils/report.helpers";

type Props = {
    sources: LocationOverviewRow[];
    assetCoverage: { count: number; total: number };
};

export const EPCSidebar = (props: Props) => {
    const { sources, assetCoverage } = props;

    const { t } = useTranslation();

    const [activeEPCs, expiredEPCs] = useMemo(() => {
        const allActiveEPCs = sources.filter(
            (epc) => epc.status !== DataSourceStatus.EXPIRED
        );
        const totalActiveEPCs = allActiveEPCs.length;

        const totalArea = sources.reduce(
            (acc, location) => acc + location.area,
            0
        );
        const totalActiveArea = allActiveEPCs.reduce(
            (acc, location) => acc + location.area,
            0
        );

        const activeEPCs = {
            count: formatNumberLocale(totalActiveEPCs, t, { max: 0 }),
            percentage: `(${formatPercentageLocale(
                totalActiveEPCs / sources.length,
                t
            )})`,
            totalArea,
            totalActiveArea,
            sources: sources.filter(
                (epc) => epc.status !== DataSourceStatus.EXPIRED
            ),
        };
        const totalExpiredEPCs = sources.filter(
            (epc) => epc.status === DataSourceStatus.EXPIRED
        ).length;
        const expiredEPCs = {
            count: formatNumberLocale(totalExpiredEPCs, t, { max: 0 }),
            percentage: `(${formatPercentageLocale(
                totalExpiredEPCs / sources.length,
                t
            )})`,
        };

        return [activeEPCs, expiredEPCs] as const;
    }, [sources, t]);

    const headerRow = [
        t("epcDashboard.activeEPCs", "Active EPCs"),
        "#",
        "% of Area",
    ];

    const epcScores = getScoreDistribution(
        activeEPCs.sources,
        activeEPCs.totalArea,
        t
    );

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <NumberTextBrick
                label={t("dataLocationCategory.active", "Active")}
                value={activeEPCs.count}
                secondaryLabel={activeEPCs.percentage}
            />
            <NumberTextBrick
                label={t("dataLocationCategory.expired", "Expired")}
                value={expiredEPCs.count}
                secondaryLabel={expiredEPCs.percentage}
            />
            <NumberTextBrick
                label={t("epcDashboard.assetCoverage", "Asset Coverage")}
                value={formatNumberLocale(assetCoverage.count, t, { max: 0 })}
                secondaryLabel={`/${formatNumberLocale(assetCoverage.total, t, {
                    max: 0,
                })}`}
            />
            <NumberTextBrick
                label={t("epcDashboard.totalActiveArea", "Total Active Area")}
                value={`${formatNumberLocale(activeEPCs.totalActiveArea, t, {
                    max: 0,
                })}`}
                secondaryLabel={`m² (${formatNumberLocale(
                    (activeEPCs.totalActiveArea / activeEPCs.totalArea) * 100,
                    t,
                    { max: 0 }
                )}%)`}
            />
            <TableBrick headerRow={headerRow} rows={epcScores} />
        </Box>
    );
};
