import { Maybe } from "graphql/jsutils/Maybe";
import { TFunction } from "i18next";
import _, { upperCase } from "lodash";

import {
    AssessmentDataType,
    AssessmentOrigin,
    IntegrationEpcAssessmentData,
    IntegrationEpcUkAssessmentData,
} from "graphql-types/graphql";

export function isManualMeterData<
    T extends { origin?: Maybe<Pick<AssessmentOrigin, "assessmentType">> }
>(
    assessment: T | null
): assessment is T & { origin: { assessmentType: AssessmentDataType.ACTUAL } } {
    return assessment?.origin?.assessmentType === AssessmentDataType.ACTUAL;
}

export function isManualEpcData<
    T extends { origin?: Maybe<Pick<AssessmentOrigin, "assessmentType">> }
>(
    assessment: T
): assessment is T & {
    origin: { assessmentType: AssessmentDataType.ESTIMATE };
} {
    return assessment?.origin?.assessmentType === AssessmentDataType.ESTIMATE;
}

interface AssessmentWithAssessmentMetadata {
    assessmentMetadata: {
        nationalBuildingIds?: string[] | null | undefined;
        addresses?: string[] | null | undefined;
    };
}
export function getNationalBuildingIds<
    T extends AssessmentWithAssessmentMetadata
>(assessments: readonly T[]) {
    return _.chain(assessments)
        .flatMap(
            (assessment) => assessment.assessmentMetadata.nationalBuildingIds
        )
        .uniq()
        .compact()
        .value();
}

export function getAddresses<T extends AssessmentWithAssessmentMetadata>(
    assessments: readonly T[]
) {
    return _.chain(assessments)
        .flatMap((assessment) => assessment.assessmentMetadata.addresses)
        .uniq()
        .compact()
        .value();
}

export const getEpcIdentifier = (
    data: IntegrationEpcAssessmentData | IntegrationEpcUkAssessmentData,
    t: TFunction
) => {
    if ("epcIdentifier" in data) {
        return data.epcIdentifier === "0"
            ? upperCase(t("common.draft", "draft"))
            : data.epcIdentifier;
    }
    if ("lmkKey" in data) {
        return data.lmkKey;
    }

    return "";
};
