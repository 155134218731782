import {
    OtherHousesOutlined,
    FolderOutlined,
    NoteOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { S } from "./menu.styles";
import { ComingSoonBadge } from "../ComingSoonBadge";

export const Menu = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const menuItems = [
        {
            id: "overview",
            text: t("legacyNext.overview", "overview"),
            icon: <OtherHousesOutlined fontSize="small" />,
            path: "/home",
            hasComingSoonBadge: false,
        },
        {
            id: "projects",
            text: t("legacyNext.projects", "projects"),
            icon: <FolderOutlined fontSize="small" />,
            path: "/#",
            hasComingSoonBadge: true,
        },
        {
            id: "reports",
            text: t("legacyNext.reports", "overview"),
            icon: <NoteOutlined fontSize="small" />,
            path: "/#",
            hasComingSoonBadge: true,
        },
    ];

    return (
        <S.Menu className="menu">
            {menuItems.map((item) => {
                const isSelected = location.pathname === item.path;

                return (
                    <S.MenuItem
                        key={item.id}
                        isSelected={isSelected}
                        to={item.path}
                    >
                        <S.MenuBody>
                            {item.icon}
                            <Typography variant="h5">{item.text}</Typography>
                        </S.MenuBody>
                        {item.hasComingSoonBadge && <ComingSoonBadge />}
                    </S.MenuItem>
                );
            })}
        </S.Menu>
    );
};
