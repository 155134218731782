import { Box } from "@mui/material";
import { t } from "i18next";
import { useCallback, useContext, useState } from "react";

import Loading from "components/Loading/Loading";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    AssessmentDataType,
    BenchmarkType,
    OrgProjectedEmissionSummaryFragment,
} from "graphql-types/graphql";
import { BenchmarksContext } from "utils/benchmarksContext";

import { ChartContainer } from "./ChartContainer";
import { ChartHeader } from "./ChartHeader";
import { DataQualityChart } from "./DataQualityChart";
import { MonthlyBuildingAverageChart } from "./MonthlyBuildingAverageChart";
import { MonthlyPortfolioIntensityChart } from "./MonthlyIntensityChart";
import { MonthlyTotalChart } from "./MonthlyTotalChart";
import { BenchmarkPerformanceChart } from "./MuiBenchmarkPerformanceChart";
import { Summary } from "./types/monthlyChart.types";
import { ActiveCountryBenchmarkProvider } from "./utils/activeCountryBenchmarkContext";
import { OutlierTable } from "../Table";
import { ChartType } from "../types";

type Props = {
    activeTab: AssessmentDataType;
    summaries: Summary[];
    projected: OrgProjectedEmissionSummaryFragment[];
    isLoading: boolean;
};

export const MonthlyDashboardCharts = (props: Props) => {
    const { activeTab, summaries, projected, isLoading } = props;
    const { benchmarks } = useContext(BenchmarksContext);

    const [type, setType] = useState<ChartDisplayType>(
        ChartDisplayType.EMISSIONS
    );

    const [activeChart, setActiveChart] = useState<ChartType>(
        ChartType.PORTFOLIO_RISK_PROFILE
    );

    const ActiveChartComponent = useCallback(() => {
        switch (activeChart) {
            case ChartType.PORTFOLIO_INTENSITY:
                return (
                    <MonthlyPortfolioIntensityChart
                        type={type}
                        summaries={summaries}
                        projected={projected}
                    />
                );
            case ChartType.WEIGHTED_AVERAGE:
                return (
                    <MonthlyBuildingAverageChart
                        type={type}
                        summaries={summaries}
                        projected={projected}
                    />
                );
            case ChartType.PORTFOLIO_RISK_PROFILE:
            case ChartType.BASELINE_PERFORMANCE: {
                const benchmarkType =
                    activeChart === ChartType.PORTFOLIO_RISK_PROFILE
                        ? BenchmarkType.MARKET
                        : BenchmarkType.BASELINE;

                return (
                    <BenchmarkPerformanceChart
                        benchmarks={benchmarks}
                        benchmarkType={benchmarkType}
                        activeDataType={activeTab}
                    />
                );
            }
            case ChartType.MONTH_BY_MONTH:
                return (
                    <MonthlyTotalChart
                        type={type}
                        summaries={summaries}
                        projected={projected}
                    />
                );
            case ChartType.DATA_QUALITY_MONTH_BY_MONTH:
                return <DataQualityChart activeTab={activeTab} byMonth />;
        }
    }, [activeChart, benchmarks, activeTab, type, summaries, projected]);

    const displayTable =
        activeChart === ChartType.MONTH_BY_MONTH ||
        activeChart === ChartType.PORTFOLIO_INTENSITY;

    return (
        <ActiveCountryBenchmarkProvider benchmarks={benchmarks}>
            <ChartContainer chart={activeChart} type={type}>
                <ChartHeader
                    chartState={[activeChart, setActiveChart]}
                    typeState={[type, setType]}
                />

                <Box
                    minHeight={450}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    {isLoading ? (
                        <Loading description={t("loading.title", "Loading")} />
                    ) : (
                        <ActiveChartComponent />
                    )}
                </Box>

                {displayTable && (
                    <OutlierTable
                        type={type}
                        dataType={activeTab}
                        chartType={activeChart}
                    />
                )}
            </ChartContainer>
        </ActiveCountryBenchmarkProvider>
    );
};
