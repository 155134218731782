import { Box as MUIBox, Chip as MUIChip } from "@mui/material";
import _ from "lodash";

import { theme } from "styling/themes";

import { getEPCPaletteFromTheme } from "../../utils/epcPalletteTheme";

const Chip = (props: any) => <MUIChip size="small" {...props} />;
const Box = (props: any) => <MUIBox display="flex" gap={0.5} {...props} />;

function EpcClassificationsCell(classifications: string[]) {
    const epcClassifications = Object.entries(_.countBy(classifications));

    return (
        <Box>
            {epcClassifications.map(([classification, count], index) => {
                const palette = getEPCPaletteFromTheme(theme, classification);
                const chipStyle = {
                    fontSize: theme.typography.caption.fontSize,
                    padding: theme.spacing(0.5),
                    color: palette.main,
                    backgroundColor: palette.light,
                };

                return (
                    <Box key={index}>
                        <Chip sx={chipStyle} label={classification} />
                        {count > 1 && (
                            <Chip sx={chipStyle} label={`+${count - 1}`} />
                        )}
                    </Box>
                );
            })}
        </Box>
    );
}

export default EpcClassificationsCell;
