import { ChartsAxisContentProps } from "@mui/x-charts";
import { TFunction } from "i18next";
import { ReactElement } from "react";

import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    ChartTooltip,
    formatValue,
    getBenchmarkSection,
    getProjectedSection,
} from "glue/Chart/components/ChartTooltip";
import { DASH_SEPARATOR } from "utils";
import { formatNumberLocale } from "utils/report.helpers";

export const AssessmentChartTooltip =
    (
        type: ChartDisplayType,
        t: TFunction,
        label?: string
    ): ((props: ChartsAxisContentProps) => ReactElement | null) =>
    (props: ChartsAxisContentProps) => {
        const { axisValue, dataIndex, axis, series } = props;

        if (dataIndex === null || dataIndex === undefined) {
            return null;
        }
        const header =
            axis.valueFormatter && axisValue
                ? axis.valueFormatter(axisValue, {
                      location: "tooltip",
                  })
                : axisValue?.toString();

        const verticalSeries = series.filter(
            (serie: any) =>
                serie.layout === "vertical" && serie.id !== "projected"
        );

        const sectionItems = verticalSeries.map((serie: any) => ({
            label: serie.label,
            value: serie.data[dataIndex]
                ? serie.valueFormatter(serie.data[dataIndex]) ||
                  formatValue(serie.data[dataIndex], type, t)
                : DASH_SEPARATOR,
            textColor: serie.color,
        }));

        const total = verticalSeries
            .map((serie: any) => serie.data[dataIndex])
            .reduce((acc: number, curr: number) => acc + curr, 0);

        const totalSeriesLabel =
            type === ChartDisplayType.EMISSIONS
                ? t(
                      "forecastDashboard.labels.totalEmissions",
                      "Total Emissions"
                  )
                : t(
                      "portfolioDashboard.boxes.energy",
                      "total energy consumption"
                  );

        const totalSubHeader = {
            label: label ?? totalSeriesLabel,
            value:
                verticalSeries[0]?.valueFormatter(total, {} as any) ??
                formatValue(total, type, t),
        };

        const projectedSubHeader = getProjectedSection(series, dataIndex);
        const benchmarkSection = getBenchmarkSection(series, dataIndex, t);

        const subHeaders = [
            ...(total ? [totalSubHeader] : []),
            ...(projectedSubHeader ? [projectedSubHeader] : []),
        ];

        const [first]: any = series.filter(({ data }) => data[dataIndex]);
        const hasAsset = first?.assetCount?.[dataIndex] !== undefined;

        const assetSection = hasAsset
            ? [
                  {
                      label: t("portfolioDashboard.boxes.assets", "Assets"),
                      value: first?.assetCount[dataIndex] || 0,
                  },
                  {
                      label: t("portfolioDashboard.boxes.area", "Area"),
                      value:
                          formatNumberLocale(first?.totalArea[dataIndex], t, {
                              max: 0,
                          }) +
                          " " +
                          t("portfolioDashboard.boxes.sqm", "m²"),
                  },
              ]
            : [];

        return (
            <ChartTooltip
                sections={[
                    {
                        header,
                        subHeaders,
                    },
                    { items: sectionItems },
                    hasAsset ? { items: assetSection } : {},
                    ...(benchmarkSection ? [benchmarkSection] : []),
                ]}
            />
        );
    };
