import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ComingSoonBadge = () => {
    const { t } = useTranslation();
    return (
        <Chip
            label={t("legacyNext.comingSoon", "Coming soon")}
            color="warning"
        />
    );
};
