import { Map } from "@vis.gl/react-google-maps";

import { InsightsProperty } from "graphql-types/graphql";

import { AssetMarker } from "./AssetMarker";
import { FitBounds } from "./FitBounds";

type Props = {
    assetList: InsightsProperty[];
};

export const LegacyMap = (props: Props) => {
    const { assetList } = props;

    const markers = assetList.map((asset) => {
        return <AssetMarker key={asset.id} asset={asset} />;
    });

    return (
        <Map
            style={{ width: "100%", height: "100%" }}
            defaultCenter={{ lat: 55.6768, lng: 12.5951 }}
            defaultZoom={18}
            defaultTilt={60}
            defaultHeading={0}
            mapTypeId="satellite"
            gestureHandling="greedy"
            disableDefaultUI
            fullscreenControl
        >
            {markers}
            <FitBounds assetList={assetList} />
        </Map>
    );
};
