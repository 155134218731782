import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { getChartTypeUnit } from "components/PortfolioDashboard/helpers";
import { ChartType } from "components/PortfolioDashboard/types";
import {
    ChartDisplayType,
    chartTypeTranslations,
} from "glue/Chart/chart.constants";

type Props = {
    chartState: [ChartType, (chart: ChartType) => void];
    typeState: [ChartDisplayType, (type: ChartDisplayType) => void];
};

const benchmarkCharts = [
    ChartType.PORTFOLIO_RISK_PROFILE,
    ChartType.BASELINE_PERFORMANCE,
];

export const RadioOptions = (props: Props) => {
    const {
        chartState: [activeChart, setActiveChart],
        typeState: [type, setType],
    } = props;

    const { t } = useTranslation();

    const isBenchmarkChart = benchmarkCharts.includes(activeChart);

    const radioOptions = isBenchmarkChart
        ? benchmarkCharts.map((option) => ({
              value: option,
              label:
                  option === ChartType.BASELINE_PERFORMANCE
                      ? t("benchmark.portfolioBenchmark", "portfolio benchmark")
                      : t("benchmark.countryBenchmark", "country benchmark"),
              unit: null,
          }))
        : [ChartDisplayType.EMISSIONS, ChartDisplayType.ENERGY].map(
              (option) => ({
                  value: option,
                  label: chartTypeTranslations(option, t),
                  unit: getChartTypeUnit(activeChart, option),
              })
          );

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isBenchmarkChart) {
            const value = Number(event.target.value);
            return setActiveChart(value as ChartType);
        }

        const selectedValue = event.target.value as ChartDisplayType;
        setType(selectedValue);
    };

    return (
        <RadioGroup
            row
            value={isBenchmarkChart ? activeChart : type}
            onChange={handleRadioChange}
        >
            {radioOptions.map((option) => (
                <FormControlLabel
                    key={option.value}
                    control={<Radio />}
                    value={option.value}
                    label={
                        <Typography display="inline">
                            {option.label}
                            {option.unit && (
                                <Typography
                                    component="span"
                                    color="grey.500"
                                    display="inline"
                                    pl={1}
                                >
                                    ({option.unit})
                                </Typography>
                            )}
                        </Typography>
                    }
                />
            ))}
        </RadioGroup>
    );
};
