import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { Button, Menu, MenuItem } from "@mui/material";
import { Settings } from "luxon";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export const LanguageSwitch = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const { i18n } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = useCallback(
        (language: string) => {
            i18n.changeLanguage(language);
            const luxonLocale = language === "en" ? "en-GB" : language;
            Settings.defaultLocale = luxonLocale;
        },
        [i18n]
    );

    return (
        <div>
            <Button
                id="language-switch-button"
                aria-controls={open ? "language-switch-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <LanguageOutlinedIcon />
            </Button>
            <Menu
                id="language-switch-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "language-switch-button",
                }}
            >
                <MenuItem
                    selected={i18n.language === "da"}
                    onClick={() => changeLanguage("da")}
                >
                    Dansk
                </MenuItem>
                <MenuItem
                    selected={i18n.language === "en"}
                    onClick={() => changeLanguage("en")}
                >
                    English
                </MenuItem>
            </Menu>
        </div>
    );
};
