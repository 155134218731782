import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
    const { t } = useTranslation();

    return (
        <Box height="100vh" alignContent="center">
            <Box
                display="flex"
                flexDirection="column"
                gap={5}
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="h1">404 🫣</Typography>
                <Typography variant="h2">Page not found</Typography>
                <Link to="/">
                    {t("common.goBack", "go back to the home page")}
                </Link>
            </Box>
        </Box>
    );
};
