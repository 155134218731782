import { Download } from "@mui/icons-material";
import { Box, MenuItem, Select, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { BenchmarkPanel } from "components/Benchmark";
import { useDownloadContext } from "components/PortfolioDashboard/helpers/useDownloadContext";
import PortfolioSelector from "components/PortfolioDashboard/PortfolioSelector";
import { ChartType } from "components/PortfolioDashboard/types";
import { BenchmarkMarkers } from "components/types";
import { ChartDisplayType } from "glue/Chart/chart.constants";

import { RadioOptions } from "./RadioOptions";
import { useActiveCountryBenchmark } from "../utils/activeCountryBenchmarkContext";

const CountryBenchmarkSelector = () => {
    const { activeCountryCode, countryCodes, setCountryCode } =
        useActiveCountryBenchmark();

    if (activeCountryCode === undefined) {
        return null;
    }

    return (
        <Select
            size="small"
            value={activeCountryCode}
            onChange={(e) => {
                setCountryCode(e.target.value as string);
            }}
        >
            {countryCodes.map((countryCode) => (
                <MenuItem key={countryCode} value={countryCode}>
                    {countryCode}
                </MenuItem>
            ))}
        </Select>
    );
};

type Props = {
    onPrint?: () => void;
    isYearlyBreakdown?: boolean;
    benchmarkState?: [BenchmarkMarkers, (id: string) => void];
    chartState: [ChartType, (chart: ChartType) => void];
    typeState: [ChartDisplayType, (type: ChartDisplayType) => void];
};

const hasRadioOptions = (activeChart: ChartType): boolean =>
    [
        ChartType.TOTAL_EMISSION,
        ChartType.MONTH_BY_MONTH,
        ChartType.WEIGHTED_AVERAGE,
        ChartType.PORTFOLIO_INTENSITY,
        ChartType.BASELINE_PERFORMANCE,
        ChartType.PORTFOLIO_RISK_PROFILE,
    ].includes(activeChart);

export const ChartHeader = (props: Props) => {
    const {
        onPrint,
        isYearlyBreakdown = true,
        benchmarkState: [benchmark, setBenchmark] = [],
        chartState: [activeChart, setActiveChart],
    } = props;

    const { t } = useTranslation();

    const { onDownload } = useDownloadContext();

    const displayCountrySelector =
        activeChart === ChartType.PORTFOLIO_RISK_PROFILE;

    const isIntensityChart =
        !isYearlyBreakdown && activeChart === ChartType.PORTFOLIO_INTENSITY;
    const displayBenchmarks = isIntensityChart && setBenchmark;

    const displayRadioOptions = hasRadioOptions(activeChart);

    return (
        <>
            <Box sx={{ display: "flex", columnGap: 4 }}>
                <PortfolioSelector
                    isYearlyBreakdown={isYearlyBreakdown}
                    onChange={setActiveChart}
                    activeChart={activeChart}
                />

                {displayRadioOptions && (
                    <RadioOptions
                        chartState={[activeChart, setActiveChart]}
                        typeState={props.typeState}
                    />
                )}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 2,
                        flexGrow: 1,
                    }}
                >
                    {displayCountrySelector && <CountryBenchmarkSelector />}
                    {isIntensityChart && (
                        <Button
                            sx={{ mr: 2 }}
                            variant="outlined"
                            endIcon={<Download />}
                            onClick={() => onDownload()}
                        >
                            {t("common.download", "download")} .csv
                        </Button>
                    )}

                    <Button variant="outlined" onClick={onPrint}>
                        {t("common.downloadGraph", "download graph")}
                    </Button>
                </Box>
            </Box>
            {displayBenchmarks && (
                <Box
                    sx={{
                        mt: 2,
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <BenchmarkPanel
                        allowDownload
                        title={"Country Benchmark"}
                        benchmarkMarkers={benchmark || []}
                        onToggleMarkerVisibility={setBenchmark}
                    />
                </Box>
            )}
        </>
    );
};
