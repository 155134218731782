import { Theme } from "@mui/material";
import { BarSeriesType } from "@mui/x-charts";
import { TFunction } from "i18next";
import _ from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import {
    ChartDisplayType,
    PROJECTED_SERIES_COLOR,
} from "glue/Chart/chart.constants";
import { formatValue } from "glue/Chart/components/ChartTooltip";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { getYearLabel } from "../charts.helper";
import { getVerticalSeries } from "../helpers/monthlyChart.helpers";
import { PortfolioChartData } from "../types/chart.types";

export const getYearlyBuildingAverageSeries = (
    summaries: SummaryYearOverview[],
    palette: Theme["palette"],
    type: ChartDisplayType,
    t: TFunction
): PortfolioChartData => {
    const data = summaries.map(({ year, projected }) => {
        return {
            xAxisData: year,
            projectedSeriesData:
                projected && type === ChartDisplayType.EMISSIONS
                    ? getConvertedGramsToMassUnit(projected, UnitMass.KILOGRAM)
                    : projected,
        };
    });

    const [xAxisData, projectedSeriesData] = _.chain(data)
        .map(Object.values)
        .unzip()
        .value();

    const verticalSeries = getVerticalSeries(summaries, palette, type, t, true);

    const series = [
        ...verticalSeries,
        {
            id: "projected",
            type: "bar" as const,
            stack: "total",
            data: projectedSeriesData,
            color: PROJECTED_SERIES_COLOR,
            label: t("portfolioDashboard.boxes.projected", "Projected"),
            valueFormatter: (value: any) =>
                formatValue(value, type, t, true, UnitMass.KILOGRAM) || "",
        },
    ] as BarSeriesType[];

    return {
        series,
        xAxis: [
            {
                data: xAxisData,
                scaleType: "band",
                valueFormatter: (year: number) => getYearLabel(year, t),
            },
        ],
        years: xAxisData,
    };
};
