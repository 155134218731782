import { PropsWithChildren } from "react";

import companyLogo from "assets/legacyLogoIconLightBlue.png";
import { LanguageSwitch, Menu, UserSettings } from "legacyNext/components";

import { S } from "./dashboard-layout.styles";

export const DashboardLayout = (props: PropsWithChildren) => {
    const { children } = props;

    return (
        <S.Layout>
            <S.Navigation>
                <S.Logo>
                    <S.Img variant="square" src={companyLogo} alt="Legacy" />
                </S.Logo>
                <Menu />
            </S.Navigation>
            <S.Body>
                <S.Header>
                    <LanguageSwitch />
                    <UserSettings />
                </S.Header>
                <S.Content>{children}</S.Content>
            </S.Body>
        </S.Layout>
    );
};
