import { ExitToApp } from "@mui/icons-material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
    Button,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import auth from "utils/auth";

export const UserSettings = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="user-settings-button"
                aria-controls={open ? "user-settings-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <PersonOutlinedIcon />
            </Button>
            <Menu
                id="user-settings-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "user-settings-button",
                }}
            >
                <MenuItem
                    component={Link}
                    to="/auth/login"
                    onClick={() => auth.logOut()}
                >
                    <ListItemIcon>
                        <ExitToApp fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography>{t("navbar.logOut", "Log Out")}</Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};
