import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentChartTooltip } from "components/AssessmentStats/Chart/MUIAssessmentChartTooltip";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import { OrgProjectedEmissionSummaryFragment } from "graphql-types/graphql";

import {
    getMonthlyChartAxis,
    getVerticalSeries,
} from "../helpers/monthlyChart.helpers";
import {
    getChartData,
    getMonthlyProjectedChartSeries,
} from "../MonthlyTotalChart/MonthlyTotalChart.helpers";
import { Summary } from "../types/monthlyChart.types";

type Props = {
    summaries: Summary[];
    projected: OrgProjectedEmissionSummaryFragment[];
    type: ChartDisplayType;
};

export const MonthlyPortfolioIntensityChart = (props: Props) => {
    const { summaries, projected, type } = props;

    const { t } = useTranslation();
    const { palette } = useTheme();

    const { activeYear } = useSelectedYearContext();

    const label = t("portfolioDashboard.boxes.intensity", "Intensity");

    const { series, xAxis, yAxis } = useMemo(() => {
        const intensity = getChartData(summaries, type, true);
        const totalKey =
            type === ChartDisplayType.EMISSIONS
                ? "ownedEmissionForArea"
                : "ownedEnergyForArea";
        const projectedSeries = getMonthlyProjectedChartSeries({
            summaries: intensity,
            projected,
            type,
            totalKey,
            t,
            area: true,
        });

        const series = getVerticalSeries(intensity, palette, type, t);
        if (new Date().getFullYear() === activeYear) {
            series.push(projectedSeries);
        }

        return {
            series,
            ...getMonthlyChartAxis(
                projectedSeries.data?.length || intensity.length,
                true,
                type
            ),
        };
    }, [summaries, projected, palette, type, t, activeYear]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            legend={{
                hidden: false,
                position: { vertical: "bottom" },
            }}
            customTooltip={AssessmentChartTooltip(type, t, label)}
        />
    );
};
