import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    Operation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { SentryLink } from "apollo-link-sentry";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { createUploadLink } from "apollo-upload-client";
import { jwtDecode } from "jwt-decode";

import auth from "./auth";
import customFetch from "./customFetch";
const apiUrl = process.env.REACT_APP_API_URL;

const refreshLink = new TokenRefreshLink<{
    access_token: string;
    refresh_token: string;
}>({
    accessTokenField: "tokens",
    isTokenValidOrUndefined: async () => {
        const accessToken = auth.getAccessToken();

        if (!accessToken) return false;

        const accessTokenDecrypted = jwtDecode(accessToken);

        if (
            accessTokenDecrypted.exp &&
            accessTokenDecrypted.exp < (new Date().getTime() + 1) / 1000
        ) {
            return false;
        }

        return true;
    },
    fetchAccessToken: () => {
        const refreshToken = auth.getRefreshToken();
        return fetch(`${apiUrl}/auth/refresh`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ refreshToken }),
        });
    },
    handleFetch: (tokens) => {
        auth.setAccessToken(tokens.access_token);
        auth.setRefreshToken(tokens.refresh_token);
    },
    handleError: (err) => {
        if (
            !navigator.onLine ||
            (err instanceof TypeError &&
                err.message === "Network request failed")
        ) {
            return;
        } else {
            auth.logOut();
            window.location.reload();
        }
    },
});

// Logout when getting an UNAUTHENTICATED error from graphql
const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ extensions }) => {
            if (
                extensions &&
                "code" in extensions &&
                extensions.code === "UNAUTHENTICATED"
            ) {
                auth.logOut();
                window.location.reload();
            }
        });
});

const uploadLink = createUploadLink({
    uri: `${apiUrl}/graphql`,
    fetch: customFetch as any,
    headers: { "Apollo-Require-Preflight": "true" },
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = auth.getAccessToken();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const cache = new InMemoryCache({
    typePolicies: {
        LocationIntegration: {
            fields: {
                data: { merge: true },
            },
        },
        Location: { merge: true },
        User: { merge: true },
        Organization: { merge: true },
        MissingMeterDataAlertData: { merge: true },
    },
});

const addAssetGroupMiddleware = new ApolloLink(
    (operation: Operation, forward) => {
        // Add assetGroupId to the query if it is a query and has assetGroupId as a variable
        const queryDefinition = operation.query.definitions[0];
        const isQuery =
            queryDefinition.kind === "OperationDefinition" &&
            queryDefinition.operation === "query";

        const hasAssetGroup =
            isQuery &&
            queryDefinition.variableDefinitions?.find(
                (variable) => variable.variable.name.value === "assetGroupId"
            );

        const queryName = isQuery ? queryDefinition.name?.value : undefined;

        if (queryName === "GetAssets" || queryName === "OutlierAssets") {
            const assetGroupId = localStorage.getItem("assetGroupId");
            const id = JSON.parse(assetGroupId || "null");

            if (!id) return forward(operation);

            const filter = {
                ...operation.variables.filter,
                assetGroupId: { eq: id },
            };

            operation.variables["filter"] = filter;
        } else if (hasAssetGroup) {
            const assetGroupId = localStorage.getItem("assetGroupId");
            operation.variables["assetGroupId"] = JSON.parse(
                assetGroupId || "null"
            );
        }

        return forward(operation);
    }
);

const client = new ApolloClient({
    link: ApolloLink.from([
        new SentryLink({
            attachBreadcrumbs: {
                includeVariables: true,
                includeError: true,
            },
        }),
        refreshLink,
        addAssetGroupMiddleware,
        errorLink,
        authLink.concat(uploadLink),
    ]),
    uri: `${apiUrl}/graphql`,
    cache,
    name: "legacy-web",
});

export default client;
