import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const AssetRisk = () => {
    const { t } = useTranslation();

    return (
        <Typography variant="h6" align="center" style={{ padding: "20px" }}>
            {t("legacyNext.noAssets", "no assets")}
        </Typography>
    );
};
