import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useUserContext } from "context";

import { S } from "./header.styles";

export const Header = () => {
    const { t } = useTranslation();
    const user = useUserContext();

    return (
        <S.Header>
            <Typography variant="h1">
                {t("legacyNext.overview", "overview")}
            </Typography>
            <Typography variant="h3">
                {t("legacyNext.overviewDescription", "", {
                    name: user?.name,
                })}
            </Typography>
        </S.Header>
    );
};
