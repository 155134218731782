import { gql } from "@apollo/client";

const ASSET_COVERAGE_ASSESSMENT = gql`
    fragment AssetCoverageAssessment on Assessment {
        id
        to
        from
        source
        bestEffortPriority
        unit
        emissionDataGrams {
            ownedEmission
        }
        consumptionData {
            ownedConsumption
        }
    }
`;

export const ASSET_DATA_COVERAGE = (isBestEffort: boolean) => {
    const filter = isBestEffort ? "filter: { dataType: COMBINED }" : "";

    return gql`
        query GetAssetDataCoverage($locationId: ID!, $from: DateTime, $to: DateTime) {
            location(id: $locationId) {
                id
                assessmentGroups(aggregation: Month, from: $from, to: $to, ${filter}) {
                    vertical
                    maxHighPriority
                    maxMediumPriority
                    assessments {
                        ...AssetCoverageAssessment
                    }
                }
            }
        }
        ${ASSET_COVERAGE_ASSESSMENT}
    `;
};
