import { useQuery } from "@apollo/client";
import {
    Checkbox,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    AssetGroupFilterCategory,
    FloorAreaType,
    GetAssetPropertySizeQuery,
} from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";
import { FilterItemOption, FilterValue } from "utils";

import { PROPERTY_SIZE_QUERY } from "./property-size.query";
import { S } from "../../../page.styles";

type Props = {
    onChange: (filter: FilterItemOption) => void;
};

export const FilterOptionPropertySize = (props: Props) => {
    const { onChange } = props;

    const { t } = useTranslation();
    const { translations } = useAssetFilterTranslations();

    const [value, setValue] = useState<string[]>([]);

    const { data } = useQuery<GetAssetPropertySizeQuery>(PROPERTY_SIZE_QUERY);

    const buildingAreas = data?.me.organization?.buildingAreas?.filter(
        (buildingArea) => buildingArea.floorAreaType !== FloorAreaType.XXXLARGE
    );

    const list =
        buildingAreas?.map((buildingArea) => {
            const { floorAreaType, area } = buildingArea;

            return {
                key: FloorAreaType[floorAreaType],
                value: `${area.min} - ${area.max} ${t(
                    "filter.m2",
                    "m²"
                )}, ${floorAreaType}`,
            };
        }) ?? [];

    function onSelectedValue(event: SelectChangeEvent<typeof value>) {
        const selectedValues = event.target.value as string[];

        setValue(selectedValues);

        const filterValues = selectedValues.map((valueItem) => {
            const propertyItem = list.find(
                (option) => option.value === valueItem
            ) as FilterValue;

            return {
                ...propertyItem,
            };
        });

        onChange({
            category: AssetGroupFilterCategory.PROPERTYSIZE,
            values: filterValues,
        });
    }

    return (
        <S.FilterWrapper>
            <InputLabel id="asset-filter-property-size-label">
                {translations[AssetGroupFilterCategory.PROPERTYSIZE]}
            </InputLabel>
            <Select
                labelId="asset-filter-property-size-label"
                id="asset-filter-property-size"
                multiple
                value={value}
                onChange={onSelectedValue}
                input={
                    <OutlinedInput
                        label={
                            translations[AssetGroupFilterCategory.PROPERTYSIZE]
                        }
                    />
                }
                renderValue={(selected) => selected.join(", ")}
            >
                {list.map((propertySizeItem) => (
                    <MenuItem
                        key={propertySizeItem.key}
                        value={propertySizeItem.value}
                    >
                        <Checkbox
                            checked={value.includes(propertySizeItem.value)}
                        />
                        <ListItemText primary={propertySizeItem.value} />
                    </MenuItem>
                ))}
            </Select>
        </S.FilterWrapper>
    );
};
