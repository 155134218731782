import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import logo from "assets/legacyLogoIconLightBlue.png";
import { getChartTypeUnit } from "components/PortfolioDashboard/helpers";
import { getPortfolioOptions } from "components/PortfolioDashboard/helpers/portfolioSelectorHelpers";
import { ChartDisplayType } from "glue/Chart/chart.constants";

import { S } from "./chart-container.style";
import { ChartType } from "../../types";

type Props = {
    chart: ChartType;
    type: ChartDisplayType;
};

export const ChartPrintHeader = (props: Props) => {
    const { chart, type } = props;

    const { t } = useTranslation();
    const title = [
        ...getPortfolioOptions(true, t),
        ...getPortfolioOptions(false, t),
    ].find(
        (option) =>
            option.value === chart ||
            (ChartType.BASELINE_PERFORMANCE === chart &&
                option.value === ChartType.PORTFOLIO_RISK_PROFILE)
    )?.title;

    const hideSubtitle = [
        ChartType.DATA_QUALITY,
        ChartType.DATA_QUALITY_MONTH_BY_MONTH,
    ].includes(chart);

    const subtitle = useMemo(() => {
        if (hideSubtitle) return null;

        if (chart === ChartType.PORTFOLIO_RISK_PROFILE) {
            return t("benchmark.countryBenchmark", "Country Benchmark");
        }
        if (chart === ChartType.BASELINE_PERFORMANCE) {
            return t("benchmark.portfolioBenchmark", "Portfolio Benchmark");
        }

        const chartType =
            type === ChartDisplayType.EMISSIONS
                ? t("portfolioDashboard.chartHelpers.emissions", "Emissions")
                : t("portfolioDashboard.chartHelpers.energy", "Energy");

        const unit = getChartTypeUnit(chart, type);
        return `${chartType} (${unit})`;
    }, [chart, hideSubtitle, t, type]);

    return (
        <S.PrintContainer>
            <S.PrintHeader>
                <S.Title>{title}</S.Title>
                {!hideSubtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
            </S.PrintHeader>
            <S.Logo variant="square" src={logo} alt="Logo" />
        </S.PrintContainer>
    );
};
