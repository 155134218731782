import { Theme } from "@mui/material";
import { BarSeriesType } from "@mui/x-charts";
import { AxisValueFormatterContext } from "@mui/x-charts/internals";
import { TFunction } from "i18next";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import { formatValue } from "glue/Chart/components/ChartTooltip";
import { AssessmentVertical } from "graphql-types/graphql";
import {
    assessmentVerticalToColor,
    assessmentVerticalToTitle,
} from "utils/assessment";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { MonthlyChartData, EmissionByMonth } from "../types/monthlyChart.types";

export const lengthToMonthSeries = (length: number) =>
    Array.from({ length }, (_, i) => i + 1).map((month) =>
        DateTime.fromObject({ month })
    );

export const getMonthlyChartAxis = (
    length: number,
    withArea = true,
    type: ChartDisplayType = ChartDisplayType.EMISSIONS
): Pick<MonthlyChartData, "xAxis" | "yAxis"> => {
    const months = lengthToMonthSeries(length);
    const label = type === ChartDisplayType.EMISSIONS ? "kg CO2e" : "kWh";

    return {
        xAxis: [
            {
                data: months,
                scaleType: "band",
                valueFormatter: (
                    value: DateTime,
                    context: AxisValueFormatterContext
                ) =>
                    context.location === "tick"
                        ? value.toFormat("MMM")
                        : value.monthLong || "",
            },
        ],
        yAxis: [{ label: `${label}${withArea ? " /m²" : ""}` }],
    };
};

const verticalHasData = (
    data: Pick<EmissionByMonth, "breakdown">[],
    vertical: AssessmentVertical
) => data.some(({ breakdown }) => breakdown?.[vertical] > 0);

const getVerticalSeriesLabel = (
    data: Pick<EmissionByMonth, "breakdown">[],
    vertical: AssessmentVertical,
    t: TFunction
) => {
    const label = assessmentVerticalToTitle(vertical, t);

    const hasData = verticalHasData(data, vertical);
    if (hasData) return label;

    return `${label} (${t(
        "portfolioDashboard.graphInfo.notConverted",
        "not converted"
    )})`;
};

export const getVerticalSeries = (
    summaries: Pick<
        EmissionByMonth,
        "breakdown" | "assetCount" | "totalArea"
    >[],
    palette: Theme["palette"],
    type: ChartDisplayType,
    t: TFunction,
    withArea = true,
    unit = UnitMass.KILOGRAM
): BarSeriesType[] => {
    const verticals = new Set(
        summaries.flatMap(({ breakdown }) => Object.keys(breakdown))
    ) as Set<AssessmentVertical>;

    return Array.from(verticals)
        .map((vertical) => {
            const hasData = verticalHasData(summaries, vertical);

            const color = hasData
                ? palette[assessmentVerticalToColor(vertical) || "primary"].main
                : "grey";

            const data = summaries.map(({ breakdown }) =>
                type === ChartDisplayType.EMISSIONS
                    ? getConvertedGramsToMassUnit(
                          breakdown?.[vertical] || 0,
                          unit
                      )
                    : breakdown?.[vertical] || 0
            );

            return {
                id: vertical,
                type: "bar" as const,
                stack: "total",
                data,
                color,
                totalArea: summaries.map(({ totalArea }) => totalArea),
                assetCount: summaries.map(({ assetCount }) => assetCount),
                hasData,
                valueFormatter: (value: any) =>
                    formatValue(value, type, t, withArea, unit) || "",
                label: getVerticalSeriesLabel(summaries, vertical, t),
            };
        })
        .sort((a) => (a.hasData ? -1 : 1));
};
