import { useMap } from "@vis.gl/react-google-maps";
import { useEffect } from "react";

import { InsightsProperty } from "graphql-types/graphql";

type Props = {
    assetList: InsightsProperty[];
};

export const FitBounds = (props: Props) => {
    const { assetList } = props;
    const map = useMap(); // Get the map instance

    useEffect(() => {
        if (!map || assetList.length === 0) return;

        const bounds = new google.maps.LatLngBounds();

        if (assetList.length === 1) {
            // If only one marker, set a reasonable zoom level and center the map
            const [asset] = assetList;

            const location = {
                lat: asset.latitude,
                lng: asset.longitude,
            };

            map.setCenter(location);
            map.setZoom(20); // Adjust this zoom level as needed
            return;
        }

        assetList.forEach((asset) =>
            bounds.extend(
                new google.maps.LatLng(asset.latitude, asset.longitude)
            )
        );

        // Apply padding to keep some space around the markers
        map.fitBounds(bounds, { top: 50, bottom: 50, left: 50, right: 50 });
    }, [map, assetList]);

    return null;
};
