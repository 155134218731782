import { TFunction } from "i18next";
import { chain, compact, round, sumBy, uniq } from "lodash";

import { UnitMass } from "components/AssessmentStats/types";
import {
    formatDataQualityToDataQualityItems,
    getDataQualityTitle,
} from "components/DataQuality/DataQuality.helpers";
import { AssetFragment, DataQualityFragment } from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

export const formatAsset = (assets: AssetFragment[]) => {
    return assets.map((asset) => {
        const { emissionSummaries, benchmarkPerformances, epcs } = asset;

        const dataQuality = asset.dataQuality?.[0];

        const emission = emissionSummaries.length
            ? sumBy(emissionSummaries, "ownedEmission")
            : null;

        const ownedArea =
            emissionSummaries[0]?.ownedArea ?? asset.fallbackOwnedArea;
        const emissionPerArea =
            emission && ownedArea ? emission / ownedArea : null;

        const verticals = chain(emissionSummaries)
            .groupBy("vertical")
            .map((es, vertical) => ({
                vertical,
                sources: uniq(compact(es.flatMap((s) => s.sourceTypes))),
            }))
            .value();

        const classification = chain(epcs)
            .map((epc) => epc.classification)
            .uniq()
            .value();

        return {
            id: asset.id,
            ownedArea: ownedArea,
            externalId: asset.externalId,
            displayName: asset.displayName,
            classification: classification,
            verticals,
            dataQuality: dataQuality,
            performance: benchmarkPerformances?.[0]?.performance,
            totalEmission: emission
                ? getConvertedGramsToMassUnit(emission, UnitMass.KILOGRAM)
                : null,
            emissionPerArea: emissionPerArea
                ? getConvertedGramsToMassUnit(
                      emissionPerArea,
                      UnitMass.KILOGRAM
                  )
                : null,
        };
    });
};

export const formatDataQualityColumn = (
    value: DataQualityFragment,
    t: TFunction
) => {
    if (!value) {
        return "";
    }

    const items = formatDataQualityToDataQualityItems(value, t);

    const textItems = items.map(
        ({ type, sources }) =>
            `${getDataQualityTitle(type, false, t)}: ${t(
                "common.lists",
                "{{list, list}}",
                { list: sources, type: "unit" }
            )}`
    );

    return textItems.join(" / ");
};

export const formatPercentage = (performance: number) =>
    round(performance - 100);
