import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentChartTooltip } from "components/AssessmentStats/Chart/MUIAssessmentChartTooltip";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { Chart } from "glue/Chart";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import { OrgProjectedEmissionSummaryFragment } from "graphql-types/graphql";

import {
    getVerticalSeries,
    getMonthlyChartAxis,
} from "../helpers/monthlyChart.helpers";
import { getMonthlyProjectedChartSeries } from "../MonthlyTotalChart/MonthlyTotalChart.helpers";
import { Summary } from "../types/monthlyChart.types";
import { getAvgChartData } from "../utils/buildingAverageByMonthChart.utils";

type Props = {
    summaries: Summary[];
    projected: OrgProjectedEmissionSummaryFragment[];
    type: ChartDisplayType;
};

export const MonthlyBuildingAverageChart = (props: Props) => {
    const { summaries, projected, type } = props;

    const { t } = useTranslation();
    const { palette } = useTheme();
    const { activeYear } = useSelectedYearContext();

    const label = t("portfolioDashboard.boxes.average", "Average");
    const { series, xAxis, yAxis } = useMemo(() => {
        const buildingAverage = getAvgChartData(summaries, type);
        const totalKey =
            type === ChartDisplayType.EMISSIONS
                ? "avgEmissionIntensity"
                : "avgEnergyIntensity";

        const projectedSeries = getMonthlyProjectedChartSeries({
            summaries: buildingAverage,
            projected,
            type,
            totalKey,
            t,
        });

        const series = [
            ...getVerticalSeries(buildingAverage, palette, type, t),
        ];
        if (new Date().getFullYear() === activeYear) {
            series.push(projectedSeries);
        }

        return {
            series,
            ...getMonthlyChartAxis(
                projectedSeries.data?.length || buildingAverage.length,
                true,
                type
            ),
        };
    }, [palette, summaries, projected, t, type, activeYear]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            legend={{
                hidden: false,
                position: { vertical: "bottom" },
            }}
            customTooltip={AssessmentChartTooltip(type, t, label)}
        />
    );
};
