import { TFunction } from "i18next";
import { match } from "ts-pattern";

import { IntensityStranded } from "containers/StrategyPage/strategyPage.types";
import { DgnbCertification } from "graphql-types/graphql";

export const getCertificationTranslation = (
    certification: DgnbCertification,
    t: TFunction
) =>
    match(certification)
        .with(DgnbCertification.BRONZE, () =>
            t("strategy.certification.bronze")
        )
        .with(DgnbCertification.SILVER, () =>
            t("strategy.certification.silver")
        )
        .with(DgnbCertification.GOLD, () => t("strategy.certification.gold"))
        .with(DgnbCertification.PLATINUM, () =>
            t("strategy.certification.platinum")
        )
        .exhaustive();

export const findFirstStrandedYear = (
    intensities: IntensityStranded,
    afterYear: number
): number | undefined => {
    const alreadyStranded = intensities[afterYear]?.stranded;

    if (alreadyStranded) return afterYear;

    return Object.entries(intensities)
        .map(([key, value]) => ({ year: Number(key), ...value }))
        .filter(({ year, stranded }) => year > afterYear && stranded === true)
        .sort((a, b) => a.year - b.year)
        .map(({ year }) => year)[0];
};
