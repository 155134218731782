import { Navigate, Outlet } from "react-router-dom";

import { AssetGroupProvider } from "components/AssetsGroup/hooks";
import SelectedYearProvider from "components/YearSelector/YearContext";
import { UserProvider } from "context";
import auth from "utils/auth";

import NavBarLayout from "../../NavBarLayout/NavBarLayout";

const NavRoute = () => {
    if (auth.getAccessToken() === null) return <Navigate to="/auth/login" />;

    return (
        <UserProvider>
            <AssetGroupProvider>
                <SelectedYearProvider>
                    <NavBarLayout>
                        <Outlet />
                    </NavBarLayout>
                </SelectedYearProvider>
            </AssetGroupProvider>
        </UserProvider>
    );
};

export default NavRoute;
