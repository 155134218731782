import { GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Table, DYNAMIC_GRID_TABLE_HEIGHT } from "components/Table";
import { useBrowserStorage } from "hooks";

import { useGetAssetsQuery } from "./asset-list.hooks";
import { Filters, Header, Toolbar } from "./components";
import { formatAsset, getColumns } from "./helpers";

export const AssetList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [hiddenColumns, setHiddenColumns] = useBrowserStorage<string[]>(
        "assetsListHiddenColumns",
        ["performance", "classification"]
    );

    const { assets, loading, filterProps, tablePaginationProps } =
        useGetAssetsQuery(hiddenColumns as any);

    const handleRowClick = useCallback(
        (props: GridRowParams) =>
            navigate(`/location-overview/${props.row.id}`),
        [navigate]
    );

    const slots = useMemo(
        () => ({
            toolbar: () => <Toolbar fileName="AssetList" />,
        }),
        []
    );

    return (
        <>
            <Header />

            <Filters {...filterProps} />

            <Table
                isLoading={loading}
                columns={getColumns(t)}
                rows={formatAsset(assets)}
                onRowClick={handleRowClick}
                setCustomHeight={DYNAMIC_GRID_TABLE_HEIGHT}
                /* Pagination fields */
                paginationMode="server"
                sortingMode="server"
                {...tablePaginationProps}
                /** Toolbar **/
                slots={slots}
                slotProps={{
                    loadingOverlay: { variant: "linear-progress" },
                }}
                initialHiddenColumns={hiddenColumns}
                updateHiddenColumns={setHiddenColumns}
            />
        </>
    );
};
