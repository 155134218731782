import { TFunction } from "i18next";
import _ from "lodash";

import { EmissionUnit } from "graphql-types/graphql";

import { DASH_SEPARATOR } from "./strings.helpers";
import { UnitMass } from "../components/AssessmentStats/types";

export enum UNITS {
    KG = "kg",
    TONCO2e = "ton CO2e",
    M2 = "m2",
    KGCO2e = "kg CO2e",
}

export const getUnitByKey = (key = "", t: TFunction) => {
    switch (key) {
        case EmissionUnit.GJ:
            return t("common.units.gj.label", "GJ", { ns: "translation" });
        case EmissionUnit.KG:
            return t("common.units.kg.label", "kg", { ns: "translation" });
        case EmissionUnit.KM:
            return t("common.units.km.label", "km", { ns: "translation" });
        case EmissionUnit.KWH:
            return t("common.units.kwh.label", "kWh", { ns: "translation" });
        case EmissionUnit.LITER:
            return t("common.units.liter.label", "liter", {
                ns: "translation",
            });
        case EmissionUnit.M:
            return t("common.units.m.label", "M", { ns: "translation" });
        case EmissionUnit.M_3:
            return t("common.units.m3.label", "m³", { ns: "translation" });
        case "":
            return t("common.dashSeparator", DASH_SEPARATOR, {
                ns: "translation",
            });
        case "undefined":
        case "null":
            return "";
        default:
            return key;
    }
};

export const getDecimalLength = (val: number | string) => {
    return (
        (val.toString().split(".")[1] && val.toString().split(".")[1].length) ||
        0
    );
};

export const ALL_DECIMALS = "ALL_DECIMALS";

export const formatNumberLocale = (
    value: number | string,
    t: TFunction,
    precision:
        | typeof ALL_DECIMALS
        | {
              min?: number;
              max?: number;
          } = { min: 2, max: 2 }
) => {
    const translationOptions = {
        val: value,
        ns: "translation",
        minimumFractionDigits:
            precision === ALL_DECIMALS
                ? getDecimalLength(value)
                : precision.min,
        ...(!(precision === ALL_DECIMALS) && {
            maximumFractionDigits: precision.max,
        }),
    };
    return t("common.numberFormat", "{{val, number}}", translationOptions);
};

export const formatPercentageLocale = (
    value: number | string,
    t: TFunction,
    precision: {
        min?: number;
        max?: number;
    } = { min: undefined, max: 0 }
) => {
    return t("common.numberFormat", "{{val, number}}", {
        val: value,
        style: "percent",
        maximumFractionDigits: precision.max,
        minimumFractionDigits: precision.min,
        ns: "translation",
    });
};

export const gramsToKg = (value: number) => {
    return value / 1000;
};

export const getConvertedGramsToMassUnit = (
    valueInGrams: number,
    unit: UnitMass
) => {
    switch (unit) {
        case UnitMass.TON: {
            const precision = valueInGrams < 100000 ? 5 : 2;
            return _.round(valueInGrams / 1000000, precision);
        }
        case UnitMass.KILOGRAM: {
            const precisionKg = valueInGrams < 1000 ? 4 : 2;
            return _.round(valueInGrams / 1000, precisionKg);
        }
    }
};

export const getPartialOwnershipPercentDistribution = (
    originalPercentage: number,
    ownershipPercentage: number | null
) => {
    if (ownershipPercentage === null) {
        return null;
    }
    return _.round(originalPercentage * (ownershipPercentage / 100), 2);
};

export const isValidUrl = (string: string) => {
    try {
        new URL(string);
        return true;
    } catch (err) {
        return false;
    }
};

export const getValueFormatter = (t: TFunction) => (value: any) => {
    if (typeof value === "number") {
        return formatNumberLocale(value, t);
    } else if (typeof value === "string") {
        return value.replace(/(\r\n|\n|\r)/gm, "");
    }

    return value ?? DASH_SEPARATOR;
};
