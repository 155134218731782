import { useTheme } from "@mui/material";
import {
    BarPlot,
    BarSeriesType,
    ChartsAxisContentProps,
    ChartsGrid,
    ChartsTooltip,
    ChartsXAxis,
    ChartsYAxis,
    LinePlot,
    LineSeriesType,
    MarkPlot,
    ResponsiveChartContainerProps,
} from "@mui/x-charts";
import { ElementType, PropsWithChildren } from "react";

import { S } from "./chart.styles";
import { ChartAxisTooltip } from "./components/ChartAxisTooltip";
import { ChartLegend, ChartLegendProps } from "./components/ChartLegend";

export type ChartSeries = BarSeriesType | LineSeriesType;

type Props = Omit<ResponsiveChartContainerProps, "series" | "colors"> & {
    series: ChartSeries[];
    customTooltip?: ElementType<ChartsAxisContentProps>;
    legend?: ChartLegendProps;
};

export const Chart = (props: PropsWithChildren<Props>) => {
    const { xAxis, yAxis, customTooltip, legend, children, ...rest } = props;

    const theme = useTheme();

    const defaultizedXAxis =
        xAxis &&
        xAxis.map((axe) => {
            if (axe.scaleType !== "band") return axe;

            return {
                categoryGapRatio: 0.55,
                ...axe,
            };
        });

    const defaultizedYAxis =
        yAxis &&
        yAxis.map((axe) => ({
            tickNumber: 5,
            ...axe,
        }));

    return (
        <S.Container
            {...rest}
            height={500}
            margin={{ top: 50, bottom: 50, left: 60, right: 60 }}
            colors={[theme.palette.primary.main, theme.palette.secondary.main]}
            xAxis={defaultizedXAxis}
            yAxis={defaultizedYAxis}
        >
            <ChartsGrid horizontal />
            <BarPlot borderRadius={5} />
            <LinePlot />
            <MarkPlot />
            <ChartsXAxis />
            <ChartsYAxis
                labelStyle={{
                    transform: "rotate(0)",
                    translate: "8px -380px",
                }}
            />
            <ChartsTooltip
                slots={{ axisContent: customTooltip ?? ChartAxisTooltip }}
            />
            <ChartLegend {...legend} />
            {children}
        </S.Container>
    );
};
