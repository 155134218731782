import { useQuery } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useOrganizationContext } from "context";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    AssetGroupFilterCategory,
    CrremCategory,
    GetAssetRiskPreviewQuery,
    GetAssetRiskPreviewQueryVariables,
} from "graphql-types/graphql";
import { FilterItemOption, FilterItemOptions, filtersState } from "utils";

import { GET_ASSET_RISK_PREVIEW } from "./asset-risk.query";
import { Filters, Chart, StrategyDrawer } from "./components";
import { getAssetRisk } from "./components/Chart/chart.utils";
import { DisplayTypeSelector } from "./components/Filters/DisplayTypeSelector";
import { ChartItemLegend, StrategyLegend } from "./components/Legend";
import { S } from "./page.styles";
import { AssetRisk } from "./strategyPage.types";

const currentYear = new Date().getFullYear();
const defaultFilters: FilterItemOptions = [
    {
        category: AssetGroupFilterCategory.CONSTRUCTIONYEAR,
        values: [],
    },
    {
        category: AssetGroupFilterCategory.PROPERTYSIZE,
        values: [],
    },
    {
        category: AssetGroupFilterCategory.CRREMPROPERTYTYPES,
        values: [{ key: "OFFICE", value: CrremCategory.OFFICE }],
    },
];

export const StrategyPage = () => {
    const organization = useOrganizationContext();

    const { t } = useTranslation();

    const [assetDrawerState, setAssetDrawerState] = useState<{
        isOpen: boolean;
        data?: AssetRisk;
    }>({
        isOpen: false,
    });

    const [displayType, setDisplayType] = useState(ChartDisplayType.EMISSIONS);

    const [filters, setFilters] = useState<FilterItemOptions>(defaultFilters);
    const crremCategories = filters[2].values.flatMap((value) =>
        "value" in value ? (value.key as CrremCategory) : []
    );

    const { data, loading } = useQuery<
        GetAssetRiskPreviewQuery,
        GetAssetRiskPreviewQueryVariables
    >(GET_ASSET_RISK_PREVIEW, {
        variables: {
            input: filtersState.toJSON(filters),
            from: DateTime.fromObject({
                year: organization?.baselineYear || 2020,
            }),
            to: DateTime.fromObject({
                year: currentYear + 1,
            }),
            projectedFrom: DateTime.fromObject({
                year: currentYear,
            }),
            projectedTo: DateTime.fromObject({
                year: 2050,
            }).endOf("year"),
            crremCategories,
        },
        skip: crremCategories.length === 0,
    });

    function onFilterChange(filter: FilterItemOption) {
        const hasFilter = filters.some((currentFilter) => {
            return currentFilter.category === filter.category;
        });

        if (!hasFilter) {
            setFilters([...filters, filter]);
            return;
        }

        const pendingFilters = filters.map((currentFilter) => {
            if (currentFilter.category === filter.category) {
                const values = filter.values;
                return {
                    ...currentFilter,
                    values,
                };
            }
            return currentFilter;
        });

        setFilters(pendingFilters);
    }

    function onDrawerClose() {
        setAssetDrawerState((prevState) => ({
            ...prevState,
            isOpen: false,
        }));
    }

    function onSelectedAsset(asset: AssetRisk) {
        setAssetDrawerState({ isOpen: true, data: asset });
    }

    const [assets, pathways] = useMemo(() => {
        if (!data) return [[], []];

        const pathways = data.getCrremPathways;
        const assets = getAssetRisk(data);

        return [assets, pathways];
    }, [data]);

    return (
        <S.Page>
            <S.Header>
                <Typography variant="h1">
                    {t(
                        "strategy.titles.main",
                        "Science-Based Targets decarbonization pathway"
                    )}
                </Typography>
                <Typography variant="subtitle1">
                    {t(
                        "strategy.titles.sub",
                        "Ensure your real estate portfolio is aligned with the Science-Based Targets initiative (SBTi) and future-proofed against climate risk. Get a clear view of your portfolio and asset-level decarbonization performance."
                    )}
                </Typography>
            </S.Header>
            <S.Content>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    {t(
                        "strategy.titles.chart",
                        "Portfolio Decarbonisation Overview"
                    )}
                </Typography>
                <Typography variant="body1" sx={{ maxWidth: "800px" }}>
                    {t(
                        "strategy.titles.chartSub",
                        "Compare your assets' carbon reduction pathways against industry benchmarks like the Carbon Risk Real Estate Monitor (CRREM) and national targets to assess performance and identify potential risks. Download the data and use these insights to guide strategic decision-making and optimize your sustainability strategy."
                    )}
                </Typography>
                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 5,
                    }}
                >
                    <S.Filters
                        sx={{
                            marginBottom: 6,
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                        }}
                    >
                        <Typography fontWeight="bold">
                            {t("strategy.titles.filter", "Filter your view")}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                variant="faded"
                                sx={{ fontSize: "1rem" }}
                            >
                                {t("strategy.assetWithCount", {
                                    count: assets.length,
                                })}
                            </Typography>
                            <Button
                                variant="text"
                                sx={{ textDecoration: "underline" }}
                                onClick={() => setFilters(defaultFilters)}
                            >
                                {t(
                                    "strategy.titles.resetFilters",
                                    "Reset filters"
                                )}
                            </Button>
                        </Box>
                    </S.Filters>
                    <S.Filters>
                        <Filters filters={filters} onChange={onFilterChange} />
                        <DisplayTypeSelector
                            value={displayType}
                            onSelectedValue={(e) =>
                                setDisplayType(
                                    e.target.value as ChartDisplayType
                                )
                            }
                        />
                    </S.Filters>
                </Box>
                <ChartItemLegend />
                <Chart
                    assets={assets}
                    loading={loading}
                    pathways={pathways}
                    displayType={displayType}
                    onSelectedAsset={onSelectedAsset}
                />
                <StrategyLegend pathways={pathways} />
            </S.Content>
            <StrategyDrawer
                isOpen={assetDrawerState.isOpen}
                onClose={onDrawerClose}
                asset={assetDrawerState.data}
                pathways={pathways.filter(
                    ({ year }) => year === new Date().getFullYear() - 1
                )}
            />
        </S.Page>
    );
};
