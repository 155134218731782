import { Box, Card, styled, Typography } from "@mui/material";

export const S = {
    Container: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    })),
    Card: styled(Card)(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        flexDirection: "column",
        padding: theme.spacing(6),
    })),
    Value: styled(Typography)(({ theme }) => ({
        fontSize: theme.spacing(6),
        fontWeight: "bold",
    })),
};
