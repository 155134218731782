import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AssessmentChartTooltip } from "components/AssessmentStats/Chart/MUIAssessmentChartTooltip";
import { SummaryYearOverview } from "components/PortfolioDashboard/types";
import { Chart } from "glue/Chart";
import { ChartDisplayType } from "glue/Chart/chart.constants";

import { getTotalChartSeries } from "./yearlyTotatChart.helpers";

type Props = {
    summaries: SummaryYearOverview[];
    type: ChartDisplayType;
};

export const YearlyTotalChart = (props: Props) => {
    const { summaries, type } = props;

    const { t } = useTranslation();
    const { palette } = useTheme();

    const { series, xAxis, yAxis } = useMemo(() => {
        const label = type === ChartDisplayType.EMISSIONS ? "tons CO2e" : "kWh";

        const { series, xAxis } = getTotalChartSeries(
            summaries,
            palette,
            type,
            t
        );

        return {
            series,
            xAxis,
            yAxis: [{ label }],
        };
    }, [palette, t, type, summaries]);

    return (
        <Chart
            series={series}
            xAxis={xAxis}
            yAxis={yAxis}
            legend={{
                hidden: false,
                position: { vertical: "bottom" },
            }}
            customTooltip={AssessmentChartTooltip(type, t)}
        />
    );
};
