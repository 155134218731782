import { useQuery } from "@apollo/client";
import {
    GridRowId,
    GridPaginationModel,
    GridPaginationMeta,
    GridSortModel,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { DropDownPerformanceType } from "components/AssetList/components/Filters/RadioDropdown";
import { ActiveBenchmarkContext } from "components/BenchmarkSelect/BenchmarkSelect";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import {
    AssessmentDataType,
    GetAssetsQuery,
    GetAssetsQueryVariables,
    LocationSortFields,
    SortDirection,
} from "graphql-types/graphql";
import { useBrowserStorage } from "hooks";

import { GET_ASSETS } from "./asset-list.query";
import { formatQueryFilter, formatQuerySort, QueryFilter } from "./helpers";

const PAGE_SIZE = 20;

const emptyFilterState = {
    dataType: AssessmentDataType.COMBINED,
    inputString: null,
    performance: DropDownPerformanceType.ALL,
};

type HiddenColumns =
    | "performance"
    | "dataQuality"
    | "verticals"
    | "classification";

/**
 * Based on the pagination guide on the official documentation:
 * https://mui.com/x/react-data-grid/pagination/#cursor-based-pagination
 */
export const useGetAssetsQuery = (hiddenColumns: HiddenColumns[]) => {
    const { activeYear } = useSelectedYearContext();
    const { activeBenchmark } = useContext(ActiveBenchmarkContext);

    // Filtering state
    /** Get filter states **/
    const [searchParams] = useSearchParams();
    const [storageFilter] = useBrowserStorage(
        "assetListFilter",
        emptyFilterState,
        null
    );

    const [filters, setFilters] = useState<QueryFilter>(() => {
        const inputString = searchParams.get("inputString");
        const performance = searchParams.get("performance");
        const dataType = searchParams.get("dataType");

        return {
            inputString: inputString
                ? JSON.parse(inputString)
                : storageFilter.inputString,
            performance: performance
                ? JSON.parse(performance)
                : storageFilter.performance ?? DropDownPerformanceType.ALL,
            dataType: dataType
                ? JSON.parse(dataType)
                : storageFilter.dataType ?? AssessmentDataType.COMBINED,
        };
    });

    const handleFilterChange = debounce(
        (key: keyof QueryFilter, value: any) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                [key]: value,
            }));
        },
        200
    );

    // Sorting state
    const [queryOptions, setQueryOptions] = useState({
        field: LocationSortFields.EXTERNALID,
        direction: SortDirection.ASC,
    });

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptions(formatQuerySort(sortModel));
    }, []);

    // Pagination model
    const [storagePage, setStoragePage] = useBrowserStorage(
        "assetListPage",
        { page: 0, cursorRef: {} },
        null,
        "session"
    );
    const mapPageToNextCursor = useRef<{ [page: number]: GridRowId }>(
        storagePage.cursorRef
    );
    const [paginationModel, setPaginationModel] = useState({
        page: storagePage.page,
        pageSize: PAGE_SIZE,
    });

    // Query
    const skipPerformance =
        hiddenColumns.includes("performance") || activeBenchmark === null;

    const { data, previousData, loading } = useQuery<
        GetAssetsQuery,
        GetAssetsQueryVariables
    >(GET_ASSETS, {
        variables: {
            paging: {
                first: PAGE_SIZE,
                after: mapPageToNextCursor.current[paginationModel.page - 1],
            },
            sorting: queryOptions,
            filter: formatQueryFilter(filters, activeYear, activeBenchmark?.id),
            dataType: filters.dataType,
            fromLower: activeYear.toString(),
            fromUpper: `${activeYear}-12-31:23:59:59`,
            fromYear: activeYear,
            benchmarkId: activeBenchmark?.id,
            skipPerformance,
            skipDataQuality: hiddenColumns.includes("dataQuality"),
            skipClassifications: hiddenColumns.includes("classification"),
        },
    });

    const { hasNextPage, endCursor } = data?.locations.pageInfo || {};

    // Pagination model change
    const handlePaginationModelChange = (
        newPaginationModel: GridPaginationModel
    ) => {
        // We have the cursor, we can allow the page transition.
        if (
            newPaginationModel.page === 0 ||
            mapPageToNextCursor.current[newPaginationModel.page - 1]
        ) {
            setStoragePage((prevState) => ({
                ...prevState,
                page: newPaginationModel.page,
            }));
            setPaginationModel(newPaginationModel);
        }
    };

    // Pagination meta
    const paginationMetaRef = useRef<GridPaginationMeta>();
    const paginationMeta = useMemo(() => {
        if (
            typeof hasNextPage === "boolean" &&
            paginationMetaRef.current?.hasNextPage !== hasNextPage
        ) {
            paginationMetaRef.current = { hasNextPage };
        }
        return paginationMetaRef.current;
    }, [hasNextPage]);

    // Save the cursor for the next page
    useEffect(() => {
        if (!loading && endCursor) {
            mapPageToNextCursor.current[paginationModel.page] = endCursor;
            setStoragePage((prevState) => ({
                ...prevState,
                cursorRef: mapPageToNextCursor.current,
            }));
        }
    }, [paginationModel.page, loading, endCursor, setStoragePage]);

    // Row count
    const [rowCount, setRowCount] = useState(0);
    useEffect(() => {
        const currentRowCount = data?.locations.totalCount;

        setRowCount((prevRowCountState) =>
            currentRowCount !== undefined ? currentRowCount : prevRowCountState
        );
    }, [data?.locations.totalCount]);

    const assets = useMemo(() => {
        const assets = data?.locations ?? previousData?.locations;

        if (!assets) return [];

        return assets.edges.map((edge) => edge.node);
    }, [data?.locations, previousData?.locations]);

    return {
        assets,
        loading,
        filterProps: {
            queryFilter: filters,
            onFilterChange: handleFilterChange,
        },
        tablePaginationProps: {
            rowCount,
            pageSizeOptions: [PAGE_SIZE],
            paginationModel,
            paginationMeta,
            onPaginationModelChange: handlePaginationModelChange,
            onSortModelChange: handleSortModelChange,
        },
    };
};
