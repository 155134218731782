import { MarkElementProps, LineChartProps } from "@mui/x-charts";
import { useInteractionItemProps } from "@mui/x-charts/internals";

type Props = MarkElementProps & {
    series?: LineChartProps["series"];
    currentYearIndex?: number;
};

/**
 * Custom mark component for the chart.
 * Partial logic from the MarkElement component
 */
export const StrategyMark = (props: Props) => {
    const getInteractionItemProps = useInteractionItemProps();

    const { id, dataIndex, currentYearIndex, onClick, series } = props;
    const path = series?.find((s) => s.id === id);

    const isProjected = currentYearIndex && dataIndex > currentYearIndex;

    const { stranded } = path ?? ({} as any);
    const strokeColor = stranded[dataIndex]
        ? "red"
        : isProjected
        ? "grey"
        : path?.color;

    const fillColor =
        stranded[dataIndex] || isProjected ? "white" : path?.color;

    const d = 4;
    const transform = `translate(${(props.x as number) - d}, ${
        (props.y as number) - d
    })`;
    const shapeProps = {
        cx: d,
        cy: d,
        r: d,
        fill: fillColor,
        stroke: strokeColor,
        onClick,
    };
    const interactionProps = getInteractionItemProps({
        type: "line",
        seriesId: id,
        dataIndex,
    });

    return (
        <circle
            key={id}
            className="custom-mark"
            transform={transform}
            {...shapeProps}
            {...interactionProps}
        />
    );
};
