import { AssessmentVertical } from "graphql-types/graphql";

export interface SummaryYearOverview {
    assetCount: number;
    totalArea: number;
    emission: number;
    year: number;
    projectedEmission: number;
    buildingAverageEmissionPerArea: number;
    projectedBuildingAverageEmissionPerArea?: number;
    breakdown: Record<AssessmentVertical, number>;
    projected: number | null;
}

export enum ChartType {
    PORTFOLIO_INTENSITY,
    TOTAL_EMISSION,
    PORTFOLIO_RISK_PROFILE,
    WEIGHTED_AVERAGE,
    BASELINE_PERFORMANCE,
    MONTH_BY_MONTH,
    DATA_QUALITY,
    DATA_QUALITY_MONTH_BY_MONTH,
}

export enum PortfolioPage {
    OVERVIEW,
    YEARLY_BREAKDOWN,
    COVERAGE,
}

export type ChartDataItem = {
    title: string;
    header: number | string | null;
    value: number;
    formattedValue: string | null;
    formattedProjectedValue?: string | null;
    assetCount?: number;
    totalArea?: number;
};

export type MonthChartData = {
    label: string | null;
} & ChartDataItem;
