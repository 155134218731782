import { SquareRounded } from "@mui/icons-material";
import { Box, Paper, styled } from "@mui/material";

export const S = {
    Container: styled(Paper)(({ theme }) => ({
        minWidth: theme.spacing(55.5),
        padding: theme.spacing(5, 0),
        boxShadow: "0 2px 5px 0 rgba(0,0,0,0.1)",
        border: `1px solid ${theme.palette.grey[300]}`,
    })),
    Header: styled(Box)(({ theme }) => ({
        fontWeight: 700,
        paddingBottom: theme.spacing(2),
    })),
    SubHeader: styled(Box)(() => ({
        fontWeight: 700,
        width: "100%",
        display: "flex",
        alignItems: "center",
    })),
    SubHeaderBox: styled(Box)(() => ({
        display: "flex",
        gap: 1,
        flexDirection: "column",
        paddingBottom: 5,
        "&:last-of-type": {
            paddingBottom: 0,
        },
    })),
    Icon: styled(SquareRounded)({
        fontSize: 16,
    }),
};
