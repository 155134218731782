import { Chip } from "@mui/material";

import { theme } from "styling/themes";
import { getEPCPaletteFromTheme } from "utils/epcPalletteTheme";

type Props = {
    classification: string;
};

export const EPCClassificationChip = (props: Props) => {
    const { classification } = props;

    const palette = getEPCPaletteFromTheme(theme, classification);
    const chipStyle = {
        fontSize: theme.typography.caption.fontSize,
        padding: theme.spacing(0.5),
        color: palette.main,
        backgroundColor: palette.light,
    };

    return <Chip sx={chipStyle} label={classification} />;
};
