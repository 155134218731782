import { BarSeriesType } from "@mui/x-charts";
import { TFunction } from "i18next";
import { compact, mapValues } from "lodash";
import { DateTime } from "luxon";

import { UnitMass } from "components/AssessmentStats/types";
import {
    ChartDisplayType,
    PROJECTED_SERIES_COLOR,
} from "glue/Chart/chart.constants";
import { formatValue } from "glue/Chart/components/ChartTooltip";
import {
    AssessmentVertical,
    OrgProjectedEmissionSummaryFragment,
} from "graphql-types/graphql";
import { getConvertedGramsToMassUnit } from "utils/report.helpers";

import { EmissionByMonth, Summary } from "../types/monthlyChart.types";

export const getMonthlyProjectedChartSeries = ({
    summaries,
    projected,
    type,
    t,
    area,
    totalKey,
}: {
    summaries: EmissionByMonth[];
    projected: OrgProjectedEmissionSummaryFragment[];
    type: ChartDisplayType;
    t: TFunction;
    totalKey: keyof OrgProjectedEmissionSummaryFragment;
    area?: boolean;
}): BarSeriesType => {
    const data = [...projected]
        .sort(
            (a, b) =>
                DateTime.fromISO(a.from).month - DateTime.fromISO(b.from).month
        )
        .map((summary, index) => {
            const { ownedArea } = summary;
            if (
                !ownedArea ||
                DateTime.fromISO(summary.from).month < DateTime.now().month
            ) {
                return null;
            }

            const total = summary[totalKey] || 0;

            const totalForArea = area ? total / ownedArea : total;
            const calculatedTotal = summaries[index]
                ? totalForArea - summaries[index].value
                : totalForArea;

            if (calculatedTotal <= 0) return null;

            return type === ChartDisplayType.EMISSIONS
                ? getConvertedGramsToMassUnit(
                      calculatedTotal,
                      UnitMass.KILOGRAM
                  )
                : calculatedTotal;
        });

    return {
        id: "projected",
        stack: "total",
        type: "bar",
        color: PROJECTED_SERIES_COLOR,
        data,
        label: t("portfolioDashboard.boxes.projected", "Projected"),
        valueFormatter: (value: any) =>
            formatValue(value, type, t, area, UnitMass.KILOGRAM) || "",
    };
};

export const getChartData = (
    summaries: Summary[],
    type: ChartDisplayType,
    area?: boolean
): EmissionByMonth[] => {
    return compact(
        summaries.map((summary) => {
            const { ownedArea: totalArea, assetCount } = summary;
            if (!totalArea) return null;

            const key =
                type === ChartDisplayType.EMISSIONS
                    ? area
                        ? "ownedEmissionForArea"
                        : "ownedEmission"
                    : area
                    ? "ownedEnergyForArea"
                    : "ownedEnergy";
            const total = summary[key] || 0;

            const breakdownKey =
                type === ChartDisplayType.EMISSIONS
                    ? area
                        ? "emissionForAreaBreakdown"
                        : "emissionBreakdown"
                    : area
                    ? "energyForAreaBreakdown"
                    : "energyBreakdown";

            const breakdown = area
                ? (mapValues(
                      summary[breakdownKey],
                      (value) => value / totalArea
                  ) as Record<AssessmentVertical, number>)
                : summary[breakdownKey] || {};

            return {
                month: DateTime.fromISO(summary.from).month,
                totalArea,
                assetCount,
                value: area ? total / totalArea : total,
                breakdown,
            };
        })
    );
};
