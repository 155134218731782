import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useState } from "react";

import { EPCClassificationChip } from "./EPCClassificationChip";

const epc = ["a", "b", "c", "d", "e", "f", "g"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
};

export const EPCFilter = () => {
    const [epcList, setEPCList] = useState<string[]>([]);

    const handleEPCChange = (event: SelectChangeEvent<typeof epcList>) => {
        const {
            target: { value },
        } = event;
        setEPCList(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <FormControl sx={{ m: 1, width: 150 }}>
            <InputLabel id="epc-label">EPC</InputLabel>
            <Select
                labelId="epc-label"
                id="epc-filter-select"
                multiple
                value={epcList}
                onChange={handleEPCChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
            >
                {epc.map((epcCode) => (
                    <MenuItem key={epcCode} value={epcCode}>
                        <Checkbox checked={epcList.includes(epcCode)} />
                        <EPCClassificationChip classification={epcCode} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
