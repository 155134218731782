import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { APIProvider } from "@vis.gl/react-google-maps";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import TagManager from "react-gtm-module";

import { initSentry } from "utils/sentry";

import App from "./containers/App/App";
import { theme } from "./styling/themes";
import i18n from "./utils/i18n";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID as string,
};

(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID as string,
        options: {
            bootstrap: localStorage,
        },
    });

    const rootElement = document.getElementById("root");

    if (process.env.NODE_ENV === "production") {
        TagManager.initialize(tagManagerArgs);
        initSentry();
    }

    function AppContainer() {
        const [show, setShow] = useState(false);

        useEffect(() => {
            i18n.on("initialized", () => {
                setShow(true);
            });
        }, []);

        return (
            <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
            >
                <LDProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {show && <App />}
                    </ThemeProvider>
                </LDProvider>
            </APIProvider>
        );
    }

    if (!rootElement) {
        throw new Error("Failed to find the root element");
    }

    const root = ReactDOMClient.createRoot(rootElement);
    root.render(<AppContainer />);
})();
