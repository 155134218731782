import {
    Box,
    MenuItem,
    Typography,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import InfoPopover from "components/Popovers/InfoPopover";

import { getPortfolioOptions } from "./helpers/portfolioSelectorHelpers";
import { DataQualityTooltipInfo } from "./PortfolioDataQuality";
import { ChartType } from "./types";

type SelectorOptions = {
    activeChart: ChartType;
    isYearlyBreakdown: boolean;
    onChange: (chartType: ChartType) => void;
};

function PortfolioSelector(props: SelectorOptions) {
    const { onChange, isYearlyBreakdown, activeChart } = props;

    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent<ChartType>) => {
        onChange(event.target.value as ChartType);
    };

    const options = getPortfolioOptions(isYearlyBreakdown, t);

    const value =
        activeChart === ChartType.BASELINE_PERFORMANCE
            ? ChartType.PORTFOLIO_RISK_PROFILE
            : activeChart;

    return (
        <Box sx={{ display: "flex", gap: 4 }}>
            <Select<ChartType>
                size="small"
                fullWidth
                sx={{ minWidth: 200 }}
                onChange={handleChange}
                value={value}
                defaultValue={undefined}
                MenuProps={{
                    anchorOrigin: { vertical: "bottom", horizontal: "left" },
                    transformOrigin: { vertical: "top", horizontal: "left" },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Typography fontWeight="bold" display="inline">
                            {option.title}
                        </Typography>
                        {option.unit && (
                            <Typography
                                color="grey.500"
                                display="inline"
                                pl={1}
                            >
                                ({option.unit})
                            </Typography>
                        )}
                    </MenuItem>
                ))}
            </Select>

            {activeChart === ChartType.DATA_QUALITY && (
                <InfoPopover element={<DataQualityTooltipInfo />} />
            )}
        </Box>
    );
}

export default PortfolioSelector;
