import { useQuery } from "@apollo/client";
import { Box, Stack, Typography } from "@mui/material";
import { uniq } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import EpcClassificationsCell from "components/Cells/EpcClassificationsCell";
import { GET_ASSET_ESG_ACTION_PLAN } from "containers/StrategyPage/asset-risk.query";
import {
    AssetRisk,
    CrremPathway,
    IntensityStranded,
} from "containers/StrategyPage/strategyPage.types";
import { Drawer } from "glue";
import {
    CrremCategory,
    DgnbCertification,
    GetAssetEsgActionPlanQuery,
    GetAssetEsgActionPlanQueryVariables,
} from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";
import { DASH_SEPARATOR } from "utils";

import { StrategyTable } from "./components/StrategyTable";
import { S } from "./strategyDrawer.style";
import { RowData } from "./strategyDrawer.types";
import {
    findFirstStrandedYear,
    getCertificationTranslation,
} from "./strategyDrawer.utils";

type Props = {
    asset?: AssetRisk;
    pathways?: CrremPathway[];
    isOpen: boolean;
    onClose: () => void;
};

export const StrategyDrawer = (props: Props) => {
    const { asset, pathways, ...rest } = props;
    const { t } = useTranslation();

    const { translations } = useAssetFilterTranslations();

    const { data, loading } = useQuery<
        GetAssetEsgActionPlanQuery,
        GetAssetEsgActionPlanQueryVariables
    >(GET_ASSET_ESG_ACTION_PLAN, {
        variables: { assetId: asset?.id ?? "" },
        skip: !asset,
    });

    const epcs = useMemo(() => {
        const classifications = data?.location.epcs
            .filter((epc) => new Date(epc.to) >= new Date())
            .flatMap((epc) => epc.classification ?? []);

        const target =
            asset && asset.constructionYear && asset.constructionYear >= 2020
                ? "A2015"
                : "C";

        if (!classifications || classifications.length === 0)
            return [
                {
                    kpi: "EPC",
                    current: null,
                    target: EpcClassificationsCell([target]),
                    unit: "Rating",
                    completed: false,
                },
            ];

        return uniq(classifications).map((epc) => ({
            kpi: "EPC",
            current: EpcClassificationsCell([epc]),
            target: EpcClassificationsCell([target]),
            unit: t("common.labels.rating", "Rating"),
            completed: epc.localeCompare(target) <= 0,
        }));
    }, [data, t, asset]) as RowData[];

    if (!asset) return null;

    const assetRisk = { ...asset, ...data?.location };

    const energyPathway = pathways?.find((pathway) =>
        pathway.unit.includes("kWh")
    );
    const emissionsPathway = pathways?.find((pathway) =>
        pathway.unit.includes("kgCO2e")
    );

    const { emissions, energy, dgnbCertification } = assetRisk;
    const targetYear = new Date().getFullYear() - 1;
    const yearEnergy = energy?.[targetYear];
    const yearEmissions = emissions?.[targetYear];
    const energyStranded = energy && findFirstStrandedYear(energy, targetYear);
    const emissionsStranded =
        emissions && findFirstStrandedYear(emissions, targetYear);

    const compliantLabel = (
        intensity?: IntensityStranded,
        strandedYear?: number
    ) => {
        if (!intensity) {
            return DASH_SEPARATOR;
        } else if (strandedYear === targetYear) {
            return t("common.labels.notCompliant", "Not Compliant");
        } else if (!strandedYear) {
            return t("common.labels.compliant", "Compliant");
        } else {
            return strandedYear;
        }
    };

    const tableData = [
        {
            kpi: `DGNB ${t("common.labels.certification", "Certification")}`,
            current: dgnbCertification
                ? getCertificationTranslation(dgnbCertification, t)
                : "-",
            target: getCertificationTranslation(DgnbCertification.GOLD, t),
            unit: t("common.labels.rating", "Rating"),
            completed:
                dgnbCertification === DgnbCertification.GOLD ||
                dgnbCertification === DgnbCertification.PLATINUM,
        },
        {
            kpi: t("common.labels.energyIntensity", "Energy intensity"),
            current: yearEnergy ? yearEnergy.intensity?.toFixed(2) : "-",
            target: energyPathway ? energyPathway.value.toFixed(2) : "-",
            unit: t("common.units.kwhm2", "kWh/m²"),
            completed:
                energyPathway && yearEnergy?.intensity
                    ? yearEnergy.intensity < energyPathway.value
                    : false,
        },
        {
            kpi: t("common.labels.compliantUntil", "Compliant until"),
            current: compliantLabel(yearEnergy, energyStranded),
            target: 2050,
            unit: "CRREM 1.5C",
            completed: yearEnergy
                ? energyStranded
                    ? energyStranded >= 2050
                    : true
                : false,
        },
        {
            kpi: t("common.labels.emissionIntensity", "Emission intensity"),
            current: yearEmissions ? yearEmissions.intensity?.toFixed(2) : "-",
            target:
                yearEmissions && emissionsPathway
                    ? emissionsPathway.value.toFixed(2)
                    : "-",
            unit: t("common.units.kgCO2em2", "kg CO2e / m²"),
            completed:
                emissionsPathway && yearEmissions?.intensity
                    ? yearEmissions.intensity < emissionsPathway.value
                    : false,
        },
        {
            kpi: t("common.labels.compliantUntil", "Compliant until"),
            current: compliantLabel(yearEmissions, emissionsStranded),
            target: 2040,
            unit: "CRREM 1.5C",
            completed: yearEmissions
                ? emissionsStranded
                    ? emissionsStranded >= 2040
                    : true
                : false,
        },
    ];

    const epcCompleted =
        epcs.filter((data) => data.completed).length === epcs.length ? 1 : 0;
    const completed =
        tableData.filter((data) => data.completed).length + epcCompleted;

    const info = [
        {
            label: t("assetDetailsComponent.propertyType"),
            value: translations[assetRisk.crremPropertyType as CrremCategory],
        },
        {
            label: t("assetDetailsComponent.totalArea"),
            value: `${assetRisk.buildingArea} m³`,
            loading,
        },
        {
            label: t("assetDetailsComponent.constructionYear"),
            value: assetRisk.constructionYear ?? "-",
        },
        {
            label: "Completion of green asset indicator",
            value: `${completed}/6`,
        },
    ];

    const address = `${assetRisk.zipCode} ${assetRisk.city}`;

    return (
        <Drawer anchor="right" width="600px" {...rest}>
            <Drawer.Header>
                <Drawer.Title>{assetRisk.displayName}</Drawer.Title>
                <Drawer.Panel>
                    <S.Typography loading={loading} variant="faded">
                        {address}
                    </S.Typography>
                </Drawer.Panel>
            </Drawer.Header>
            <Drawer.Content>
                <Box sx={{ padding: "2rem" }}>
                    {info.map(({ label, value, loading }) => (
                        <Stack
                            key={label}
                            direction="row"
                            justifyContent="space-between"
                            marginBottom={4}
                        >
                            <Typography fontWeight="bold">{label}</Typography>
                            <S.Typography loading={loading || false}>
                                {value}
                            </S.Typography>
                        </Stack>
                    ))}
                    <Typography width="75%" display="block" variant="faded">
                        The green asset indicator is benchmarked against Nordic
                        Banks Green finance framework. <a href="/">Read more</a>
                    </Typography>
                </Box>
                <Box sx={{ padding: "2rem" }}>
                    <Typography variant="h4" sx={{ marginBottom: 5 }}>
                        Asset level ESG actionplan
                    </Typography>
                    <StrategyTable data={tableData} epcs={epcs} />
                    <Typography variant="faded" sx={{ marginTop: 6 }}>
                        The ESG Actionplan supported by a Capex plan with
                        de-risking initiatives like heatpumps, solar pv, new
                        windows etc. with budget on the implementation. *DGNB in
                        use (explain what this mean).
                    </Typography>
                </Box>
            </Drawer.Content>
        </Drawer>
    );
};
