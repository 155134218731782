import { gql } from "@apollo/client";

export const GET_ASSET_RISK_PREVIEW = gql`
    query GetAssetRiskPreview(
        $input: [AssetGroupFilterInput!]!
        $crremCategories: [CrremCategory!]!
        $from: DateTime!
        $to: DateTime!
        $projectedFrom: DateTime!
        $projectedTo: DateTime!
    ) {
        getAssetGroupPreview(assetGroupFilters: $input) {
            id
            externalId
            displayName
            constructionDate
            crremPropertyType
            ownedArea
            emissionSummaries(
                from: $from
                to: $to
                aggregation: Year
                dataType: COMBINED
            ) {
                from
                ownedEmission
                ownedEnergy
            }
            projectedEmissionSummaries(
                from: $projectedFrom
                to: $projectedTo
                aggregation: Year
                dataType: COMBINED
            ) {
                from
                ownedEmission
                ownedEnergy
            }
        }

        getCrremPathways(
            categories: $crremCategories
            countries: "DK"
            versions: "v203"
        ) {
            crremCategory
            color
            value
            year
            unit
            version
        }
    }
`;

export const GET_ASSET_ESG_ACTION_PLAN = gql`
    query GetAssetEsgActionPlan($assetId: ID!) {
        location(id: $assetId) {
            id
            zipCode
            city
            buildingArea
            dgnbCertification
            epcs {
                to
                classification
            }
        }
    }
`;
