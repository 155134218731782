import { useQuery } from "@apollo/client";
import { DateTime } from "luxon";
import { useMemo } from "react";

import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    AssessmentDataType,
    GetPortfolioOverviewEmissionSummariesQuery,
    GetPortfolioOverviewEmissionSummariesQueryVariables,
} from "graphql-types/graphql";

import { getSummaryYearOverviewForEmissionSummaries } from "./utilities";
import { PORTFOLIO_OVERVIEW_EMISSION_SUMMARY_QUERY } from "../portfolioQueries";
import { ChartType } from "../types";

export const useYearSummaries = (
    dataType: AssessmentDataType,
    chartType: ChartType,
    type: ChartDisplayType
) => {
    const { data, loading } = useQuery<
        GetPortfolioOverviewEmissionSummariesQuery,
        GetPortfolioOverviewEmissionSummariesQueryVariables
    >(PORTFOLIO_OVERVIEW_EMISSION_SUMMARY_QUERY, {
        variables: {
            from: `${DateTime.local().year}`,
            dataType,
        },
    });

    const yearSummaries = useMemo(() => {
        const { orgEmissionSummaries, orgProjectedEmissionSummaries } =
            data ?? {};

        if (!orgEmissionSummaries || !orgProjectedEmissionSummaries) return [];

        return getSummaryYearOverviewForEmissionSummaries(
            orgEmissionSummaries,
            orgProjectedEmissionSummaries,
            chartType,
            type
        );
    }, [data, chartType, type]);

    return { yearSummaries, loading };
};
