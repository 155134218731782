import { Box, FormControl, Paper, styled } from "@mui/material";

export const S = {
    Page: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(20),
    })),

    Header: styled(Box)(({ theme }) => ({
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(20),
    })),

    Content: styled(Paper)(({ theme }) => ({
        padding: theme.spacing(10, 5),
        borderRadius: 4,
        flexGrow: 1,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    })),

    Filters: styled(Box)(({ theme }) => ({
        display: "flex",
        gap: theme.spacing(3),
    })),

    FilterWrapper: styled(FormControl)(() => ({
        width: "100%",
    })),
};
