import { Circle } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import _ from "lodash";

import { CrremPathway } from "containers/StrategyPage/strategyPage.types";
import { CrremCategory } from "graphql-types/graphql";
import { useAssetFilterTranslations } from "hooks";

type Props = {
    pathways: CrremPathway[];
};

export const StrategyLegend = (props: Props) => {
    const { pathways } = props;

    const { translations } = useAssetFilterTranslations();

    const crremFilters = _.chain(pathways)
        .groupBy("crremCategory")
        .map((pathways, key) => ({ key, color: pathways[0].color }))
        .value();

    if (!crremFilters.length) return;

    return (
        <Stack
            direction="row"
            gap={5}
            justifyContent="center"
            sx={{ maxWidth: "80%", margin: "auto" }}
            flexWrap="wrap"
        >
            {crremFilters.map((filter) => (
                <Stack
                    gap={2}
                    alignItems="center"
                    key={filter.key}
                    direction="row"
                >
                    <Circle sx={{ fontSize: 10 }} htmlColor={filter.color} />
                    <Typography>
                        {translations[filter.key as CrremCategory]}
                    </Typography>
                </Stack>
            ))}
        </Stack>
    );
};
