import { styled, TableCell, Typography } from "@mui/material";

export const S = {
    Cell: styled(TableCell)(({ theme }) => ({
        padding: 0,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    })),
    Head: styled(TableCell)(({ theme }) => ({
        padding: 0,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        fontWeight: "bold",
    })),
    Typography: styled(Typography, {
        shouldForwardProp: (prop) => prop !== "loading",
    })<{ loading: boolean }>(({ loading }) => ({
        color: loading ? "transparent" : "initial",
        backgroundColor: loading ? "#f0f0f0" : "initial",
        borderRadius: "4px",
        animation: loading ? "pulse 1s infinite" : "none",
    })),
};
