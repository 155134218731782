import { useQuery } from "@apollo/client";
import { TFunction } from "i18next";
import { useMemo } from "react";

import {
    AssessmentAggregation,
    GetOrgEmissionSummariesForYearlyBreakdownQuery,
    GetOrgEmissionSummariesForYearlyBreakdownQueryVariables,
    AssessmentDataType,
} from "graphql-types/graphql";
import { getStartAndEndOfYear } from "utils/date.utils";
import {
    formatNumberLocale,
    getConvertedGramsToMassUnit,
} from "utils/report.helpers";

import { UnitMass } from "../../AssessmentStats/types";
import { EMISSION_SUMMARIES_QUERY } from "../Queries/emissionSummariesQuery";

export const getYearLabel = (year: number, t: TFunction): string => {
    const currentYear = new Date().getFullYear();
    const isCurrentYear = currentYear === year;

    return isCurrentYear
        ? year + t("portfolioDashboard.yearSelect.ytd", " YTD")
        : year.toString();
};

export const getFormattedConvertedGramsToMassUnit = (
    value: number | null,
    unit: UnitMass,
    t: TFunction,
    isValuePerArea = true
) => {
    if (!value) return null;

    const roundedValue = getConvertedGramsToMassUnit(value, unit);
    return getFormattedValueToMassUnit(roundedValue, unit, t, isValuePerArea);
};

export const getFormattedEnergyValue = (
    value: number | null,
    t: TFunction,
    isValuePerArea?: boolean
) => {
    if (!value) return null;

    const formattedValue = formatNumberLocale(value, t);

    return `${formattedValue} ${
        isValuePerArea
            ? t("common.units.kwhm2", "kWh / m²")
            : t("common.units.kwh.label", "kWh")
    }`;
};

export const getFormattedValueToMassUnit = (
    value: number | null,
    unit: UnitMass,
    t: TFunction,
    isValuePerArea = true
) => {
    if (!value) return null;

    const formattedValue = formatNumberLocale(value, t);
    const unitString = getUnitMassString(unit, t);

    return `${formattedValue} ${unitString} ${
        isValuePerArea
            ? t("portfolioDashboard.chartHelpers.CO2em2", "CO2e / m²")
            : t("portfolioDashboard.chartHelpers.CO2e", "CO2e")
    }`;
};

const getUnitMassString = (unit: UnitMass, t: TFunction) => {
    switch (unit) {
        case UnitMass.KILOGRAM:
            return t("portfolioDashboard.chartHelpers.kg", "kg");
        case UnitMass.TON:
            return t("portfolioDashboard.chartHelpers.t", "t");
    }
};

const getQueryVariablesForMonthlyData = (
    year: number,
    dataType: AssessmentDataType
) => {
    const yearRange = getStartAndEndOfYear(year);
    return {
        aggregation: AssessmentAggregation.MONTH,
        from: yearRange.from,
        to: yearRange.to,
        dataType,
    };
};

export const useGetMonthlyEmissionSummaries = (
    activeYear: number,
    activeDataType: AssessmentDataType
) => {
    const { data, loading, previousData } = useQuery<
        GetOrgEmissionSummariesForYearlyBreakdownQuery,
        GetOrgEmissionSummariesForYearlyBreakdownQueryVariables
    >(EMISSION_SUMMARIES_QUERY, {
        variables: getQueryVariablesForMonthlyData(activeYear, activeDataType),
    });
    const dataLoader = data ?? previousData;
    const assets = useMemo(() => {
        return dataLoader?.getAssets ?? [];
    }, [dataLoader]);

    const orgEmissionSummaries = useMemo(() => {
        return dataLoader?.orgEmissionSummaries ?? [];
    }, [dataLoader]);
    const orgProjectedEmissionSummaries = useMemo(() => {
        return dataLoader?.orgProjectedEmissionSummaries ?? [];
    }, [dataLoader]);

    return {
        assets,
        orgEmissionSummaries,
        orgProjectedEmissionSummaries,
        loading,
    };
};
