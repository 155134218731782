import { AssetList } from "components/AssetList";
import { ActiveBenchmarkProvider } from "components/BenchmarkSelect/BenchmarkSelect";
import { BenchmarksProvider } from "utils/benchmarksContext";

function AssetListPage() {
    return (
        <BenchmarksProvider>
            <ActiveBenchmarkProvider>
                <AssetList />
            </ActiveBenchmarkProvider>
        </BenchmarksProvider>
    );
}

export default AssetListPage;
