import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { t } from "i18next";
import { useFlags } from "launchdarkly-react-client-sdk";

import { VerticalFilter } from "containers/AssetPage/AssetOverview/VerticalFilter";
import {
    AssessmentVertical,
    PropertyOwnershipType,
} from "graphql-types/graphql";
import { getPropertyOwnershipTranslation } from "utils/translations";

import { useAssetPropertyFilters } from "./AssetPropertyFiltersContext";

const InputStyle = {
    background: "#fff",
    minWidth: "250px",

    "&.Mui-focused fieldset": {
        background: "transparent",
        color: "currentColor",
    },
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
            backgroundColor: "#fff",
            border: "2px solid currentColor",
        },
    },
};

export const AssetOverviewFilters = () => {
    const {
        verticals,
        setVerticals,
        ownershipTypes,
        setOwnershipTypes,
        resetFilters,
        propertiesCount,
    } = useAssetPropertyFilters();

    const { useBasicConsumptionAllocation, useConsumptionAllocation } =
        useFlags();

    if (!useConsumptionAllocation && !useBasicConsumptionAllocation) {
        return null;
    }

    const onChangeOwnershipTypes = (
        e: SelectChangeEvent<PropertyOwnershipType[]>
    ) => {
        const value =
            // On autofill we get a stringified value.
            typeof e.target.value === "string"
                ? (e.target.value.split(",") as PropertyOwnershipType[])
                : e.target.value;

        setOwnershipTypes(value);
    };

    return (
        <FormControl sx={{ display: "flex", flexDirection: "row", gap: 8 }}>
            <InputLabel id="ownershipTypes-label" size="small">
                {t(
                    "assessmentOverview.assetList.filter.ownershipType",
                    "Ownership Type"
                )}
            </InputLabel>
            <Select
                multiple
                size="small"
                labelId="ownershipTypes-label"
                input={
                    <OutlinedInput
                        sx={InputStyle}
                        label={t(
                            "assessmentOverview.assetList.filter.ownershipType",
                            "ownership type"
                        )}
                    />
                }
                value={ownershipTypes}
                renderValue={(ownershipTypes) =>
                    Object.values(PropertyOwnershipType).length ===
                    ownershipTypes.length
                        ? t("assessmentOverview.assetList.filter.all", "All")
                        : ownershipTypes
                              .map((ownershipType) =>
                                  getPropertyOwnershipTranslation(ownershipType)
                              )
                              .join(", ")
                }
                onChange={onChangeOwnershipTypes}
                MenuProps={MenuProps}
            >
                {Object.values(PropertyOwnershipType).map((item) => (
                    <MenuItem
                        value={item}
                        key={item}
                        sx={{ whiteSpace: "normal" }}
                    >
                        <Checkbox
                            checked={ownershipTypes.includes(item)}
                            size="small"
                        />
                        <ListItemText
                            primary={getPropertyOwnershipTranslation(item)}
                        />
                    </MenuItem>
                ))}
            </Select>

            <VerticalFilter
                filters={Object.values(AssessmentVertical).filter(
                    (v) => v !== AssessmentVertical.UN_CATEGORIZED
                )}
                activeFilters={verticals}
                onFilterChange={setVerticals}
            />
            {useConsumptionAllocation && (
                <Typography
                    variant="h5"
                    color={"primary"}
                    sx={{ padding: "8px" }}
                >
                    {`${propertiesCount} ${t(
                        "assetDetailsComponent.propertyList.title",
                        "Properties"
                    )}`}
                </Typography>
            )}

            {ownershipTypes.length > 0 || verticals.length > 0 ? (
                <Button
                    onClick={resetFilters}
                    variant="text"
                    sx={{ padding: "8px" }}
                >
                    <Typography variant="h5" fontWeight={"bold"}>
                        {t(
                            "assessmentOverview.assetList.filter.resetFilter",
                            "Reset Filters"
                        )}
                    </Typography>
                </Button>
            ) : null}
        </FormControl>
    );
};
