import { Avatar, Box, styled } from "@mui/material";

export const S = {
    Layout: styled(Box)(() => ({
        display: "grid",
        gridTemplateColumns: "250px auto",
        height: "100vh",
    })),

    Navigation: styled(Box)(({ theme }) => ({
        width: 250,
        textAlign: "center",
        paddingTop: theme.spacing(14),
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.05)",
        zIndex: 2,
        ".menu": {
            marginTop: theme.spacing(12),
        },
    })),

    Body: styled(Box)(({ theme }) => ({
        display: "grid",
        gridTemplateRows: "80px auto",
        backgroundColor: theme.palette.grey[100],
        zIndex: 1,
    })),

    Content: styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.grey[100],
    })),

    Header: styled(Box)(({ theme }) => ({
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(10),
        paddingLeft: theme.spacing(10),
        display: "flex",
        justifyContent: "flex-end",
    })),

    Logo: styled(Box)(() => ({
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
    })),

    Img: styled(Avatar)(({ theme }) => ({
        display: "flex",
        width: theme.spacing(10),
    })),
};
