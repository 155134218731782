import { Alert } from "@mui/material";
import { ChartsItemContentProps } from "@mui/x-charts";
import { getLabel, CommonSeriesType } from "@mui/x-charts/internals";
import { useTranslation } from "react-i18next";

import { StrategySeriesDefaultized } from "containers/StrategyPage/strategyPage.types";
import { ChartTooltip } from "glue/Chart/components/ChartTooltip";
import { useAssetFilterTranslations } from "hooks";

type Props = {
    series: StrategySeriesDefaultized;
    years?: number[];
} & ChartsItemContentProps<"line">;

export const StrategyTooltip = (props: Props) => {
    const { series, itemData, getColor, years } = props;
    const { dataIndex } = itemData;

    const { t } = useTranslation();

    const { translations } = useAssetFilterTranslations();
    if (!dataIndex || !series.data[dataIndex] || !years) {
        return null;
    }

    const color = getColor(dataIndex);

    const value = series.data[dataIndex];

    const formattedValue = (
        series.valueFormatter as CommonSeriesType<
            typeof value
        >["valueFormatter"]
    )?.(value, { dataIndex });

    const assetLabel = getLabel(series.label, "tooltip");
    const year = years[dataIndex];

    return (
        <ChartTooltip
            sections={[
                {
                    header: year,
                    subHeaders: [
                        {
                            label: t("common.labels.intensity", "Intensity"),
                            value: formattedValue ?? "Error",
                        },
                        {
                            label: t(
                                "assetDetailsComponent.assetType",
                                "Asset Type"
                            ),
                            value: assetLabel
                                ? translations[
                                      assetLabel as keyof typeof translations
                                  ]
                                : "Error",
                            iconColor: color,
                        },
                    ],
                },
                {
                    items: [
                        {
                            label: t(
                                "assetDetailsComponent.internalID",
                                "Internal Id"
                            ),
                            value: series.internalId?.toString(),
                        },
                        {
                            label: t(
                                "assetDetailsComponent.stranded",
                                "Stranded"
                            ),
                            value: series.stranded?.[dataIndex]
                                ? t("common.yes", "Yes")
                                : t("common.no", "No"),
                        },
                        {
                            label: t(
                                "assetDetailsComponent.constructionYear",
                                "Construction Year"
                            ),
                            value: series.constructionYear?.toString(),
                        },
                        {
                            label: t("common.labels.address", "Address"),
                            value: series.displayName,
                        },
                    ],
                },
            ]}
        >
            <Alert
                sx={{
                    marginX: 5,
                    paddingY: 0,
                    marginTop: 4,
                    textAlign: "center",
                    "& .MuiAlert-message": {
                        width: "100%",
                    },
                }}
                icon={false}
            >
                {t("common.labels.clickToSeeMore", "Click to see more")}
            </Alert>
        </ChartTooltip>
    );
};
