import { useQuery } from "@apollo/client";
import { Box, Link, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

import { getChartTypeUnit } from "components/PortfolioDashboard/helpers";
import { ChartType } from "components/PortfolioDashboard/types";
import { Table } from "components/Table";
import { useSelectedYearContext } from "components/YearSelector/YearContext";
import { ChartDisplayType } from "glue/Chart/chart.constants";
import {
    AssessmentDataType,
    OutlierAssetsQuery,
    OutlierAssetsQueryVariables,
} from "graphql-types/graphql";

import { geQueryVariables, getSumValue } from "./outlier-table.helpers";
import { OUTLIER_ASSETS } from "./query";

type Props = {
    type: ChartDisplayType;
    dataType: AssessmentDataType;
    chartType: ChartType;
};

export const OutlierTable = (props: Props) => {
    const { t } = useTranslation();
    const { type, chartType } = props;

    const { activeYear } = useSelectedYearContext();

    const { data, loading } = useQuery<
        OutlierAssetsQuery,
        OutlierAssetsQueryVariables
    >(OUTLIER_ASSETS, {
        variables: geQueryVariables(
            type,
            props.dataType,
            activeYear,
            chartType
        ),
    });

    const fieldLabel = useMemo(() => {
        const label = match(chartType)
            .with(ChartType.MONTH_BY_MONTH, () =>
                type === ChartDisplayType.EMISSIONS
                    ? t("assessmentOverview.emissions", "emissions")
                    : t(
                          "portfolioDashboard.boxes.energy",
                          "total energy consumption"
                      )
            )
            .with(ChartType.PORTFOLIO_INTENSITY, () =>
                type === ChartDisplayType.EMISSIONS
                    ? t("common.labels.emissionIntensity", "emission intensity")
                    : t("common.labels.energyIntensity", "energy intensity")
            )
            .otherwise(() => "");

        if (!label) return "";
        const unit = getChartTypeUnit(chartType, type);

        return `${label} (${unit})`;
    }, [chartType, type, t]);

    const columns = [
        {
            field: "address",
            headerName: t("common.labels.address", "address"),
            renderCell: (params: any) => (
                <Link href={`/location-overview/${params.row.id}`}>
                    {params.value ?? "-"}
                </Link>
            ),
            width: 300,
        },
        {
            field: "internalId",
            headerName: t("assetDetailsComponent.internalID", "Internal ID", {
                ns: "translation",
            }),
            flex: 1,
        },
        {
            field: type,
            headerName: fieldLabel,
            flex: 1,
        },
    ];

    const rows = useMemo(() => {
        if (!data) return [];

        return data.locations.edges.map((edge) => {
            const { node } = edge;
            const {
                id,
                shortAddress,
                displayName,
                name,
                externalId,
                emissionSummaries,
            } = node;

            return {
                id,
                address: displayName || shortAddress || name,
                internalId: externalId,
                [type]: getSumValue(type, chartType, emissionSummaries),
            };
        });
    }, [data, chartType, type]);

    const description = useMemo(() => {
        if (chartType === ChartType.PORTFOLIO_INTENSITY) {
            return type === ChartDisplayType.EMISSIONS
                ? t(
                      "portfolioDashboard.outlierAnalysisDescriptionIntensity",
                      "assets with highest emissions intensity"
                  )
                : t(
                      "portfolioDashboard.outlierAnalysisDescriptionIntensityEnergy",
                      "assets with highest energy intensity"
                  );
        }

        return type === ChartDisplayType.EMISSIONS
            ? t(
                  "portfolioDashboard.outlierAnalysisDescription",
                  "assets with highest emissions"
              )
            : t(
                  "portfolioDashboard.outlierAnalysisDescriptionEnergy",
                  "assets with highest energy consumption"
              );
    }, [chartType, type, t]);

    return (
        <Box sx={{ "@media print": { display: "none" } }}>
            <Typography variant="h4">
                {t("portfolioDashboard.outlierAnalysis", "Outlier analysis")}
            </Typography>
            <Typography>{description}</Typography>
            <Table
                sx={{ mt: 4 }}
                autoHeight
                rows={rows}
                columns={columns}
                hasBorder
                hideFooter
                isLoading={loading}
            />
        </Box>
    );
};
